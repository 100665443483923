import { breakpoints, colors } from "style";
import styled from "styled-components";
// import { MerchantBackground } from "assets/images";

export const StampContainer = styled.div`
  overflow: scroll;
  width: 100%;
  display: flex;
  flex: 1;
  // box-shadow:inset 0 0 0 2000px rgb(41 41 41 / 62%);
  background-color: ${props => props.color1};
  .stamp-container {
    // margin-top: 10px;
    // padding: 20px;
    flex: 1;
    width: 100%;
    background: ${colors.white};
    // border-top-left-radius: 24px;
    // border-top-right-radius: 24px;
    .tabs {
      display: flex;
      text-align: center;
      background-color: #FFB84F;
      padding: 8px 0;
      height: 60px;
      .tab {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.5);
        position: relative;
        cursor: pointer;
        :not(:first-child) {
          border-left: solid 2px #FFFFFF;
        } 
        &.active {
          color: #FFFFFF;
          ::before {
            content: ""; 
            display: block;
            border-bottom: solid 4px #FFFFFF;
            position: absolute;
            bottom: -8px;
            width: 0;
            height: 0;
            border-width: 0 11.5px 13px 11.5px;
            border-color: transparent transparent #FFFFFF transparent;
            border-style: solid;
            z-index: 9;
          }
          ::after {
            content: ""; 
            display: block;
            border-bottom: solid 4px #FFFFFF;
            width: 70%;
            position: absolute;
            bottom: -8px;
          }
        }
      }
    }
    .section-accordion {
      margin-top: 20px;
      padding: 0px 18px;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: ${colors.purple};
      }
      .campaigns {
        margin-top: 4px;
        .campaign-name {
            width: 100%;
            min-height: 54px;
            background: white;
            padding: 12px;
            border-radius: 10px;
            font-weight: 400;
            // text-transform: capitalize;
          .first {
            .referral {
              text-overflow: ellipsis;
              overflow: hidden; 
              width: 90%; 
              white-space: nowrap;
            }
            .remove {
              text-overflow: unset;
              overflow: unset; 
              width: unset; 
              white-space: unset;
            }
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
          }
          .second {
            padding-top: 8px;
            .badge {
              width: 94px;
              padding: 10px 10px;
              background: #3B7779;
              border-radius: 100px;
              text-align: center;
              color: white;
              font-weight: 600;
              font-size: 12px;
              :not(:first-child) {
                margin-left: 10px;
              }
            }
            .redeemed {
              background: #3B7779;
            }
            .otherBadge {
              background: #FFB84F;
            }
            .redeemExpired {
              width: 126px;
            }
            .delete {
              cursor: pointer;
              background: #DB5655;
            }
          }
          > div p {
            font-weight: 500;
            font-size: 14px;
            line-height: 1;
            margin-bottom: 2px;
          }
          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #263238;
          }
          &.active {
            // background: linear-gradient(to bottom right, ${colors.red} , ${colors.yellow});
            // color: white;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            margin-bottom: 0;
            box-shadow: unset;
          }
          .status {
            position: absolute;
            right: 24px;
            background: white;
            padding: 2px 8px;
            border-radius: 12px;
            color: ${colors.red};
            font-size: 12px;
          }
          .collected {
            background-color: #E15655;
            padding: 0px 6px;
            color: white;
            font-size: 8px;
            border-radius: 2px;
            margin-right: 4px;
          }
        }
        .campaign-detail {
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          background: white;
          padding: 0px 8px 8px 8px;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          margin-bottom: 8px;
          .expire {
            color: ${colors.purple};
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 8px;
            display: flex;
            justify-content: center;        
          }
          // .slots {
          //   display: flex;
          //   flex-wrap: wrap;
          //   .stamp {
          //     width: 33%;
          //     padding: 4px;
          //     min-height: 54px;
         
          //   }
          // }

          .stamp-wrapper {
              display: flex;
              align-items: center;
              .status {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 25px;
                border-radius: 12px;
                margin-right: 4px;
                color: white;
                // background: ${props => props.color1};
                background: #c4c4c4;
                span {
                  font-size: 4px;
                }
                .stamped {
                  width: 20px;
                  margin-top: -1px;
                }
              }
              .stamp-detail {
                display: flex;
                flex-direction: column;
                font-size: 8px;
                span {
                  line-height: 1;
                }
              }
            }

          .cards {
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: space-between;
            .card {
              display: flex;
              justify-content: center;
              padding: 6px;
              background: ${colors.lightGrey};
              margin-right: 8px;
              margin-bottom: 8px;
              height: 44px;
              // width: 32%;
              .card-no {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                background: ${colors.bgGrey};
                border-radius: 50%;
                color: white;
                font-weight: bold;
                font-size: 14px;
              }
            }
          }

          .tos {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0px 0 18px 0;
            .title {
              color: ${colors.purple};
              font-size: 12px;
              font-weight: 600;
              margin: 4px 0;
              margin-left: 8px;
            }
            .detail {
              font-weight: 400;
              font-size: 11px;
              line-height: 1.3;
              color: #757575;
              ul {
                padding-left: 24px;
              }
              p {
                margin-bottom: 0px !important;  
              }
            }
          }
          button {
            :disabled {
              background: ${colors.bgGrey};
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #F1F1D0;
            width: 96%;
            height: 50px;
            border-radius: 8px;
            margin-bottom: 8px;
            span {
              color: ${colors.purple};
              &:nth-child(1) {
                font-weight: 600;
                font-size: 16px;
                color: rgb(0,0,0, 0.7);
              }
              &:nth-child(2) {
                font-weight: 400;
                font-size: 11px;
                color: #3B7779;                
              }
            }
          }
          .unavailable {
            background: ${colors.bgGrey};
          }
        }
      }
      &.unavailable {
        .title {
          color: ${colors.red};
        }
        .campaign-name {
          background-color: #cac7c7;
          opacity: 1;
          background-size: 10px 10px;
          background-image: repeating-linear-gradient(-45deg, ${colors.textGrey} 0, ${colors.textGrey} 1px, #ffffff00 0, #ffffff00 50%);
          &.active {
            background-color: #cac7c7;
            opacity: 1;
            background-size: 10px 10px;
            background-image: repeating-linear-gradient(-45deg, ${colors.textGrey} 0, ${colors.textGrey} 1px, #ffffff00 0, #ffffff00 50%);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            margin-bottom: 0;
            box-shadow: unset;
            color: ${colors.purple};
          }
        }
        .campaign-detail {
          button {
            background: ${colors.bgGrey};
          }
        }
      }
    }
    .ant-modal-content {
      border-radius: 25px;
      .close-icon {
        font-size: 30px;
        color: #3B7779;
      }
    }
    .auth-modal {
      display: flex;
      flex-direction: column;
      justfy-content: center;
      align-items: center;
      .title1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #575757;
      }
      .title2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #979797;
        margin: 16px 0;
      }
      .title3 {
        font-weight: 400;
        font-size: 16px;
        color: #3B3054;
        opacity: 0.5;
        margin: 16px 0;
      }
      .title4 {
        font-style: italic;
        font-weight: 100;
        font-size: 32px;
        line-height: 36px;
        color: #3B3054;
      }
      .title5 {
        color: ${colors.red};
      }
      .title6 {
        font-weight: 400;
        font-size: 14px;
        color: ${colors.purple};
        margin-top: 20px;
      }
      .title7 {
        font-weight: 700;
        font-size: 20px;
        color: #3B7779;
      }
      .status-icon-success {
        font-size: 100px;
        color: ${colors.green};
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .status-icon-fail {
        font-size: 100px;
        color: red;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .title8 {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
      }
      .title9 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #979797;        
        margin-top: 16px;
      }
      .btn-default, .btnEmpty {
        width: 80%;
        height: 40px;
        border-radius: 20px;
        margin-top: 20px;
      }
      .btn-success {
        width: 80%;
        height: 40px;
        background: #3B7779;
        border-radius: 10px;
        margin-top: 20px;
      }
      .btn-danger {
        width: 80%;
        height: 40px;
        background: #3B7779;
        border-radius: 10px;
        margin-top: 20px;
        background: red;
      }

      .success {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #3B3054;
        text-transform: uppercase;
      }
      .fail {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #3B3054;
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
