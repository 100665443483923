//Action Types
export const GET_MERCHANT = 'GET_MERCHANT';
export const GET_LOGO_BANNER = 'GET_LOGO_BANNER';
export const TOPUP_AND_PAY = 'TOPUP_AND_PAY';
export const GET_AVERAGE_RATES = 'GET_AVERAGE_RATES';
export const GET_TOTAL_REVIEWS = 'GET_TOTAL_REVIEWS';

//Action Creator
export const getMerchant = (data) => ({
   type: GET_MERCHANT,
   data: data
});

export const getLogoBanner = (data) => ({
   type: GET_LOGO_BANNER,
   data: data
});

export const topupAndPay = (data) => ({
   type: TOPUP_AND_PAY,
   data: data
});

export const getAverageRates = (data) => ({
   type: GET_AVERAGE_RATES,
   data: data
});

export const getTotalReviews = (data) => ({
   type: GET_TOTAL_REVIEWS,
   data: data
});