import { breakpoints } from "style";
import styled from "styled-components";

export const LoadingViewContainer = styled.div`
  position: absolute;
  z-index: 2000;
  top: 0;
  background: #b8b8bba3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {

  }
  @media (max-width: ${breakpoints.xl}px) {
  }
`;
