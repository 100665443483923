import { breakpoints, colors } from "style";
import styled from "styled-components";
import { StarBg } from "assets/images"

export const StampContainer = styled.div`
overflow: scroll;
  width: 100%;
  display: flex;
  flex: 1;
  // box-shadow:inset 0 0 0 2000px rgb(41 41 41 / 62%);
  background-color: ${props => props.color1};
  .stamp-container {
    // margin-top: 10px;
    // padding: 20px;
    flex: 1;
    width: 100%;
    // height: max-content;
    background: ${colors.white};
    .tabs {
      display: flex;
      text-align: center;
      background-color: #FFB84F;
      padding: 8px 0;
      height: 60px;
      .tab {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.5);
        position: relative;
        cursor: pointer;
        :not(:first-child) {
          border-left: solid 2px #FFFFFF;
        } 
        &.active {
          color: #FFFFFF;
          ::before {
            content: ""; 
            display: block;
            border-bottom: solid 4px #FFFFFF;
            position: absolute;
            bottom: -8px;
            width: 0;
            height: 0;
            border-width: 0 11.5px 13px 11.5px;
            border-color: transparent transparent #FFFFFF transparent;
            border-style: solid;
            z-index: 9;
          }
          ::after {
            content: ""; 
            display: block;
            border-bottom: solid 4px #FFFFFF;
            width: 70%;
            position: absolute;
            bottom: -8px;
          }
        }
      }
    }
    .body-wrapper {
      padding: 0px 18px;
    }
    .points-page-body {
      background-color: ${props => props.color2};
      background-image: url(${StarBg});
      background-repeat: no-repeat;
      background-position: right;
      border-radius: 10px;
      margin-top: 14px;
      padding: 20px 18px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.25);
      .balance-wrap {
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #263238;          
        }
        .balance {
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 28px;
          line-height: 33px;
          color: #FFFFFF;
          img {
            width: 25px;
            padding: 1px;
            margin-right: 6px;
          }
        }
      }
      button.top-up {
        background: #3B7779;
        box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
        border-radius: 7px;

        font-weight: 700;
        font-size: 15px;
        color: #FFFFFF;
        padding: 8px 22px;
      }
    }
    .claim-points {
      margin-top: 20px;
      padding: 0px 18px;
      background: #FFFFFF;
      box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.25);
      border-radius: 10px;
      text-align: center;
      padding: 14px 16px 4px 16px;
      label {
        font-weight: 700;
        font-size: 18px;
        color: #3B3054;        
      }
      .input {
        background: #FFFFFF;
        box-shadow: 0px 9px 27px rgba(225, 225, 225, 0.25);
        border-radius: 7px;
        border: none;
        padding: 24px 16px;
        margin-bottom: 10px;
      }
      .date {
        background: #FFFFFF;
        box-shadow: 0px 9px 27px rgba(225, 225, 225, 0.25);
        border-radius: 7px;
        border: none;
        padding: 14px 16px;
        margin-bottom: 10px;
        width: 100%;
      }
      .file-upload {
        margin-bottom: 8px;
      }
      button {
        background: rgba(255, 184, 79, 0.09);
        border: 1px dashed #FFB84F;
        border-radius: 7px;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #FFB84F;

        padding: 14px 16px;
        img {
          margin-right: 12px;
        }
        // :active, : hover {
        //   background-color: #db5655; 
        //   color: #F5F5F6;
        // }
      }
      .btn-submit {
        background: #3B7779;
        box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
        border-radius: 7px;
        font-weight: 700;
        font-size: 15px;
        color: #FFFFFF;
        text-transform: uppercase;
        padding: 12px 34px;
        margin-top: 6px;
        :active, : hover {
          background-color: transparent;
          color: var(--mr-color-2);
        }
      }
    }
    .transactions-page-body {
      height: 100%;
      margin-top: 20px;
      // padding: 20px;
      .header {
        color: #848484;
        text-align: center;
        // font-size: 12px;
      }
      .body {
        p {
          margin: 0;
        }
        text-transform: capitalize;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #3B7779;
      }
      .load-more {
        background-color: #db5655; 
        color: #F5F5F6;
        font-size: 14px;
        width: 100%;
        border-radius: 20px;
        padding: 6px 14px;
        margin-bottom: 12px;
        :active, : hover {
          background-color: transparent;
          border: solid 1px #DB5655;
          color: #DB5655;
        }
      }
    }
    .section-accordion {
      margin-top: 20px;
      // padding: 0px 18px;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: ${colors.purple};
      }
      .campaigns {
        margin-top: 4px;
        .campaign-name {
            width: 100%;
            min-height: 54px;
            background: white;
            padding: 12px;
            border-radius: 10px;
            font-weight: 600;
            text-transform: capitalize;
          .first {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
          }
          .second {
            padding-top: 3px;
            .badge {
              width: 94px;
              padding: 5px;
              background: #3B7779;
              border-radius: 100px;
              text-align: center;
              color: white;
              font-weight: 600;
              font-size: 11px;
              :not(:first-child) {
                margin-left: 10px;
              }
            }
            .voucher {
              background: #F1F1D0;
              color: #3B3054;
            }
            .redeemed {
              background: #3B7779;
            }
            .otherBadge {
              background: #FFB84F;
            }
            .delete {
              cursor: pointer;
              background: #DB5655;
            }
          }
          > div p {
            font-weight: 500;
            font-size: 14px;
            line-height: 1;
            margin-bottom: 2px;
          }
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #263238;
          }
          &.active {
            // background: linear-gradient(to bottom right, ${colors.red} , ${colors.yellow});
            // color: white;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            margin-bottom: 0;
            box-shadow: unset;
          }
          .status {
            position: absolute;
            right: 24px;
            background: white;
            padding: 2px 8px;
            border-radius: 12px;
            color: ${colors.red};
            font-size: 12px;
          }
        }
        .campaign-detail {
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          background: white;
          padding: 8px;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
          margin-bottom: 8px;
          .expire {
            color: ${colors.purple};
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 8px;
            display: flex;
            justify-content: center;        
          }
          // .slots {
          //   display: flex;
          //   flex-wrap: wrap;
          //   .stamp {
          //     width: 33%;
          //     padding: 4px;
          //     min-height: 54px;
         
          //   }
          // }

          .stamp-wrapper {
              display: flex;
              align-items: center;
              .status {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 25px;
                border-radius: 12px;
                margin-right: 4px;
                color: white;
                background: ${props => props.color1};
                span {
                  font-size: 4px;
                }
              }
              .stamp-detail {
                display: flex;
                flex-direction: column;
                font-size: 8px;
                span {
                  line-height: 1;
                }
              }
            }

          .cards {
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: space-between;
            .card {
              display: flex;
              justify-content: center;
              padding: 6px;
              background: ${colors.lightGrey};
              margin-right: 8px;
              margin-bottom: 8px;
              height: 44px;
              // width: 32%;
              .card-no {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                background: ${colors.bgGrey};
                border-radius: 50%;
                color: white;
                font-weight: bold;
                font-size: 14px;
              }
            }
          }

          .tos {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 8px 0 18px 0;
            .title {
              color: ${colors.purple};
              font-size: 12px;
              font-weight: 600;
              margin: 4px 0;
              margin-left: 8px;
            }
            .detail {
              font-weight: 400;
              font-size: 11px;
              line-height: 1.3;
              color: #757575;
              ul {
                padding-left: 24px;
              }
              p {
                margin-bottom: 0px !important;  
              }
            }
          }
          button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #F1F1D0;
            width: 96%;
            height: 50px;
            border-radius: 8px;
            margin-bottom: 8px;
            span {
              color: ${colors.purple};
              &:nth-child(1) {
                font-weight: 600;
                font-size: 16px;
                color: rgb(0,0,0, 0.7);
              }
              &:nth-child(2) {
                font-weight: 400;
                font-size: 11px;
                color: #3B7779;                
              }
            }
          }
          .unavailable {
            background: ${colors.bgGrey};
          }
        }
      }
      &.unavailable {
        .title {
          color: ${colors.red};
        }
        .campaign-name {
          background-color: #cac7c7;
          opacity: 1;
          background-size: 10px 10px;
          background-image: repeating-linear-gradient(-45deg, ${colors.textGrey} 0, ${colors.textGrey} 1px, #ffffff00 0, #ffffff00 50%);
          &.active {
            background-color: #cac7c7;
            opacity: 1;
            background-size: 10px 10px;
            background-image: repeating-linear-gradient(-45deg, ${colors.textGrey} 0, ${colors.textGrey} 1px, #ffffff00 0, #ffffff00 50%);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            margin-bottom: 0;
            box-shadow: unset;
            color: ${colors.purple};
          }
        }
        .campaign-detail {
          button {
            background: ${colors.bgGrey};
          }
        }
      }
    }
    .ant-modal-content {
      border-radius: 25px;
      .close-icon {
        font-size: 30px;
        color: #3B7779;
      }
    }
    .auth-modal {
      display: flex;
      flex-direction: column;
      justfy-content: center;
      align-items: center;
      .title1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #575757;
      }
      .title2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #979797;
        margin: 16px 0;
      }
      .title3 {
        font-weight: 400;
        font-size: 16px;
        color: #3B3054;
        opacity: 0.5;
        margin: 16px 0;
      }
      .title4 {
        font-style: italic;
        font-weight: 100;
        font-size: 32px;
        line-height: 36px;
        color: #3B3054;
      }
      .title5 {
        color: ${colors.red};
      }
      .title6 {
        font-weight: 400;
        font-size: 14px;
        color: ${colors.purple};
        margin-top: 20px;
      }
      .title7 {
        font-weight: 700;
        font-size: 20px;
        color: #3B7779;
      }
      .status-icon-success {
        font-size: 100px;
        color: ${colors.green};
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .status-icon-fail {
        font-size: 100px;
        color: red;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .title8 {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
      }
      .title9 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #979797;        
        margin-top: 16px;
      }
      .btn-default, .btnEmpty, .btnConfirm {
        width: 80%;
        height: 40px;
        border-radius: 20px;
        margin-top: 20px;
      }
      .btnConfirm {
        background-color: #db5655;
        color: #F5F5F6;
        :active, : hover {
          background-color: transparent;
          color: var(--mr-color-2);
        }
      }
      .btn-success {
        width: 80%;
        height: 40px;
        background: #3B7779;
        border-radius: 10px;
        margin-top: 20px;
      }
      .btn-danger {
        width: 80%;
        height: 40px;
        background: #3B7779;
        border-radius: 10px;
        margin-top: 20px;
        background: red;
      }

      .success {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #3B3054;
        text-transform: uppercase;
      }
      .fail {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #3B3054;
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
