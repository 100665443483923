import { breakpoints, colors } from "style";
import styled from "styled-components";

export const MerchantContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  overflow: scroll;
  .button-primary {
    display: flex;
    justify-content: end;
    color: white;
    background: #3B7779;
    box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
    border-radius: 7px;
    opacity: 0.97;
    text-transform: uppercase;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 118%;

      display: flex;
      align-items: center;

      color: #FFFFFF;
    }
  }
  .button-secondary {
    display: flex;
    justify-content: end;
    color: white;
    background: #FFB84F;
    box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
    border-radius: 7px;
    opacity: 0.97;
    text-transform: uppercase;
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 118%;

      display: flex;
      align-items: center;

      color: #FFFFFF;
    }
  }
  .merchant-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    position: relative;
    .reviews-wrap {
      padding-bottom: 40px;
    }
    .review-ratings {
      border: none;
      border-radius: 15px;
      margin: 6px 20px 0px 20px;
      background: #fff;
      padding: 12px;
      box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.35);
      .ratings-wrap {
        display: flex;
        align-items: center;
        position: relative;
        h1 {
          font-size: 36px;
          font-weight: 600;
          display: inline-block;
          margin-bottom: 0;
          margin-right: 8px;
          color: #FFB84F;
        }
        .num_of_reviews {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #B4B4B4;
          margin-bottom: 0;
        }
      }
    }
    .reviews-wrap {
      .photos {
        margin-right: 8px;
        .img-wrapper {
          position: relative;
          .zoom-photo {
            display: none;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 0;
            right: 0;
            margin: auto;
            text-align: center;
            z-index: 9;
            cursor: pointer;
          }
          :hover::after, :hover .zoom-photo {
            display: block;
          }
          ::after {
            content: "";
            display: none;
            width: 100%;
            height: 100%;
            position: absolute;
            border: none;
            border-radius: 10px;
            top: 0;
            left: 0;
            background: rgba(0,0,0,.3);
          }
        }
      }
    }
    .reviews {
      display: flex;
      position: relative;
      border: none;
      border-radius: 15px;
      margin: 6px 20px 0px 20px;
      background: #fff;
      padding: 12px;
      box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.35);
      .img {
        border: none;
        border-radius: 50%;
        height: 45px;
        width: 45px;
        margin-right: 10px;
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #7F7F7F;
        margin-bottom: 0;
      }
      .name-wrap {
        display: flex;
        justify-content: space-between;
        h3 {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.2px;
          margin-bottom: 0;
        }
        span {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          text-align: right;
          letter-spacing: 0.2px;
          color: #7F7F7F;
          margin-right: 4px;
        }
        svg {
          width: 12px;
          color: #7F7F7F;
        }
      }
    }
    .auth-modal {
      display: flex;
      flex-direction: column;
      justfy-content: center;
      align-items: center;
      .image-preview {
        position: relative;
        .file-buttons {
          display: none;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 0;
          right: 0;
          z-index: 9;
          cursor: pointer;
        }
        :hover::after, :hover .file-buttons {
          display: block;
        }
        ::after {
          content: "";
          display: none;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(0,0,0,.3);
        }
      }
      .upload-image {
        position: relative;
        width: 104px;
        height: 104px;
        margin-right: 8px;
        margin-bottom: 8px;
        text-align: center;
        vertical-align: top;
        background-color: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 2px;
        cursor: pointer;
        transition: border-color .3s;
        :hover {
          border-color: #1890ff;
        }
        .inner {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: 0;
          right: 0;
        }
      }
      .profile-img-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-conet: start;
        h3 {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #7F7F7F;
          margin-bottom: 0;
        }
        .profile-img {
          border: none;
          border-radius: 50%;
          height: 45px;
          width: 45px;
          margin-right: 10px;
          display: inline-block;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
      .footer {
        display: flex;
        justify-content: end;
        margin-left: auto;
        button:not(:last-child) {
          margin-right: 8px;
        }
      }
      .title1 {
        font-size: 24px;
        font-weight: bold;
        color: ${colors.purple};
        line-height: 36px;
      }
      .title2 {
        font-size: 18px;
        font-weight: bold;
        color: ${colors.textGrey};
        line-height: 36px;
        text-align: center;
        line-height: 22px;
        margin: 16px 0;
      }
      .title3 {
        font-size: 14px;
        font-weight: bold;
        color: ${colors.textGrey};
        line-height: 36px;
        text-align: center;
        line-height: 22px;
        margin: 16px 0;
      }
      .title4 {
        font-size: 36px;
        font-weight: bold;
        color: ${colors.purple};
        line-height: 36px;
      }
      .title5 {
        color: ${colors.red};
      }
      .title6 {
        font-size: 14px;
        font-weight: bold;
        color: ${colors.purple};
        margin-top: 20px;
      }
      .title7 {
        font-size: 24px;
        font-weight: bold;
        color: ${colors.textGrey};
        line-height: 36px;
      }
      .status-icon-success {
        font-size: 100px;
        color: ${colors.green};
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .status-icon-fail {
        font-size: 100px;
        color: red;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .title8 {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
      }
      .title9 {
        font-size: 14px;
        line-height: 16px;
        color: ${colors.purple};
        text-align: center;
        margin-top: 16px;
      }
      .btn-success {
        width: 80%;
        height: 40px;
        border-radius: 20px;
        margin-top: 20px;
      }
      .btn-danger {
        width: 80%;
        height: 40px;
        border-radius: 20px;
        margin-top: 20px;
        background: red;
      }

      .success {
        color: ${colors.green};
      }
      .fail {
        color: red;
      }
    }
    textarea::placeholder {
      font-size: 12px !important;
    }
    textarea {
      font-weight: 500;
      font-size: 12px !important;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
