import React from 'react';
import GlobalStyle from "style/globalStyle";
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import styled from "styled-components";
import DataContextContainer from "contexts/DataContextContainer";
import Routes from './routes'
import "./services/i18";

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`
function App() {
  return (
      <ModalProvider backgroundComponent={FadingBackground}>
        <div className="App">
          <GlobalStyle />
          <DataContextContainer>
            <Routes />
          </DataContextContainer>
        </div>
      </ModalProvider>
  );
}

export default App;
