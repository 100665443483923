/* eslint-disable */
const EMAIL_REG = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const PHONE_REG = /^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,4}?[-\s\.]?[0-9]{2,4}$/im 
const PHONE_REG_WITHOUT_COUNTRY_CODE = /^[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,4}?[-\s\.]?[0-9]{2,4}$/im 

const validation = {
  EMAIL_REG,
  PHONE_REG,
  PHONE_REG_WITHOUT_COUNTRY_CODE
}

export default validation
