import { CampaignStamp, CampaignPackage, CampaignVoucher, CampaignPromotion, CampaignPoint, CampaignWallet } from "assets/images";

export function get_campaignDefs(domain){
    return [
      {
        key: 'stamp',
        name: 'Stamp Card',
        route: `/${domain}/stamp`,
        icon: CampaignStamp,
        available: false,
        notification: 0
      },
      {
        key: 'voucher',
        name: 'Voucher',
        route: `/${domain}/voucher`,
        icon: CampaignVoucher,
        available: false,
        notification: 0
      },
      {
        key: 'package',
        name: 'Package',
        route: `/${domain}/package`,
        icon: CampaignPackage,
        available: false,
        notification: 0
      },
      {
        key: 'Referral',
        name: 'Invite Friend',
        route: `/${domain}/referral`,
        icon: CampaignPromotion,
        available: false,
        notification: 0
      },
      {
        key: 'points',
        name: 'Points',
        route: `/${domain}/points`,
        points: 0,
        icon: CampaignPoint,
        available: false,
        notification: 0
      },
      {
        key: 'wallet',
        name: 'Wallet',
        route: `/${domain}/wallet`,
        currency: '$',
        cash: '0.00',
        icon: CampaignWallet,
        available: false,
        notification: 0
      }
    ]
}
