import React, { useState } from "react";
// import { toast } from 'react-toastify';
import FailModal from "components/Reward/FailModal"
import SuccessModal1 from "components/Reward/SuccessModal1"

const DataContext = React.createContext(null);

export { DataContext };

function DataContextContainer(props) {
  const [lang, setLang] = useState("en");
  const [isLoading, setLoading] = useState(false);
  const [authUser, setAuthUser] = useState({});
  const [ showSuccessModal, setShowSuccessModal ] = useState(false)
  const [ showFailModal, setShowFailModal ] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ component, setComponent ] = useState('')
  const [ urlNavigate, setUrlNavigate ] = useState('')

  const showToast = (type, msg, footer, atrType, merchantName) => {
    if (atrType === 1) { setUrlNavigate(`/${merchantName}/profile`)}
    else if (atrType === 2) { setUrlNavigate(`/${merchantName}/profile`)}
    else if (atrType === 3) { setUrlNavigate(`/${merchantName}/ratings`)}
    else if (atrType === 4) { setUrlNavigate(`/${merchantName}/wallet`)} 

    // if(toast[type]){
    //   toast[type](msg);
    // }
    
    if(footer){
      setComponent(footer)
    }else{
      setComponent('')
    }
    if(type === 'error'){
      toggleFailModal()
      setMessage(msg)
    }else if(type === 'success'){
      toggleSuccessModal()
      setMessage(msg)
    }
  }

  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal)
  }

  const toggleFailModal = () => {
    setShowFailModal(!showFailModal)
  }

  const controlFailModal = (bool) => {
    setShowFailModal(bool)
  }

  return (
    <>
      <DataContext.Provider
        value={{
          lang,
          setLang,
          isLoading,
          setLoading,
          authUser,
          setAuthUser,
          showToast,
          controlFailModal,
        }}
      >
        {props.children}
      </DataContext.Provider>
      <FailModal 
        message={message}
        showModal={showFailModal}
        toggleModal={toggleFailModal}
        footer={component}
        url={urlNavigate}
      />
      <SuccessModal1 
        message={message}
        showModal={showSuccessModal}
        toggleModal={toggleSuccessModal}
      />
    </>
  );
}
export default DataContextContainer;
