

import { HomeContainer } from "./style";
import { Group427 } from "assets/images"

function Home() {
  return (
    <HomeContainer>
      <div className="text-center mt-5">
        <a href="https://morerewardz.com/">
          <img src={Group427} alt="" width={300} />
        </a>
        <h1>404 Page Not Found</h1>
      </div>
    </HomeContainer>
  );
}

export default Home;