import { breakpoints, colors } from "style";
import styled from "styled-components";
import { BackgroundWallet } from "assets/images";

export const CampaignsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 18px 16px 0px 16px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .campaign {
      position: relative;
      background-color: white;
      display: flex;
      // flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 12px;
      width: 48%;
      padding: 6px;
      margin-bottom: 12px;
      line-height: 1;
      box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07)!important;
      // box-shadow: -13px -3px 16px -2px rgb(0 0 0 / 12%);
      &.unavailable {
        background-color: #cac7c7 !important;
        opacity: 1 !important;
        background-size: 10px 10px !important;
        background-image: repeating-linear-gradient(-45deg,#b4b2b7 0,#b4b2b7 1px,#ffffff00 0,#ffffff00 50%) !important;
        ::after {
          content: "Not Available";
          font-size: 12px;
          color: #808080;
          transform: rotateZ(-28deg);
          transform-origin: center;
          position: absolute;
        }
      }
      img {
        width: 30%;
      }
      span {
        color: ${colors.red};
        font-size: 18px;
        font-weight: 500;
      }
      // &.wallet {
      //   background-color: transparent;
      //   background-image: url(${BackgroundWallet});
      //   background-size: cover;
      //   box-shadow: -13px -3px 16px -2px rgb(0 0 0 / 12%) !important;
      //   span {
      //     color: white;
      //   }
      // }
      .badge {
        position: absolute;
        width: 24px;
        height: 24px;
        background: red;
        border-radius: 15px;
        top: -6px;
        right: -6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 14px;
      }
    }
    a.campaign:hover{
      text-decoration: none;
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;

export const CampaignsProfile = styled.div`
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    padding: 4px 12px 16px 12px;
    border: none;
    border-radius: 15px;
    overflow: hidden;
    background: #fff;
    
    margin-bottom: 14px;
    .profile-wrap {
      display: flex;
    }
    // @media (max-width: 414px) {
    //   .profile-info {
    //     margin: 8px 0 16px;
    //   }
    // }
    .profile-icon {
      border: solid #3B7779 1px;
      border-radius: 50%;
      height: 56px;
      width: 56px;
      background: #fff;
      margin-right: 8px;
      display: inline-block;
    }
    // @media (max-width: 414px) {
    //   .profile-icon {
    //     height: 50px;
    //     width: 50px;
    //   }
    // }
    .profile-info {
      display: inline-block;
      span {
        font-size: 12px;
        line-height: 1;
      }
      h3 {
        font-weight: 600;
        font-size: 17px;
        line-height: 23px;
        color: #3B3054;
        margin-bottom: 0.1rem;
      }
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #3B3054;
        margin-bottom: 0.3rem;
      }
    }
    .logout {
      border-radius: 20px !important;
      font-size: 18px !important;
      font-weight: bold !important;
      width: 90px;
      height: 22px;
      box-shadow: 6px 10px 18px rgba(77, 121, 120, 0.35);
      // background-color: ${props => props.color2};
      // border-color: ${props => props.color2};

      font-weight: 600;
      font-size: 12px;
      line-height: 0.5;

      color: #FFFFFF;
    }
`;
