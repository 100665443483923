import { breakpoints, colors } from "style";
import styled from "styled-components";
import { Home1 } from "assets/images";

export const HomeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  img {
    max-width: 100%;
    object-fit: cover;
  }
  .left-image {
    height: 100%;
    background-image: url(${Home1});
    background-size: cover;
    background-position: center;
  }
  .home-container {
    flex: 1;
  }
  .signup-form {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    h2 {
      color: ${colors.purple};
    }
    h4 {
      color: ${colors.grey};
    }
    .form-control {
      background: ${colors.lightGrey};
      height: 44px;
      border-width: 0;
      &:focus, &:active {
        outline:0px !important;
        -webkit-appearance:none;
        box-shadow: none !important;
      }
    }
    span {
      margin-top: 40px;
      align-self: center;
      color: ${colors.red};
      a {
        color: ${colors.red};
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;