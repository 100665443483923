  /* eslint-disable */
import { useRef, useEffect, useState, useContext } from 'react';
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import { Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import { Form, InputGroup } from "react-bootstrap";
import { Row, Col } from 'react-bootstrap';
import { DatePicker } from 'antd';
import Accordion from 'react-bootstrap/Accordion'
import parse from 'html-react-parser';
import AccordionItem from 'components/Elements/StyledAccordionItem';
import { StampContainer } from "./style";
import { DataContext } from "contexts/DataContextContainer";
import {connect} from 'react-redux';
import { getPointsBalance } from 'redux/actions/roleActions';
import {
  FaTimesCircle,
} from "react-icons/fa";
import { AuthSuccess, AuthFail } from "assets/images"
import { get_campaignDefs } from "functions.js"
import { Voucher } from "assets/images"
import moment from 'moment';
import { Starpoint, carbonReceipt } from "assets/images"
import { isEmpty } from 'lodash';
import TopNav from "components/TopNav/index"
import getSymbolFromCurrency from 'currency-symbol-map'

import client from "feathers.js"
import axios from 'axios';
import classNames from 'classnames';

import { useTranslation as translation, Trans } from 'react-i18next';
import 'services/i18'

const Schema = Yup.object().shape({
  receiptNumber: Yup.string().required("Required!"),
  datePurchase: Yup.date().required("Required!"),
  amount: Yup.number().required("Required!"),
});

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      format="DD/MM/YYYY"
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

const Points = props => {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const {t} = translation();

  const fileEl = useRef()
  const history = useHistory();
  const { setLoading, showToast } = useContext(DataContext)
  const [ activeTab, setActiveTab ] = useState(0)
  const [ activeKey, setActiveKey ] = useState(-1)
  const [ activeValue, setActiveValue ] = useState({})
  const [ showModal, setShowModal ] = useState(false)
  const [showTransactionResultModal, setShowTransactionResultModal] = useState(false)
  const [transactionResult, setTransactionResult] = useState(null)
  const [ points, setPoints ] = useState([])
  const [ campaignDef ] = useState(get_campaignDefs('')[4])
  const [ done, setDone ] = useState(false)
  const [ skip, setSkip ] = useState(0)
  const [ data, setData ] = useState([])

  useEffect(() => {
    client.authenticate()
    .then((auth) => {
      return axios.post(`${client.io.io.uri}getPointsBalance`, {
        userId: props.userInfo._id,
        merchantId: props.merchant._id,
      }, {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      props.getPointsBalance(res.data.balance)
    })
    .catch((err)=>{
      console.log(err)
      // if(err.name === "NotAuthenticated"){  
      //   showToast('error', 'Login to continue.')
      // }
      // showToast('error', err.message)
      // setLoading(false)
    })
    /* eslint-disable-next-line */
  }, [])

  useEffect(() => {
    fetchRePoints()
    /* eslint-disable-next-line */
  }, [props.merchant._id])

  useEffect(() => {
    fetchTransactions(0)
    /* eslint-disable-next-line */
  }, [])

  const fetchTransactions = (innerSkip) => {
    setLoading(true)

    var skipped = skip+innerSkip
    client.authenticate()
    .then(() => {
      return client.service('transactions').find({
        query: {
          userId: props.userInfo._id,
          $skip: skipped,
          type: "points",
          $sort: {
            createdAt: -1
          },
          $limit: 10
        }
      })
    })
    .then((res) => {
      setSkip(skipped)
      setData(data.concat(res.data))
      setLoading(false)
      if(res.data.length === 0){
        setDone(true)
      }
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){  
        // props.toggleLoginModal()
      }else{
        showToast('error', err.message)
      }
      setLoading(false)
    })
  }

  const fetchCampaign = (key) => {
    setActiveTab(key)
    // setLoading(true)
  }

  const fetchRePoints = () => {
    setLoading(true)
    client.service('points').find({
      query: {
        // for merchant create
        active: true,
        userId: props.merchant._id,
      }
    })
    .then((res) => {
      console.log(res)
      setPoints(res.data)
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      if(err.name === "NotAuthenticated"){
        
      }else{
        showToast('error', err.message)
      }
      setLoading(false)
    })
  }

  const back = () => {
    history.goBack()
  }

  const redeem = (value) => {
    setShowModal(true)
  }

  const confirmRedeem = () => {
    setLoading(true)

    if(!isEmpty(activeValue)){
      client.authenticate()
      .then((auth) => {
        return axios.post(`${client.io.io.uri}checkPointsBalanceRedeem`, {
          title: activeValue.title,
          merchantId: props.merchant._id,
          merchantName: props.merchant.username,
          pointId: activeValue._id,
          userId: props.userInfo._id,
          cost: activeValue.cost,
        }, {
          headers: {
            'Authorization': auth.accessToken
          }
        })
      })
      .then((res) => {
        setLoading(false)
        setShowModal(false)

        if(res.status === 200){
          setTransactionResult('success')
          setShowTransactionResultModal(true)
          
          // Re Fetch if needed
          // fetchPackages()
          setActiveKey(-1)
  
          props.getPointsBalance(Number(props.points) - Number(activeValue.cost))
        }else{
          setTransactionResult('fail')
          setShowTransactionResultModal(true)
        }
        
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){  
          props.toggleLoginModal()
        }else{
          showToast('error', err.message)
        }
        setLoading(false)
      }) 
    }
  }

  const handleImgChange = (e, setFile) => {

    let singleFile = e.target.files[0]
    // let fileType = (singleFile.type === "image/jpeg" || singleFile.type === "image/jpg" || singleFile.type === "image/png" || singleFile.type === "image/gif")

    // if(singleFile.size <= 2000000 && fileType){
      let newFileName = (Date.now() + "_" + 
        props.userInfo._id + "_" + 
        singleFile.name)
      let formData = new FormData()
      formData.append('file', singleFile, newFileName)

      let modified = formData.get('file')
      modified.originFileName = singleFile.name

      setFile('file', modified)

      return e.target.value = null
    // }else{
    //   window.alert("File size Limit: 2000000 kb!")
    //   return false
    // }
  }

  const fileUpload = async(file) => {

    const formData = new FormData();
    formData.append('NAME', 'Fred');
    formData.append('file', file)
    
    return await axios({
      method: 'post',
      url: `${client.io.io.uri}uploadFileLocal`,
      data: formData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then((res) => {
      return (res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const onSubmit = async(value, { resetForm }) => {

    if(props.merchant.activePoint !== 1){
      showToast('error', 'Merchant has deactivated this feature!')
      return false
    }

    setLoading(true)

    value.merchantId = props.merchant._id
    value.currency = props.merchant.currency
    
    if(value.file){
      if(value.file.name){
        let productDataUrl = await fileUpload(value.file)
        
        // file.name
        value.receiptFile.fileName = productDataUrl
        value.receiptFile.originFileName = value.file.originFileName
      }
    }

    value.desc = `You have submitted a receipt: ${value.receiptNumber} ${getSymbolFromCurrency(props.merchant.currency)} ${value.amount} from ${props.merchant.username}`
    value.merchantDescription = `${props.userInfo.email} has submitted a receipt: ${value.receiptNumber} ${getSymbolFromCurrency(props.merchant.currency)} ${value.amount}`

    client.authenticate()
    .then(()=>{
      return client.service('points-submissions').create(value)
    })
    .then((res) => {
      resetForm()
      setLoading(false)

      showToast('success', 'Your receipt is submitted successfully.')
    })
    .catch((err)=>{
      setLoading(false)
      if(err.name === "NotAuthenticated"){  
        props.toggleLoginModal()
      }else{
        showToast('error', err.message)
      }
    })
  }

  const _activeStamp = (value, index) => {
    if(activeKey === index){
      setActiveKey(-1)
    }else{
      setActiveKey(index)
      setActiveValue(value)
    }
  }

  const RenderCampaign = ({activeTab}) => {
    if(activeTab === 0){
      return (
        <>
          <div className='points-page-body'>
            <div className='balance-wrap'>
              <p>{t('Points CurrentBalance')}</p>
              <h4 className='balance'>
                <img src={Starpoint} alt="" />
                <span>{props.points}{t('Points BalancePoints')}</span>
              </h4>
            </div>
            <div>
              <button 
                className='top-up shadow'
                onClick={() => setActiveTab(2)}
              >{t('Points Transaction')}</button>
            </div>
          </div>

          <Formik
            // enableReinitialize
            validationSchema={Schema}
            onSubmit={onSubmit}
            initialValues={{
              receiptNumber: "",
              datePurchase: "",
              amount: 0,
              file: null,
              receiptFile: {
                fileName: "",
                originFileName: ""
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              values,
              errors,
            }) => (
            <Form onSubmit={handleSubmit} className="w-100">
              <div className='claim-points'>
                <label>{t('Points ClaimNewPoints')}</label>

                <DatePickerField 
                  value={values.datePurchase}
                  name="datePurchase"
                  className='date'
                  placeholder={t('Points DateOfPurchase')}
                />
                <Form.Control.Feedback type="invalid" className={classNames('input-group-feedback', {
                  'd-block': errors.datePurchase
                })}>{errors.datePurchase}</Form.Control.Feedback>

                <Form.Control
                  type="text"
                  name="receiptNumber"
                  value={values.receiptNumber}
                  onChange={handleChange}
                  isInvalid={!!errors.receiptNumber}
                  className='input'
                  placeholder={t('Points Receipt')}
                />
                <Form.Control.Feedback type="invalid" className='input-group-feedback'>{errors.receiptNumber}</Form.Control.Feedback>

                <Form.Control
                  type="number"
                  name="amount"
                  value={Number(values.amount).toString()}
                  onChange={handleChange}
                  isInvalid={!!errors.amount}
                  className='input'
                  placeholder="Amount"
                />
                <Form.Control.Feedback type="invalid" className='input-group-feedback'>{errors.amount}</Form.Control.Feedback>
                
                <div className="file-upload">
                  <input 
                    type="file" 
                    ref={fileEl} 
                    onChange={(e) => handleImgChange(e, setFieldValue)} 
                    hidden />                
                  <button
                    type='button'
                    className='btn upload-file'
                    onClick={() => fileEl.current.click()}
                  >
                    <img src={carbonReceipt} alt="" />
                    <span>{t('Points UploadReceipt')}</span>
                  </button>
                  <span>
                    {
                      values.file && values.file.originFileName
                    }
                  </span>
                </div>

                <div className='w-100 text-center'>
                  <button type="submit" className='btn-submit'>{t('Points Submit')}</button>
                </div>
              </div>
            </Form>
              )}
          </Formik>
        </>
      )
    }else if(activeTab === 1){
      return (
        <>
          <Accordion activeKey={activeKey}>
            <div className='section-accordion available'>
              <p style={{
                textAlign: 'center',
                fontWeight: 700,
                fontSize: 18,
                color: '#3B3054'
                }}>{t('Points Catalogue Message')}</p>
              {/* <div className='title'>
                <span>Invite Your Friend</span>
              </div> */}
              <div className='campaigns'>
                
                {
                  points.length > 0?
                  points.map((_cm, _i) => {
                    // if( (new Date() >= new Date(_cm.period_start)) && (new Date() <= new Date(_cm.period_end)) && _cm.redeemed !== true ){
                      return (
                        <AccordionItem
                          key={_i}
                          eventKey={`available_${_i}`}
                          renderToggle={() =>
                            <AccordionHeader index={`available_${_i}`} value={_cm}/>
                          }
                          renderContent={() =>
                            <AccordionContent index={`available_${_i}`} value={_cm}/>
                          }
                        />
                      )
                    // }else{ return null}
                  })
                  : (
                    <div className='text-center'>
                      <br/>
                      <img src={Voucher} alt="" />
                      <br/>
                      <br/>
                      <p style={{
                        fontWeight: 400,
                        fontSize: 16,
                        color: '#CDD9D9'
                      }}>{t('Points No Voucher')}</p>
                      <br/>
                    </div>
                  )
                }
                
              </div>
            </div>
          </Accordion>
        </>
      )
    }else{
      return (
        <>
          <div className='transactions-page-body'>
            <Row className='header mb-2'>
            <Col>{t('Points Date')}</Col>
            {/* <Col>Time</Col> */}
            <Col>{t('Points Type')}</Col>
            {/* <Col>Description</Col> */}
            <Col>{t('Points Action')}</Col>
          </Row>
            {
              data.length > 0?
              data.map((x, i) => {
                return (
                  <div key={i} className="body">
                    <Row className="align-items-center mb-2">
                      <Col>
                        <p>
                          {moment(x.createdAt).format('DD MMM YY')}
                          <br/>
                          {moment(x.createdAt).format('HH:MM')}
                        </p>
                      </Col>
                      <Col>
                      {
                          x.creditDebit === 'credit'? 
                          "Add Points"
                          : 
                          "Exchange Points"
                        }
                      </Col>
                      <Col>
                        {
                          x.creditDebit === 'credit'? 
                          <span className='text-success'>{'+ ' + x.amount}</span>
                          : 
                          <span className='text-danger'>{'- ' + x.amount}</span>
                        }
                      </Col>
                    </Row>
                    <hr/>
                  </div>
                )
              }): <p style={{textAlign: 'center', color: '#BDBDBD'}}>{t('Points NoTransaction')}</p>
            }
            {
              !done && data.length >= 15? (
                <button 
                  className='load-more'
                  onClick={() => fetchTransactions(15)}
                >Load more</button>
              ): null
            }
          </div>
        </>
      )
    }
  }

  const AccordionHeader = ({ index, value, status }) => {

    return (
      <div 
        className={`campaign-name ${activeKey === index? 'active': false}`} 
        onClick={() => {
          _activeStamp(value, index)
        }}
      >
        <div className='first'> 
          <div>
            <span>{value.title}</span>
            <p style={{fontWeight: 500}}>{t('Points PointsRequired')}: {value.cost}</p>
          </div>
          <span><i className={`fas fa-angle-${activeKey === index ? 'down' : 'right'}`}/></span>
        </div>
        <div className='second'>
          <div className={classNames('badge voucher')}>
            {value.rewardType}
          </div>
        </div>
      </div>
    )
  }

  const AccordionContent = ({ index, value, status }) => {

    return (
      <div className='campaign-detail'>
        <span className='expire'>
          <Trans i18nKey='Points VoucherExpiry' values={{amount: value.campaign_validity, unit: value.campaign_validity_unit}} />
        </span>
        <div className='tos'>
          <span className='title'>{t('Points TnC')}</span>
          <div className='detail'>
            <ul style={{marginBottom: 0}}>
              <li>This voucher is valid ${value.campaign_validity} ${value.campaign_validity_unit} upon first collect</li>
            </ul>
            {parse(value.tos)}
          </div>
        </div>
        <button
          style={{width: 'auto'}}
          className="m-auto px-4"
          onClick={() => redeem(value)}
        >
          <span>{t('Points Redeem')}</span>
        </button>
      </div>
    )
  }

  return (
    <StampContainer color1={color1} color2={color2}
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      <div className='stamp-container' id="stamp-container">
        <TopNav 
          name={t(`Home ${campaignDef.name}`)}
          back={back}
          merchant={props.merchant}
          userInfo={props.userInfo}
          signout={props.signout}
          toggleLoginModal={props.toggleLoginModal}
        />

        <div className='tabs'>
          <div className={classNames('tab', {
            active: activeTab === 0
          })}
          onClick={() => fetchCampaign(0)}
          >{t('Points ClaimPoints')}</div>
          <div className={classNames('tab', {
            active: activeTab === 1
          })}
          onClick={() => fetchCampaign(1)}>{t('Points Redemption Catalogue')}</div>
          <div className={classNames('tab', {
            active: activeTab === 2
          })}
          onClick={() => fetchCampaign(2)}>{t('Points Transaction')}</div>
        </div>

        <div className='body-wrapper'>

          <RenderCampaign activeTab={activeTab} />

        </div>
      </div>

      <Modal
        title={false}
        visible={showModal}
        onCancel={() => {
          setShowModal(false)
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='auth-modal'>
          <span className='title1 text-center'>
          <img src={campaignDef.icon} style={{width: 30, marginRight: 8, lineHeight: 1.2}} alt="" />
            <br/>
            {t('Points Title')} <br/>{t('Points Redemption')}
          </span>
          <span className='title2'>
            <Trans i18nKey='Points Redeem Message' values={{amount: activeValue.cost}} />
          </span>
          
          <div className='d-flex align-items-center w-100'>
            <button onClick={() => setShowModal(false)} className='btn btnEmpty mr-1'>{t('Points Cancel')}</button>
            <button onClick={() => confirmRedeem()} className='btn btnConfirm ml-1'>{t('Points Confirm')}</button>
          </div>
        </div>
      </Modal>

      <Modal
        title={false}
        visible={showTransactionResultModal}
        onCancel={() => {
          setShowTransactionResultModal(false)
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='auth-modal'>
          {/* <span className='title1'>Authentication</span> */}
          {
            transactionResult === 'success' ? <img src={AuthSuccess} alt="" /> : <img src={AuthFail} alt="" />
          }
          {/* <span className={transactionResult === 'success' ? 'title8 success' : 'title8 fail'}>{transactionResult === 'success' ? 'Transaction is' : 'Transaction is'}</span> */}
          <br/>
          <span className={transactionResult === 'success' ? 'title8 success' : 'title8 fail'}>{transactionResult === 'success' ? t('Points Successful') : t('Points Unsuccessful')}</span>
          <button onClick={() => setShowTransactionResultModal(false)} className={transactionResult === 'success' ? 'btn-success' : 'btn-danger'}>{t('Points Done')}</button>
        </div>
      </Modal>
    </StampContainer>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  getPointsBalance: getPointsBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(Points);
