import React, { useRef, useContext, useState, useEffect } from "react";
import { withRouter } from 'react-router'
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TabBar from "./TabBar";
import { PageLayoutContainer } from "./style";
import { Scrollbars } from "react-custom-scrollbars";
import { DataContext } from "contexts/DataContextContainer";
import LoadingView from "components/Elements/LoadingView";
import {connect} from 'react-redux';
import { logout } from 'redux/actions/roleActions';
import { getMerchant } from 'redux/actions/extraActions';
import ModalConfirmation from 'components/Extra/ModalConfirmation';
import { Formik } from "formik";
import * as Yup from "yup";
import { Col, Form } from "react-bootstrap";
import StyledButton from "components/Elements/StyledButton";

import Login from "containers/customer/Login"
import Reward from "components/Reward/Reward"
import SuccessModal from "components/Reward/SuccessModal"
import client from "feathers.js"
import { isEmpty } from "lodash";
import { gapi } from 'gapi-script';
import env from "env"

const PageLayout = ({ children, ...props }) => {
  const { googleAnalyticsTrackingId } = props.merchant;
  const adminGAID = env.superAdminGA;
  const childRef = useRef(null);
  const { setLoading, showToast } = useContext(DataContext);
  const { isLoading } = useContext(DataContext);
  const [ isLogin, setIsLogin ] = useState(true)
  const [ isVerifying, setIsVerifying ] = useState(false)
  const [ showModal, setShowModal ] = useState(false)
  const [ showRewardModal, setShowRewardModal ] = useState(false)
  const [ showSuccessModal, setShowSuccessModal ] = useState(false)
  const [ showForgotModal, setShowForgotModal ] = useState(false)
  const [ emailRegister, setEmailRegister ] = useState(false)
  const [ rewardData, setRewardData ] = useState({})
  const [ applicableTo, setApplicableTo ] = useState('new')
 
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const vcode = urlParams.get('code')
  const email = urlParams.get('email')

  const username = props.match.params.username
  const refLinkId = props.match.params.refLinkId

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: ["openid", "email", "profile"]
      });
    };
    if(!window["gapi"]){
      gapi.load('client:auth2', initClient);
    }

    if (googleAnalyticsTrackingId !== '') {
      ReactGA.initialize([
        {
          trackingId: adminGAID,
        },
        {
          trackingId: googleAnalyticsTrackingId,
        }
      ]);
    } else {
      ReactGA.initialize(adminGAID);
    }
  });
  
  // useEffect(() => {
  //   function setCookie(cname, cvalue, exdays) {
  //     const d = new Date();
  //     d.setTime(d.getTime() + (exdays*24*60*60*1000));
  //     let expires = "expires="+ d.toUTCString();
  //     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  //   }
  //   setCookie('asdsa', 'zxcxzc', 1)
  // }, [])

  useEffect(() => {
      if(props.role === 'superadmin' || props.role === 'merchant'){
        signout()
      }
      
      if(!props.userInfo._id){
        console.log('signing out')
        signout()
        // props.history.push(`/${username}`);
      }
      if(props.userInfo.merchantId){
        if(props.userInfo.merchantId !== props.merchant._id){
          signout()
        }
      }

      let domain_verify = username
      // ? username: props.merchant.username

      // do this
      // update merchant info
      
      client.authenticate()
      .then((res) => {
        
      })
      .catch((err) => {
        if(err.name === "NotAuthenticated"){  
          props.logout()
        }
      })
  
      if(domain_verify){
        let query = {
          campaginsCount: true,
          username: domain_verify,
          set_done: 'done',
          $limit: 1
        }

        if(props.userInfo._id){
          query = {
            campaginsCount: true,
            customerId: props.userInfo._id,
            username: domain_verify,
            set_done: 'done',
            $limit: 1
          }
        }

        client.service('merchants').find({
          query,
        })
        .then((res) => {
          if(res.data.length > 0){
            if(props.userInfo._id){
              
              client.service('users').find({
                query: {
                  _id: props.userInfo._id,
                  merchantId: res.data[0]._id
                },
              })
              .then((res1) => {
                if(res1.data.length > 0){

                }else{
                  signout()
                }
              })

            }
            props.getMerchant(res.data[0])
            setLoading(false)
            
            checkSubEnd(res.data[0])

            if(refLinkId){
              getReward(res.data[0])
            }
            
            // functions
            checkIsVerifying()
            
          }else{
            showToast('error', 'Merchant ID not found!')
            props.history.push(`/`);    
          }
        })
        .catch((err) => {
          console.log(err)
          if(err.name === "NotAuthenticated"){
        
          }else{
            showToast('error', err.message)
          }
        })
      }else{
        showToast('error', 'Merchant ID not found!')
        props.history.push(`/`);
      }
    /* eslint-disable-next-line */
  }, [username, props.merchant.username, props.userInfo._id]) 

  const checkIsVerifying = () => {
    // if no login
    if(!props.userInfo._id){
      if(vcode && email){
        client.service('users').find({
          query: {
            vcode,
            email,
            $limit: 1,
            $sort: {"createdAt": -1}
          }
        })
        .then((res) => {
          if(res.data.length > 0){
            setIsVerifying(true)
            setShowModal(true)
          }
        })
      }
    }
  }

  const getReward = (merchant) => {
    if(refLinkId){
      client.service('referral-shares').find({
        query: {
          getReferralInfo: true,
          refLinkId: refLinkId,
          merchantId: merchant._id,
          $limit: 1,
          $sort: {"createdAt": -1}
        }
      })
      .then((res) => {
        if(res.data.length > 0){
          if(!res.data[0].referralInfo.active){
            showToast('error', 'This link is not available!')
            return false
          }
          setRewardData(res.data[0])
          setApplicableTo(res.data[0].applicableTo)

          if((new Date() >= new Date(res.data[0].period_start)) && (new Date() <= new Date(res.data[0].period_end))){
            if(props.userInfo._id){
              client.service('voucher-pouches').find({
                query: {
                  referralSahreId: res.data._id,
                  userId: props.userInfo._id
                }
              })
              .then((res1) => {
                if(res1.data.length > 0){
                  // props.history.push(`/${merchant.username}`)
                  setShowRewardModal(false)
                }else{
                  // toggleRewardModal()
                }
              })
            }else{
              toggleRewardModal()
            }
          }else{
            showToast('error', 'Campaign is not ready yet!')    
          }

        }else{
          props.history.push(`/${merchant.username}`)
        }
      })
      .catch((err)=>{
        console.log(err)
        if(err.name === "NotAuthenticated"){
          
        }else{
          showToast('error', err.message)
        }
        setLoading(false)
      })
    }else{
      setShowRewardModal(false)   
    }
  }

  const signout = () => {
    client.authenticate()
    .then(() => {
      client.logout()
      props.logout()
      localStorage.clear()
      window.location.reload(true)
    })
    .catch((err) => {
      props.logout()
    })
  }

  const checkSubEnd = (res) => {
    let bool = true
    if(res.subscription !== 'copper'){
      if(new Date(res.subEnd) < new Date()){
        showToast('error', "Opps... It seems like this Merchant's subscription has ended, Contact this Merchant for more informations!")
        bool = false
      }
    }
    return bool
  }

  const toggleLoginModal = () => {
    setShowModal(!showModal)
  }

  const toggleForgotPassword = () => {
    setShowForgotModal(!showForgotModal)
  }

  const modalCallback = () => {
  }

  const toggleRewardModal = () => {
    setShowRewardModal(!showRewardModal)
  }

  const acceptReward = () => {
    setShowRewardModal(false)
    setShowModal(true)
    setIsLogin(false)
    signout()
  }

  const acceptRewardMember = () => {
    setShowRewardModal(false)
    setShowModal(true)
    setIsLogin(true)
    signout()
  }

  const toggleSuccessModal = (emailRegister) => {
    if(emailRegister){
      setEmailRegister(true)
    }
    setShowSuccessModal(!showSuccessModal)
  }

  const viewVoucher = () => {
    setShowSuccessModal(false)
    setShowRewardModal(false)
    props.history.push(`/${props.merchant.username}/voucher?view=true`)
  }

  const forgotPassword = async (value, { resetForm }) => {
    setLoading(true)

    client.service('forgot-passwords').create({
      email: value.email,
      role: 'user',
      merchantId: props.merchant._id,
      merchantName: props.merchant.username
    })
    .then((res) => {
      resetForm()
      showToast('success', 'Email sent, Check your inbox shortly.')
      setShowForgotModal(false)
      setLoading(false)
    })
    .catch((err)=>{
      showToast('error', err.message)
      setLoading(false)
    })
  }

  return (
    <Scrollbars
      ref={childRef}
    >
      <PageLayoutContainer isCustomer={true}>
        <div className="page-content shadow-sm" style={{
          maxHeight: 844,
          maxWidth: 388
        }}>
          {
            React.cloneElement(children, { 
              merchant: props.merchant,
              userInfo: props.userInfo,
              auth: props.auth,
              balance: props.balance,
              points: props.points,
              toggleLoginModal,
              isLogin,
              setIsLogin,
              checkSubEnd,
              signout,
            })
          }
          <TabBar 
            merchant={props.merchant} 
            auth={props.auth} 
            toggleLoginModal={toggleLoginModal}
            signout={signout} 
          />
        </div>
        <ToastContainer className='toast-container'/>
        <LoadingView isLoading={isLoading}/>
      </PageLayoutContainer>
      <Login 
        showModal={showModal}
        toggleLoginModal={toggleLoginModal}
        toggleForgotPassword={toggleForgotPassword}
        toggleSuccessModal={toggleSuccessModal}
        isLogin={isLogin}
        setIsLogin={setIsLogin}
        refLinkId={refLinkId}
        showRewardModal={showRewardModal}
        verifying={isVerifying}
        emailRegister={emailRegister}
        merchant={props.merchant}
        email={email}
      />
      <Reward 
        showModal={showRewardModal}
        toggleRewardModal={toggleRewardModal}
        acceptReward={acceptReward}
        acceptRewardMember={acceptRewardMember}
        refLinkId={refLinkId}
        merchant={props.merchant}
        data={!isEmpty(rewardData)?rewardData: {}}
        applicableTo={applicableTo}
      />
      <SuccessModal 
        showModal={showSuccessModal}
        toggleModal={toggleSuccessModal}
        toggleLoginModal={toggleLoginModal}
        viewVoucher={viewVoucher}
        emailRegister={emailRegister}
        merchant={props.merchant}
      />
      <ModalConfirmation 
        modalVisible={showForgotModal}
        toggleModal={toggleForgotPassword}
        modalCallback={modalCallback}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Forgot Password
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>
            Please enter the email that was used to set up your account. You will receive an email with instructions on how to reset your password.
            </span>
            <br/>

            <Formik
              validationSchema={
                Yup.object().shape({
                  email: Yup.string().email().required("Required!")
                })
              }
              onSubmit={forgotPassword}
              initialValues={{
                email: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                isValid,
                errors,
                dirty,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Row>
                    <Col md={12}>
                      <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                        placeholder="Enter Email Address"
                      />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Col>
                  </Form.Row>
                  <StyledButton
                    sizeh={44}
                    type="submit"
                    className="btn-blue mt-5"
                    radius="7"
                    bgcolor={"#3B7779"}
                    textTransform="uppercase"
                    weight={700}
                    title="Submit"
                  />
                </Form>
              )}
            </Formik>
          </>
        }
        noFooter={true}
      />
    </Scrollbars>
  );
};

const mapStateToProps = state => ({
  auth: state.role.auth,
  username: state.role.details.user?state.role.details.user.username:'',
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
  merchant: state.extra.merchant,
  balance: state.role.balance?state.role.balance:0,
  points: state.role.points?state.role.points:0,
});

const mapDispatchToProps = {
  getMerchant: getMerchant,
  logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout));