import { useState, useEffect } from 'react';
import { MerchantContainer } from "./style";
import InfoContainer from "components/customer/MerchantInfo";
import LoadingView from "components/Elements/LoadingView";
import CampaignsContainer from "components/customer/Campaigns";
// import { DataContext } from "contexts/DataContextContainer";
import { getBalance, getPointsBalance } from 'redux/actions/roleActions';
import {connect} from 'react-redux';
import isEmpty from "lodash/isEmpty"
import cloneDeep from "lodash/cloneDeep"
import { get_campaignDefs } from "functions.js"
import getSymbolFromCurrency from 'currency-symbol-map'

import axios from "axios";
import client from "feathers.js"

const Merchant = (props) => {
  const username = props.match.params.username
  let domain_verify = username && username !== undefined? username: props.merchant.username

  // const { setLoading, showToast } = useContext(DataContext);
  const[ campaignDefs, setCampaignDefs ] = useState([])
  
  const setInfo = (campaigns, merchant, balance, points) => {
    if(!isEmpty(merchant)){

      let cloneCampaignDefs = cloneDeep(campaigns)

      // cloneCampaignDefs[0].notification = merchant.totalStamps
      // cloneCampaignDefs[3].notification = merchant.totalReferrals

      cloneCampaignDefs[5].currency = getSymbolFromCurrency(merchant.currency)

      // cloneCampaignDefs[5].cash = balance

      cloneCampaignDefs[0].notification = merchant.totalStamps
      cloneCampaignDefs[1].notification = merchant.totalVouchers
      cloneCampaignDefs[2].notification = merchant.totalPackages
      cloneCampaignDefs[3].notification = merchant.totalReferrals

       // cloneCampaignDefs[4].notification = merchant.totalPoints

      // if(merchant.totalStamps){
      cloneCampaignDefs[0].available = true
      // }

      cloneCampaignDefs[1].available = true
      
      // if(merchant.totalPackages){
      cloneCampaignDefs[2].available = true
      // }
      
      // if(merchant.totalReferrals){
      cloneCampaignDefs[3].available = true
      // }
      
      // copper, bronze, silver, gold
      if(merchant.subscription === 'copper'){
        
      }else if(merchant.subscription === 'bronze'){
        
        // edit
        if(merchant.activePoint === 1){
          cloneCampaignDefs[4].available = true
        }

        if(merchant.business_verified){
          cloneCampaignDefs[5].available = true
        }

        // cloneCampaignDefs[1].notification = merchant.totalPackages
        // cloneCampaignDefs[2].notification = merchant.totalVouchers
        // cloneCampaignDefs[4].notification = merchant.totalPoints
        // cloneCampaignDefs[4].points = points

        // if(merchant.totalPackages){
        //   cloneCampaignDefs[2].available = true
        // }
        // if(merchant.totalVouchers){
          // cloneCampaignDefs[1].available = true
        // }
        
      }else if(merchant.subscription === 'silver'){
        // edit
        if(merchant.activePoint === 1){
          cloneCampaignDefs[4].available = true
        }

        if(merchant.business_verified){
          cloneCampaignDefs[5].available = true
        }

        // cloneCampaignDefs[1].notification = merchant.totalPackages
        // cloneCampaignDefs[2].notification = merchant.totalVouchers
        // cloneCampaignDefs[4].notification = merchant.totalPoints
        // cloneCampaignDefs[4].points = points

        // if(merchant.totalPackages){
        //   cloneCampaignDefs[2].available = true
        // }
        // if(merchant.totalVouchers){
          // cloneCampaignDefs[1].available = true
        // }

      }else if(merchant.subscription === 'gold'){
        
        // edit
        if(merchant.activePoint === 1){
          cloneCampaignDefs[4].available = true
        }

        if(merchant.business_verified){
          cloneCampaignDefs[5].available = true
        }

        // cloneCampaignDefs[1].notification = merchant.totalPackages
        // cloneCampaignDefs[2].notification = merchant.totalVouchers
        // cloneCampaignDefs[4].notification = merchant.totalPoints
        // cloneCampaignDefs[4].points = points
        
        // if(merchant.totalPackages){
        //   cloneCampaignDefs[2].available = true
        // }
        // if(merchant.totalVouchers){
          // cloneCampaignDefs[1].available = true
        // }
      }
      // setCampaignDefs(cloneCampaignDefs)
      return cloneCampaignDefs
    }
    /* eslint-disable-next-line */
  }
  
  useEffect(() => {
    client.authenticate()
    .then((auth) => {
      return axios.post(`${client.io.io.uri}getPointsBalance`, {
        userId: props.userInfo._id,
        merchantId: props.merchant._id,
      }, {
        headers: {
          'Authorization': auth.accessToken
        }
      })
    })
    .then((res) => {
      props.getPointsBalance(res.data.balance)

      let campaigns = cloneDeep(get_campaignDefs(domain_verify))
      setCampaignDefs(setInfo(campaigns, props.merchant, 0, res.data.balance))
    })
    .catch((err)=>{
      console.log(err)

      let campaigns = cloneDeep(get_campaignDefs(domain_verify))
      setCampaignDefs(setInfo(campaigns, props.merchant, 0, 0))
      // if(err.name === "NotAuthenticated"){  
      //   showToast('error', 'Login to continue.')
      // }
      // showToast('error', err.message)
      // setLoading(false)
    })
    /* eslint-disable-next-line */
  }, [props.merchant])


  return (
    <MerchantContainer onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}>
      {
        !isEmpty(props.merchant) ?
        <div className="merchant-container" id="merchant-container">
          <InfoContainer 
            merchant={props.merchant} 
            userInfo={props.userInfo}
            toggleLoginModal={props.toggleLoginModal}
          />
          <CampaignsContainer 
            campaignDefs={campaignDefs} 
            signout={props.signout}
            auth={props.auth}
            merchant={props.merchant} 
            userInfo={props.userInfo}
            toggleLoginModal={props.toggleLoginModal}
            isLogin={props.isLogin}
            setIsLogin={props.setIsLogin}
          />
        </div>
        :
        <LoadingView isLoading/>
      }
    </MerchantContainer>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  getBalance: getBalance,
  getPointsBalance: getPointsBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(Merchant);
