import { BrowserRouter, Route, Switch } from "react-router-dom";
import RouteWrapper from "./RouteWrapper";
import PageNotFound from "containers/Home";

import Merchant from "containers/customer/Merchant";
import Stamp from "containers/customer/Merchant/Campaigns/Stamp";
import Package from "containers/customer/Merchant/Campaigns/Package";
import Voucher from "containers/customer/Merchant/Campaigns/Voucher";
import Referral from "containers/customer/Merchant/Campaigns/Referral";
import Points from "containers/customer/Merchant/Campaigns/Points";
import Wallet from "containers/customer/Merchant/Campaigns/Wallet";
import Topup from "containers/customer/Merchant/Topup";
import CustomerProfile from "containers/customer/Profile";
import Ratings from "containers/customer/Merchant/Ratings";
import ResetPassword from "containers/customer/Merchant/ResetPassword";

// import CustomerLogin from "containers/customer/Login";
import Transactions from "containers/customer/Transactions";
import Notifications from "containers/customer/Notifications";
import Contact from "containers/customer/Contact";
import Outlet from "containers/customer/Outlet";

function Routes() {
  return (
      <BrowserRouter forceRefresh={true}>
        <Switch>
          {/* customer routes */}
          <RouteWrapper exact path="/:username" component={Merchant} />
          <RouteWrapper exact path="/:username/ref/:refLinkId" component={Merchant} />
          <RouteWrapper exact path="/:username/stamp" component={Stamp} />
          <RouteWrapper exact path="/:username/package" component={Package} />
          <RouteWrapper exact path="/:username/voucher" component={Voucher} />
          <RouteWrapper exact path="/:username/referral" component={Referral} />
          <RouteWrapper exact path="/:username/points" component={Points} />
          <RouteWrapper exact path="/:username/wallet" component={Wallet} />
          <RouteWrapper exact path="/:username/topup" component={Topup} />
          <RouteWrapper exact path="/:username/profile" component={CustomerProfile} />

          <RouteWrapper exact path="/:username/ratings" component={Ratings} />
          <RouteWrapper exact path="/:username/reset-password/:id" component={ResetPassword} />

          {/* <RouteWrapper exact path="/:username/login" component={CustomerLogin} /> */}
          <RouteWrapper exact path="/:username/transactions" component={Transactions} />
          <RouteWrapper exact path="/:username/notifications" component={Notifications} />
          <RouteWrapper exact path="/:username/contact" component={Contact} />
          <RouteWrapper exact path="/:username/outlet" component={Outlet} />

          <Route exact path="/" render={PageNotFound} />
          <Route exact path="*" render={PageNotFound} />
          {/* <RouteWrapper exact path="*" component={PageNotFound} /> */}

        </Switch>
      </BrowserRouter>
  );
}

export default Routes;
