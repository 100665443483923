import React, { useEffect, useState } from 'react';
import { ContactContainer } from "./style";
import CountryList from 'country-list-with-dial-code-and-flag'
import { useHistory } from "react-router-dom";
import TopNav from "components/TopNav/index"

import client from "feathers.js"

import ReactGA from 'react-ga4'

import 'services/i18'
import { useTranslation as translation } from 'react-i18next';

const Contact = props => {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const {t} = translation()

  const history = useHistory();
  const [ country, setCountry ] = useState('')

  useEffect(() => {
    let findCountry = CountryList.find(e => e.code === props.merchant.country_visible)
    if(findCountry){
      setCountry(findCountry.name)
    }
  }, [props.merchant.country_visible])

  const back = () => {
    history.goBack()
  }

  const contactFacebook = () => {
    ReactGA.event({
      category: "click",
      action: "click_contact_facebook",
    });
  }

  const contactInstagram = () => {
    ReactGA.event({
      category: "click",
      action: "click_contact_instagram",
    });
  }

  const contactTwitter = () => {
    ReactGA.event({
      category: "click",
      action: "click_contact_twitter",
    });
  }

  return (
    <ContactContainer color1={color1} color2={color2} 
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      <div className='contact-container' id="contact-container">
        <TopNav 
          name={t('ContactUs Title')}
          back={back}
          merchant={props.merchant}
          userInfo={props.userInfo}
          signout={props.signout}
          toggleLoginModal={props.toggleLoginModal}
        />

       <div className='contact-page-body'>
         <div className='logo'>
           <div className='profile-icon' style={{
              backgroundImage: `url("${client.io.io.uri}${(props.merchant.logo)?props.merchant.logo:null}")`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              position: 'relative'
            }}></div>
           {/* <p>{props.merchant.business_name}</p> */}
         </div>
         <div className='card-wrapper'>
          <h1 className='mb-2'>{props.merchant.business_name}</h1>
          <div className='address mb-2'>
            {/* <h1>Business Details</h1> */}
            <p>{props.merchant.address1_visible}</p>
            <p>{props.merchant.address2_visible} {props.merchant.city_visible}</p>
            <p>{props.merchant.zip_visible}</p>
            <p>{country}</p>
          </div>
          {
            props.merchant.about?
            <div className='about'>
              <h4>{t('ContactUs AboutUs')}</h4>
              <span>{props.merchant.about}</span>
            </div>
            :null
          }
        </div>
        
         <div className='card-wrapper'>
          <div className='contact'>
            {
              props.merchant.contact_email?
                <h1><i className="fa fa-envelope"></i>{props.merchant.contact_email}</h1>
              :null
            }
            {
              (props.merchant.phonePrefix_1 && props.merchant.contact_phone_1) &&
              <p><i className="fa fa-phone"></i>{props.merchant.phonePrefix_1} {props.merchant.contact_phone_1}</p>
            }
          </div>
        </div>

         <div className='social'>
           {
             props.merchant.facebook.url? (
                <div className='card-wrapper mb-2'>
                  <p><i className="fab fa-facebook"></i>
                    <a href={props.merchant.facebook.url} target="_blank" rel="noreferrer"
                      onClick={() => contactFacebook()}
                    >
                      {
                        props.merchant.facebook.id?
                        props.merchant.facebook.id:
                        (props.merchant.facebook.url?props.merchant.facebook.url.split('/').pop(): '-')
                      }
                    </a>
                  </p>
                </div>
             ): null
           }
           {
             props.merchant.instagram.url? (
                <div className='card-wrapper mb-2'>
                  <p><i className="fab fa-instagram"></i>
                    <a href={props.merchant.instagram.url} target="_blank" rel="noreferrer"
                      onClick={() => contactInstagram()}
                    >
                      {
                        props.merchant.instagram.id?
                        props.merchant.instagram.id:
                        (props.merchant.instagram.url?props.merchant.instagram.url.split('/').pop(): '-')
                      }
                    </a>
                  </p>
                </div>
             ): null
           }
           {
             props.merchant.twitter.url? (
                <div className='card-wrapper'>
                  <p><i className="fab fa-twitter"></i>
                    <a href={props.merchant.twitter.url} target="_blank" rel="noreferrer"
                      onClick={() => contactTwitter()}
                    >
                      {
                        props.merchant.twitter.id?
                        props.merchant.twitter.id:
                        (props.merchant.twitter.url?props.merchant.twitter.url.split('/').pop(): '-')
                      }
                    </a>
                  </p>
                </div>
             ): null
           }
         </div>
       </div>
      </div>
    </ContactContainer>
  );
}

export default Contact;
