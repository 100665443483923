import { useRef, useContext, useState, useEffect } from 'react';
import { MerchantContainer } from "./style";
import { Modal } from "antd";
import Form from 'react-bootstrap/Form';
import {
  Card
} from 'react-bootstrap';
import InfoContainer from "components/customer/MerchantInfo";
import LoadingView from "components/Elements/LoadingView";
import ModalConfirmation from 'components/Extra/ModalConfirmation';
import { DataContext } from "contexts/DataContextContainer";
import { getBalance, getPointsBalance } from 'redux/actions/roleActions';
import { getAverageRates, getTotalReviews } from 'redux/actions/extraActions';
import { FaTrashAlt, FaTimesCircle, FaRegEye } from "react-icons/fa";
import { PlusOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import isEmpty from "lodash/isEmpty"
import cloneDeep from "lodash/cloneDeep"
import { Rate } from 'antd';
import Resizer from "react-image-file-resizer";
import { UserLogo } from "assets/images"

import ModalPreview from "components/Extra/ModalPreview"

import client from "feathers.js"
import axios from 'axios';
import moment from 'moment';

import ReactGA from 'react-ga4';

const isUrl = string => {
  try { return Boolean(new URL(string)); }
  catch(e){ return false; }
}

const Merchant = (props) => {
  const bgFileEl = useRef()
  const { setLoading, showToast } = useContext(DataContext)
  const [ showModal, setShowModal ] = useState(false)
  const [ rates, setRates] = useState(0)
  const [ data, setData ] = useState([])
  const [ comment, setComment ] = useState('')
  const [ action, setAction ] = useState('new')
  const [ activeId, setActiveId ] = useState('')
  const [ fileArray, setFileArray ] = useState([])
  const [ modalVisible, setModalVisible ] = useState(false)
  const [ imgSrc, setImgSrc ] = useState('')
  const [ deleteId, setDeleteId ] = useState('')
  const [ modalDeleteVisible, setModalDeleteVisible ] = useState(false)

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const toggleDeleteModal = () => {
    setModalDeleteVisible(!modalDeleteVisible)
  }

  const modalCallback = () => {
    setLoading(true)
    client.authenticate()
    .then(() => {
      return client.service('ratings').remove(deleteId)
    })
    .then((res) => {
      let cloneData = cloneDeep(data)
      cloneData = cloneData.filter(e => e._id !== deleteId)
      setData(cloneData)

      if(props.totalReviews === 1){
        props.getAverageRates(0)
        props.getTotalReviews(0)
      }

      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      if(err.name === "NotAuthenticated"){
        
      }else{
        showToast('error', err.message)
      }
    })
  }

  useEffect(() => {
    client.service('ratings').find({
      query: {
        merchantId: props.merchant._id,
      }
    })
    .then((res) => {
      setData(res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [props.merchant._id, props.userInfo._id])

  const writeReview = async() => {
    ReactGA.event({
      category: "click",
      action: "click_ratings_writeReview",
    });

    let getRecord = await client.service('ratings').find({
      query: {
        merchantId: props.merchant._id,
        userId: props.userInfo._id
      }
    })
    if(getRecord.data.length > 0){
      showToast('error', "You're only allow to write a review!")
    }else{
      client.authenticate()
      .then(() => {
        setShowModal(true)
      })
      .catch((err) => {
        if(err.name === "NotAuthenticated"){  
          props.toggleLoginModal()
        }else{
          showToast('error', err.message)
        }
      })
    }
  }

  const submitReview = async() => {
    ReactGA.event({
      category: "click",
      action: "click_ratings_submitReview",
    });


    if(rates <= 0){
      showToast('error', 'Please rate a star!')
      return false
    }

    setLoading(true)
    
    var photos = []

    if(fileArray.length > 0){
      let cloneFileArr = cloneDeep(fileArray)
      let processImg = await cloneFileArr.map(async(v) => {
        if(v.file){
          if(v.file.name){
            let originFileName = await imageProcess(v.file)
            v.originFileName = v.file.originFileName
            v.fileName = originFileName
            delete v.file
            delete v.preview
            delete v.name
            return v
          }else{
            return v
          }
        }else{
          return v
        }
      })
      
      const result = await Promise.all(processImg)
      photos = result
    }

    if(action === 'new'){
      client.authenticate()
      .then(() => {
        return client.service('ratings').create({
          rates,
          comment,
          merchantId: props.merchant._id,
          photos,
          userId: props.userInfo._id,
        })
      })
      .then((res) => {
        let cloneData = cloneDeep(data)
        res.userInfo = props.userInfo
        cloneData.unshift(res)

        if(props.averageRates === 0){
          props.getAverageRates(rates)
        }
        props.getTotalReviews(props.totalReviews+1)

        setRates(0)
        setComment('')
        setFileArray([])
        removeFile()
        // let findKey = cloneData.findIndex(e => e._id === res._id)
        // if(findKey !== -1){
        //   cloneData[findKey] = res
        // }
        setData(cloneData)

        setShowModal(false)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
        if(err.name === "NotAuthenticated"){
        
        }else{
          showToast('error', err.message)
        }
      })
    }else{

      client.authenticate()
      .then(() => {
        return client.service('ratings').patch(activeId, {
          rates,
          photos,
          comment,
        })
      })
      .then((res) => {
        let cloneData = cloneDeep(data)
        let findKey = cloneData.findIndex(e => e._id === res._id)
        if(findKey !== -1){
          cloneData[findKey] = res
        }

        setRates(0)
        setComment('')
        setFileArray([])
        removeFile()
        
        setData(cloneData)

        setShowModal(false)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
        if(err.name === "NotAuthenticated"){
        
        }else{
          showToast('error', err.message)
        }
      })

    }
  }

  const handleUpload = (file) => {
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append('NAME', 'Fred');
      formData.append('file', file)
      
      axios({
        method: 'post',
        url: `${client.io.io.uri}uploadFileLocalPublic`,
        data: formData,
        config: { headers: {'Content-Type': 'multipart/form-data' }}
      })
      .then((res) => {
        return resolve(res.data)
      })
      .catch((err) => {
        console.log(err)
      })   
    })
  }

  const imageProcess = (file) => {
    let result = handleUpload(file).then(v => v)
    return result
  }

  const handleDelete = (currentFileName) => {
    axios({
      method: 'post',
      url: `${client.io.io.uri}deleteFileLocalPublic`,
      data: {fileName: currentFileName,},
      config: { headers: {'Content-Type': 'application/json' }}
    })
    .then((res) => {
      return (res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const handleEdit = async(_id, rateState, commentState, photos) => {
    let getRecord = await client.service('ratings').get(_id)
    
    if(moment(new Date(getRecord.updatedAt)).add(23, 'hours').format() <= moment(new Date()).format()){
      setRates(rateState)
      setComment(commentState)
      setFileArray(photos)
      setActiveId(_id)
      setAction('edit')
      setShowModal(true)
    }else{
      showToast('error', "Edit review is allow once a day!")
    }
  }

  const resizeFile = (file, getSize) => {
    let compressPercent = 50
    // 100kb
    if(file.size > 300000){
      compressPercent = 80
    }
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        getSize.width,
        getSize.height,
        file.name.split('.').pop(),
        compressPercent,
        0,
        (uri) => {
          return resolve(uri);
        },
        "file"
      );
    });
  }

  // const dataURIToBlob = (dataURI) => {
  //   const splitDataURI = dataURI.split(",");
  //   const byteString =
  //     splitDataURI[0].indexOf("base64") >= 0
  //       ? atob(splitDataURI[1])
  //       : decodeURI(splitDataURI[1]);
  //   const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  //   const ia = new Uint8Array(byteString.length);
  //   for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  //   return new Blob([ia], { type: mimeString });
  // };

  const handleImgChange = async(e) => {

    var singleFile = e.target.files[0]
    // let fileType = (singleFile.type === "image/jpeg" || singleFile.type === "image/jpg" || singleFile.type === "image/png" || singleFile.type === "image/gif")

    // if(singleFile.size <= 2000000 && fileType){
    var newFileName = (Math.random().toString(36).substring(2, 15) + "-" + 
      singleFile.lastModified + "-" + 
      singleFile.name)
    let formData = new FormData()
    formData.append('file', singleFile, newFileName)
    let modified = formData.get('file')
    modified.originFileName = singleFile.name

    function getImageSize(file){
      return new Promise(resolve => {
        var fr = new FileReader();

        fr.onload = function() { 
            var img = new Image();
    
            img.onload = function() {
              return resolve({
                width: img.width,
                height: img.height
              }); 
            };
    
            img.src = fr.result; 
        };
    
        fr.readAsDataURL(file);
      })
    }

    let getSize = await getImageSize(singleFile)

    if(singleFile.size > 300000){
      const image = await resizeFile(modified, getSize);
        
      let objectImg = URL.createObjectURL(image)
      let imgData = {
        name: image.name,
        file: image,
        preview: objectImg
      }
      setFileArray(fileArray.concat(imgData))
    }else{

      let objectImg = URL.createObjectURL(modified)
      let imgData = {
        name: modified.name,
        file: modified,
        preview: objectImg
      }
      setFileArray(fileArray.concat(imgData))

    }

    // }else{
    //   window.alert("File size Limit: 2000000 kb!")
    //   return false
    // }
  }

  const removeFileInedx = (index) => {
    URL.revokeObjectURL(fileArray[index].preview)
    if(fileArray[index].fileName){
      handleDelete(fileArray[index].fileName)
    }
    let result = cloneDeep(fileArray)
    result.splice(index, 1)
    setFileArray(result)
  }

  const removeFile = () => {
    fileArray.map((v) => {
      return URL.revokeObjectURL(v.preview)
    })
    setFileArray([])
  }

  const previewImg = (src) => {
    setImgSrc(src)
    toggleModal()
  }

  return (
    <MerchantContainer
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      {
        !isEmpty(props.merchant) ?
        <div className="merchant-container" id="stamp-container">
          <InfoContainer 
            merchant={props.merchant} 
            userInfo={props.userInfo}
            toggleLoginModal={props.toggleLoginModal}
          />
          <div className='review-ratings'>
            <div className='ratings-wrap'>
              <div>
                <button 
                  className='btn button-primary'
                  onClick={() => {
                    setAction('new')
                    writeReview()
                  }}
                >
                  <span>
                    Write a review
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className='reviews-wrap'>
            {
              data.length > 0 ? 
                data.map((x, i) => {
                  return (
                    <div key={i} className='reviews'>
                      <div>
                        <div 
                          className='img'
                          style={{backgroundImage: `url("${
                            !isEmpty(x.userInfo)? 
                            (x.userInfo.profilePicURL? (
                              isUrl(x.userInfo.profilePicURL)? x.userInfo.profilePicURL: (
                                client.io.io.uri + x.userInfo.profilePicURL
                              )
                            ): UserLogo)
                          : UserLogo 
                          }")`}}
                          />
                        </div>
                      <div className='w-100'>
                        <div className='name-wrap'>
                          <h3 style={{textTransform: 'capitalize'}}>
                          {!isEmpty(x.userInfo)?
                            x.userInfo.lastName || x.userInfo.firstName?
                            (x.userInfo.firstName || "") + " " + (x.userInfo.lastName || "")
                            :
                            x.userInfo.email.split("@")[0]
                          :""}
                          </h3>
                          <div>
                            <span>
                              {moment(new Date(x.updatedAt)).fromNow()}
                            </span>
                            {
                              x.userInfo._id === props.userInfo._id? (
                                <>
                                  <div
                                    style={{display: 'inline-block'}}
                                    onClick={() => handleEdit(x._id, x.rates, x.comment, x.photos)}
                                  >
                                    <i 
                                      className="fa fa-pen"
                                    ></i>
                                  </div>
                                  <div
                                    className='ml-1'
                                    style={{display: 'inline-block'}}
                                    onClick={() => {
                                      setDeleteId(x._id)
                                      toggleDeleteModal()
                                    }}
                                  >
                                  <i 
                                    className="fa fa-trash"
                                  ></i>
                                </div>
                                </>
                              ): null
                            }
                          </div>
                        </div>
                        <Rate disabled value={x.rates} />
                        <p className='mt-2'>
                            {x.comment}
                        </p>
                        <div>
                          {
                            x.photos.length > 0?
                            x.photos.map((x, i) => {
                              return (
                                <div key={i} className="d-inline-block photos">
                                  <div className='img-wrapper'>
                                    <img style={{
                                      width: 80,
                                      border: '0.811688px solid #3B7779',
                                      borderRadius: 10
                                    }} 
                                    src={client.io.io.uri + x.fileName}
                                    alt="" />
                                    <div className="zoom-photo">
                                      <FaRegEye color="white" onClick={() => previewImg(client.io.io.uri + x.fileName)} />
                                    </div>
                                  </div>
                                </div>
                              )
                            }): null
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              : null
            }
          </div>
        </div>
        :
        <LoadingView isLoading/>
      }

      <Modal
        title={false}
        visible={showModal}
        onCancel={() => {
          setShowModal(false)
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='auth-modal'>
          <span className='title1 text-capitalize'>Review</span>
          {/* <span className='title1 text-capitalize'>{props.merchant.username}</span> */}
          <div className='profile-img-wrap'>
            <div 
              className='profile-img'
              style={{backgroundImage: `url("${!isEmpty(props.userInfo)?props.userInfo.profilePicURL:null}")`}}
              />
              <div>
                <h3>
                  {!isEmpty(props.userInfo)?
                    props.userInfo.lastName || props.userInfo.firstName?
                    (props.userInfo.firstName || "") + " " + (props.userInfo.lastName || "")
                    :
                    props.userInfo.email.split("@")[0]
                  :""}
                </h3>
                <p>
                  Posting publicly
                </p>
              </div>
          </div>
          <div className='w-100 text-center mb-2'>
            <Rate 
              value={rates}
              onChange={(e) => setRates(e)}
            />
          <div>
            <div className="d-flex mt-3">
              {fileArray.length > 0? 
                fileArray.map((v, i) => {
                  return (
                    <div className='image-preview' style={{width: '30%', display: 'inline-block'}} key={i}>
                      <Card className="shadow-sm">
                        <Card.Body className="p-0">
                          <div className="file-component">
                            <div className="file-overlay">
                              <div className="file-buttons">
                                <FaTrashAlt color="white" onClick={() => removeFileInedx(i)} />
                              </div>
                            </div>
                            <img className="w-100" src={v.preview || (client.io.io.uri + v.fileName) } alt="" />
                          </div>
                        </Card.Body>
                      </Card>
                      {/* <span style={{ overflowWrap: 'break-word' }}>{v.name}</span> */}
                    </div>
                  )
                }): null}
                {fileArray.length < 6? (
                <>
                  <div className="file-upload">
                    <input 
                      type="file" 
                      ref={bgFileEl} 
                      onChange={(e) => handleImgChange(e)} 
                      hidden />                
                      {/* <div className="progessBar" style={{ width: progress }}>
                    </div> */}
                    <div
                      className='upload-image'
                      onClick={() => bgFileEl.current.click()}
                    >
                      <div className="inner">
                        <PlusOutlined />
                        <div className="btn-inner--text">Upload</div>
                      </div>
                    </div>
                  </div>
                </>
              ): null}
            </div>
              </div>
              <div>
              </div>
              <Form.Group className="mt-3 mb-2" style={{fontSize: 12}}>
                <Form.Control 
                  as="textarea" 
                  rows={3} 
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Share details of your own experience at this place"
                />
              </Form.Group>
          </div>
        <div className='footer'>
          <button 
              className='btn button-secondary'
              onClick={() => setShowModal(false)}
            >
              <span>
                Cancel
              </span>
            </button>
            <button 
                className='btn button-primary'
                onClick={() => submitReview()}
              >
                <span>
                  Post
                </span>
              </button>
          </div>
        </div>
      </Modal>

      <ModalPreview 
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        body={
          <>
            <img src={imgSrc} className="w-100" alt="" />
          </>
        }
      />
      
      <ModalConfirmation 
        modalVisible={modalDeleteVisible}
        toggleModal={toggleDeleteModal}
        modalCallback={modalCallback}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              {
                "Delete Review"
              }
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>
              {
                "Proceed to delete this review?"
              }
            </span>
          </>
        }
      />
    </MerchantContainer>
  );
}

const mapStateToProps = state => ({
  averageRates: state.extra.averageRates?state.extra.averageRates:0,
  totalReviews: state.extra.totalReviews?state.extra.totalReviews:0,
});

const mapDispatchToProps = {
  getBalance: getBalance,
  getPointsBalance: getPointsBalance,
  getAverageRates: getAverageRates, 
  getTotalReviews: getTotalReviews
};

export default connect(mapStateToProps, mapDispatchToProps)(Merchant);
