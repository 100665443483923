import {
  GET_MERCHANT,
  GET_LOGO_BANNER,
  TOPUP_AND_PAY,
  GET_AVERAGE_RATES,
  GET_TOTAL_REVIEWS
} from '../actions/extraActions';

const INITIAL_STATE = {
  merchant: {},
  logo: '',
  banner: '',
  topupAndPayData: {},
  averageRates: 0,
  totalReviews: 0,
}

export default function roleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MERCHANT:
      return {
        ...state, 
        merchant: action.data,
      }
    case GET_LOGO_BANNER:
      return {
        ...state, 
        logo: action.data.logo,
        banner: action.data.banner,
      }
    case TOPUP_AND_PAY:
      return {
        ...state, 
        topupAndPayData: action.data,
      }
    case GET_AVERAGE_RATES:
      return {
        ...state, 
        averageRates: action.data,
      }
    case GET_TOTAL_REVIEWS:
      return {
        ...state, 
        totalReviews: action.data,
      }
    default:
      return state
  }
}