import React from 'react';
import { withRouter } from "react-router";
import { Modal } from "antd";
import {
  FaTimesCircle
} from "react-icons/fa";
import { UilBill } from "assets/images";

// import client from "feathers.js"

const Wallet = props => {

  return (
      <Modal
        title={false}
        visible={props.showModal}
        onCancel={() => {
          props.toggleModal()
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        getContainer={() => document.getElementById("stamp-container")}
        width='90%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='wallet-modal'>
          <div className='wallet-modal-header'>
            <img src={UilBill} alt="" />
            <span className='title1'>Total Amount</span>
          </div>
          <div className='wallet-modal-body mt-2'>
            <span className='price'>{(props.currency)}{props.amount?(props.amount).toFixed(2):0}</span>
            <p className='mt-4 mb-0'>Current Wallet Balance <span className='current'>{(props.currency)}{props.balance?(props.balance).toFixed(2):0}</span></p>
            {
              props.modePaymentPackage.wallet?
                props.amount > props.balance? (
                  <>
                    <p className='insufficient-balance mb-3'>Insufficient balance</p>
                    <button className='top-up' onClick={props.redirectTopup}>Top up wallet & pay</button>
                  </>
                ): (
                  <>
                    <button className='top-up' onClick={props.payWithWallet}>Wallet Payment</button>
                  </>
                )
              :
              null
            }

            <p className='option'>Or complete purchase with</p>

            {
              props.modePaymentPackage.cash?
                <button className='outline mb-3' onClick={props.payAtCounter}>Cash Payment (Over counter)</button>
              :
              null
            }

            {
              props.modePaymentPackage.credit && 
              (props.stripePublishableKey && props.stripeSecretKey)? (
                <button className='outline mb-2' onClick={props.payWithCreditCard}>Credit Card Payment</button>
              ):null
            }

          </div>
        </div>
      </Modal>
  );
}

export default withRouter(Wallet);
