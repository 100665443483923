import { TopNavContainer } from "./style"
import 'services/i18'
import { useTranslation as translation } from "react-i18next";

const TopNav = (props) => {
    const {t} = translation()
    return (  
        <TopNavContainer>
             <div className='title-nav'>
                <span className='btn-back' onClick={props.back}>
                    <i className="fas fa-angle-left"></i> 
                    {" "}
                    <span>{t('Header BackButton')}</span>
                </span>
                <div className='title'>
                    {/* <img src={campaignDef.icon} alt="" /> */}
                    <span>{props.name}</span>
                </div>
                {
                    props.userInfo._id?
                    <button onClick={props.signout} className='btn btn-sm btn-mrz-warning'>
                        {t('Header LogoutButton')}
                        <span className='ml-1'><i className="fas fa-sign-out-alt"/></span>
                    </button>
                    :
                    <button onClick={props.toggleLoginModal} className='btn btn-sm btn-mrz-warning'>
                        {t('Header LoginButton')}
                        <span className='ml-1'><i className="fas fa-sign-in-alt"/></span>
                    </button>
                }
                </div>
                <div className="sub-title">
                    {props.userInfo.email? props.userInfo.email + " | ": null}
                    {props.merchant.business_name}
                </div>
        </TopNavContainer>
    );
}
 
export default TopNav;