import { useRef, useEffect, useState, useContext } from 'react';
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion'
import parse from 'html-react-parser';
import QRCode from "react-qr-code";
import randomstring from  'randomstring';
import AccordionItem from 'components/Elements/StyledAccordionItem';
import { StampContainer } from "../Stamp/style";
import ModalConfirmation from 'components/Extra/ModalConfirmation';
import { DataContext } from "contexts/DataContextContainer";
import moment from 'moment'
import {
  FaTimesCircle
} from "react-icons/fa";
import { cloneDeep } from 'lodash';
import { get_campaignDefs } from "functions.js"
import {connect} from 'react-redux';
import { getBalance } from 'redux/actions/roleActions';
import { Voucher, AuthSuccess, AuthFail } from "assets/images"
import TopNav from "components/TopNav/index"

import client from "feathers.js"
import classNames from 'classnames';
import ReactGA from 'react-ga4'

import 'services/i18'
import { useTranslation as translation, Trans } from 'react-i18next';

const Vouchers = props => {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const {t} = translation()

  const history = useHistory();
  const timer = useRef(null);
  const firstLoad = useRef(true);
  const { setLoading, showToast } = useContext(DataContext)
  const [ activeTab, setActiveTab ] = useState(1)
  const [ activeKey, setActiveKey ] = useState(-1)
  const [ deleteId, setDeleteId ] = useState('')
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [showTransactionResultModal, setShowTransactionResultModal] = useState(false)
  const [pin, setPin] = useState('')
  const [authTimer, setAuthTimer] = useState(0)
  const [transactionResult, setTransactionResult] = useState(null)
  const [ vouchers, setVouchers ] = useState([])
  const [ voucherPurchases, setVoucherPurchases ] = useState([])
  const [ unavailableVouchers, setUnavailableVouchers ] = useState([])
  const [ campaignDef ] = useState(get_campaignDefs('')[1])
  const [ currentMerchantName, setCurrentMerchantName ] = useState('')

  var getView = new URLSearchParams(props.location.search).get('view')

  client.authenticate()
  .then((auth) => {
    setCurrentMerchantName(auth.user.merchantName)
  })

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      
      if(getView){
        setActiveTab(1)
        fetchCampaignPouch(props.merchant._id, props.userInfo._id)
      }else{
        fetchCampaignPouch(props.merchant._id, props.userInfo._id)
      }
      
      return;
    }else{
      if(activeTab === 0){
        fetchVouchers()
      }else{
        fetchCampaignPouch(props.merchant._id, props.userInfo._id)
      }
    }
    /* eslint-disable-next-line */
  }, [props.merchant._id, props.userInfo._id, activeTab])

  const fetchCampaign = (key) => {
    if (key === 0) {
      ReactGA.event({
        category: "click",
        action: `click_voucher_availableTab`,
      });
    }

    if (key === 1) {
      ReactGA.event({
        category: "click",
        action: `click_voucher_activeTab`,
      });
    }

    if (key === 2) {
      ReactGA.event({
        category: "click",
        action: `click_voucher_unavailableTab`,
      });
    }
    setActiveTab(key)
    // setLoading(true)
  }

  const availableStampsTracking = (value) => {
    ReactGA.event({
      category: "click",
      action: `click_voucher_availableStamps`,
      label: value,
    });
  }

  const activeStampsTracking = (value) => {
    ReactGA.event({
      category: "click",
      action: `click_voucher_activeStamps`,
      label: value,
    });
  }

  const unavailableStampsTracking = (value) => {
    ReactGA.event({
      category: "click",
      action: `click_voucher_unavailableStamps`,
      label: value,
    });
  }

  const tncForAtr = (value) => {
    switch (value) {
      case 1: return 'You need to fill in your Birthday and Gender in your profile.';
      case 2: return 'You need to fill in your Phone Number in your profile.';
      case 3: return 'You need to leave a review.';
      case 4: return 'You will need to perform a top up to your wallet';
      default: return '';
    }
  }

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const modalCallback = () => {
    deleteCampaign(deleteId)
  }

  const fetchVouchers = () => {
    setLoading(true)
    client.service('vouchers').find({
      query: {
        userId: props.merchant._id,
        active: true,
        period_start: {
          $lt: new Date()
        },
        period_end: {
          $gt: new Date()
        },
      }
    })
    .then((res) => {
      fetchVoucherPurchases(res.data, props.merchant._id, props.userInfo._id)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      if(err.name === "NotAuthenticated"){
        
      }else{
        showToast('error', err.message)
      }
    })
  }

  const fetchVoucherPurchases = (data, merchantId, userId) => {
    client.authenticate()
    .then(() => {
      return client.service('voucher-pouches').find({
        query: {
          merchantId: merchantId,
          userId: userId,
          // redeemed: false, // open for re collect
          voucherId: {
            $in: cloneDeep(data).map(x => x._id)
          }
        }
      })
    })
    .then((res) => {

      let cloneVouchers = cloneDeep(data)

      if(res.data.length > 0){
        res.data.map(x => {
          if(x.voucherId){
            let findVoucherIndex = cloneVouchers.findIndex(e => e._id === x.voucherId)
            if(findVoucherIndex !== -1){
              cloneVouchers[findVoucherIndex].collected = true
              // cloneVouchers.splice(findVoucherIndex, 1)
            }
          }
          return x
        })
      }
      
      setVouchers(cloneVouchers)
      setLoading(false)

    })
    .catch(err => {
      console.log(err)
      setVouchers(data)
      setLoading(false)
    })
  }

  const fetchCampaignPouch = (merchantId, userId) => {
    client.authenticate()
    .then(() => {
      return client.service('voucher-pouches').find({
        query: {
          getVoucherInfo: true,
          hide: {
            $ne: true
          },
          merchantId: merchantId,
          userId: userId,
          $sort: {
            createdAt: -1
          }
        }
      })
    })
    .then((res) => {

      let voucherPouches = [] 
     
      function filterNotAvailable(voucherPouches){
        let notAvailable = []
        voucherPouches.map(x => {
          if(!x.canRedeem){
            notAvailable.push(x)
            voucherPouches = voucherPouches.filter(e => e._id !== x._id)
            return x
          }else{ 
            let expired = new Date() > new Date(x.cardExpiry)
            let status = x.redeemed? "Redeemed": ("Expired")
            
            if( (expired) || x.redeemed ){
              x.status = status
              notAvailable.push(x)
              voucherPouches = voucherPouches.filter(e => e._id !== x._id)
              return x
            }else{ return x } 
          }
        })

         // remove hide
         if(notAvailable.length > 0){
          notAvailable = notAvailable.filter(e => !e.hide)
        }
        
        setVoucherPurchases(voucherPouches)
        setUnavailableVouchers(notAvailable)
      }

      if(res.data.length > 0){
        res.data.map(x => {
          if(x.voucherId){
            // purchased's _id to origin's _id
            // all activities under purchased package
            let cloneToPush = x.voucherInfo

            // if not yet redeem
            if(!x.redeemed){
              let checkRedeem = (new Date() >  new Date(moment(cloneToPush.valid_end)))? false: true
              cloneToPush.canRedeem = checkRedeem
              cloneToPush.status = checkRedeem?"":"Redeem Expired"
            }else{
              cloneToPush.canRedeem = false
              cloneToPush.status = "Redeemed"
            }
            
            cloneToPush._id = x._id
            cloneToPush.cardExpiry = moment(cloneToPush.valid_end)
            cloneToPush.redeemed = x.redeemed
            cloneToPush.hide = x.hide
            cloneToPush.createdAt = x.createdAt
            cloneToPush.updatedAt = x.updatedAt
            voucherPouches.push(cloneToPush)
          }else if(x.referralSahreId){
            let cloneToPush = cloneDeep(x)
  
            // if not yet redeem
            if(!x.redeemed){
              let checkRedeem = (new Date() >  new Date((x.referralShareValue.cardExpiry)))? false: true
              cloneToPush.canRedeem = checkRedeem
              cloneToPush.status = checkRedeem?"":"Redeem Expired"
            }else{
              cloneToPush.canRedeem = false
              cloneToPush.status = "Redeemed"
            }

            cloneToPush.cardExpiry = moment(x.referralShareValue.cardExpiry)
            cloneToPush.hide = x.hide
            cloneToPush.title = x.referralShareValue.title
            cloneToPush.tos = x.referralShareValue.tos
            voucherPouches.push(cloneToPush)
          }else if(x.pointId){
            let cloneToPush = cloneDeep(x)
  
            // if not yet redeem
            if(!x.redeemed){
              let checkRedeem = (new Date() >  new Date((x.pointValue.cardExpiry)))? false: true
              cloneToPush.canRedeem = checkRedeem
              cloneToPush.status = checkRedeem?"":"Redeem Expired"
            }else{
              cloneToPush.canRedeem = false
              cloneToPush.status = "Redeemed"
            }

            cloneToPush.cardExpiry = moment(x.pointValue.cardExpiry)
            cloneToPush.title = x.pointValue.title
            cloneToPush.tos = x.pointValue.tos
            voucherPouches.push(cloneToPush)
          }
          return x
        })

        filterNotAvailable(voucherPouches)

      }else{
        filterNotAvailable(res.data)
        // there is purchase
      }
      setLoading(false)

    })
    .catch(err => {
      console.log(err)
      setLoading(false)
    })
  }

  const back = () => {
    history.goBack()
  }

  const voucherPouch = async (value) => {
    let query;

    if(!props.checkSubEnd(props.merchant)){
      return false
    }

    setLoading(true)

    if (value.isAtr === false) {
      query = {
        merchantId: props.merchant._id,
        voucherId: value._id,
        userId: props.userInfo._id,
      }
    } else {
      query = {
        merchantId: props.merchant._id,
        voucherId: value._id,
        userId: props.userInfo._id,
        atrType: value.atrType,
      }
    }

    client.service('voucher-pouches').create(query)
    .then((res) => {
      setLoading(false)
      fetchVouchers()
      setActiveKey(-1)
      setActiveTab(1)
    })
    .catch(err =>{
      if(err.name === "NotAuthenticated"){  
        props.toggleLoginModal()
        setActiveKey(-1)
      } else {
        showToast('error', err.message, false, value.atrType, currentMerchantName)
      }
      setLoading(false)
    })
  }

  const redeemVoucher = (value) => {

    if(!props.checkSubEnd(props.merchant)){
      return false
    }
    
    setLoading(true)
    const _pin = randomstring.generate({length: 4, charset: 'numeric'})

    let campaignType = "voucher"
    let campaignId = value._id
    if(value.referralSahreId){
      campaignType = "referral"
    }

    client.authenticate()
      .then(() => {
        return client.service('activities').create({
          title: value.title,
          customer_email: props.userInfo.email,
          merchantId: props.merchant._id,
          merchantName: props.merchant.username,
          campaignType,
          campaignId, 
          redeem: true,
          action: 'redeemed',
          pin: _pin, 
        })
      })
      .then((res) => {

        setShowAuthModal(true)
        setLoading(false)
        setPin(_pin)

        if (timer?.current) {
          clearInterval(timer.current);
        }

        setAuthTimer(60)
        timer.current = setInterval(() => {
          console.log(authTimer)

          setAuthTimer(preVal => {
            if (preVal > 1) {
              return preVal - 1
            } else {
              setShowAuthModal(false)
              setPin('')
              if (timer?.current) {
                clearInterval(timer.current);
                timer.current = null
              }
              return 0
            }
           });
        }, 1000)

        getRedeemAuthResponse(res._id, _pin, value._id)

      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
        
        }else{
          showToast('error', err.message)
        }
        setLoading(false)
      })
  }

  const getRedeemAuthResponse = async (activityId, _pin, voucherId) => {

    const result = await client.service('activities').get(activityId)

    if (result.status === 'unauthorized') {
      if (timer?.current) {
        setTimeout(() => {
          getRedeemAuthResponse(activityId, _pin, voucherId)
        }, 1500)
      }
    } else if (result.status === 'authorized') {

      // mobile patch stamp
      // redeemed
      // re fetch stamp status
      // fetchVouchers()
      fetchCampaignPouch(props.merchant._id, props.userInfo._id)
      setActiveKey(-1)

      setShowAuthModal(false)
      setPin('')
      if (timer?.current) {
        clearInterval(timer.current);
        timer.current = null
      }

      setTransactionResult('success')
      setShowTransactionResultModal(true)

    } else if (result.status === 'declined') {
      setShowAuthModal(false)
      setPin('')
      if (timer?.current) {
        clearInterval(timer.current);
        timer.current = null
      }

      setTransactionResult('fail')
      setShowTransactionResultModal(true)
    }
  }

  const _activeStamp = (value, index) => {
    if(activeKey === index){
      setActiveKey(-1)
    }else{
      setActiveKey(index)
    }
  }


  const deleteCampaign = (id) => {
    setLoading(true)
    client.authenticate()
    .then(() => {
      return client.service('voucher-pouches').patch(id, {hide: true})
    })
    .then((res) => {
      let cloneData = cloneDeep(unavailableVouchers)
      cloneData = cloneData.filter(e => e._id !== id)
      setUnavailableVouchers(cloneData)

      setActiveKey(-1)
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      if(err.name === "NotAuthenticated"){
        
      }else{
        showToast('error', err.message)
      }
    })
  }

  const AccordionHeader = ({ index, value, live, status }) => {

    return (
      <div 
        className={`campaign-name ${activeKey === index? 'active': false}`} 
        onClick={() => {
          _activeStamp(value, index)
        }}
      >
        <div className='first'> 
          <div className='w-100 d-flex justify-content-between align-items-center'>
            <span>{value.title}</span>
             {/* redeemed, expired */}
              {/* {
                value.collected?
                <span className='collected'>Active</span>
                :null
              } */}
          </div>
          <span><i className={`fas fa-angle-${activeKey === index ? 'down' : 'right'}`}/></span>
        </div>
        {/* redeemed, expired */}
        {
          status &&
          <div className='second'>
            <div className={classNames('badge', {
              'redeemed': status === 'Redeemed',
              'otherBadge': status !== 'Redeemed',
              'redeemExpired': status === 'Redeem Expired'
            })}>
              {status}
            </div>
            {
              status !== 'Awaiting'? (
                <div className='badge delete' onClick={() => {
                  setDeleteId(value._id)
                  toggleModal()
                }}>
                  Delete
                </div>
              ): null
            }
          </div>
        }
      </div>
    )
  }

  const AccordionContent = ({ index, value, live, status }) => {
    
    return (
      <div className='campaign-detail'>
        {
          live || status === 'Redeemed'? (
            <>
              {
                value.status?
                <span className='expire'>Complete this voucher by: {moment(value.cardExpiry).format('DD/MM/YYYY - HH:mm')}</span>
                : (
                  live &&
                  <span className='expire'>Complete this voucher by: {moment(value.cardExpiry).format('DD/MM/YYYY - HH:mm')}</span>
                )
              }
              <div className='tos'>
                <span className='title'>Terms & Conditions</span>
                <div className='detail'>
                  <ul style={{marginBottom: 0}}>
                    <li>
                      Campaign Period {moment(value.period_start).format('DD/MM/YYYY')} - {moment(value.period_end).format('DD/MM/YYYY')}
                    </li>
                    <li>
                      This voucher is valid from {value.valid_start?moment(value.valid_start).format('DD/MM/YYYY'):''} - {value.valid_end?moment(value.valid_end).format('DD/MM/YYYY'):''}
                    </li>
                    <li>
                      This voucher is applicable for one (1) time usage only
                    </li>
                    {(value.isAtr === true) && (
                    <li>
                        {tncForAtr(Number(value.atrType))}
                    </li>
                    )}
                  </ul>
                  {parse(value.tos)}
                </div>
              </div>
              {
                status === 'Redeemed'?
                  <button className='unavailable'>
                    {
                      value.status === 'Redeemed'? (
                        <>
                          <span>Redeemed Voucher</span>
                          <span>At {moment(value.redeemDate).format('DD/MM/YYYY - HH:mm')}</span>
                        </>
                      ): (
                        <>
                          <span>Redeem Voucher</span>
                          <span>Starting From {moment(value.createdAt).add(value.use_validity, value.use_validity_unit).format('DD/MM/YYYY - HH:mm')}</span>
                        </>
                      )
                    }
                  </button>
                :
                // else expired
                value.canRedeem? 
                  (  new Date() >=  new Date(moment(value.valid_start))? 
                    (
                      <button 
                        onClick={() => redeemVoucher(value)}
                      >
                        <span>Redeem Voucher</span>
                        <span>Before {value.cardExpiry.format('DD/MM/YYYY - HH:mm')}</span>
                      </button>
                    )
                    : 
                    <button disabled>
                      <span>Redeem Voucher</span>
                      <span>Valid at {moment(value.valid_start).format('DD/MM/YYYY - HH:mm')}</span>
                    </button>)
                :
                  (
                  <button>
                    <span>Redeem Voucher</span>
                    <span>Expired {moment(value.valid_start).format('DD/MM/YYYY - HH:mm')}</span>
                  </button>
                  )
              }
            </>
          ): (
            <>
              {/* <span className='expire'>Complete this voucher by: {moment(value.cardExpiry).format('DD/MM/YYYY - HH:mm')}</span> */}
              <div className='tos'>
                <span className='title'>Terms & Conditions</span>
                <div className='detail'>
                  <ul style={{marginBottom: 0}}>
                    <li>
                      Campaign Period {moment(value.period_start).format('DD/MM/YYYY')} - {moment(value.period_end).format('DD/MM/YYYY')}
                    </li>
                    <li>
                      This voucher is valid from {value.valid_start?moment(value.valid_start).format('DD/MM/YYYY'):''} - {value.valid_end?moment(value.valid_end).format('DD/MM/YYYY'):''}
                    </li>
                    <li>
                      This voucher is applicable for one (1) time usage only
                    </li>
                    {(value.isAtr === true) && (
                    <li>
                        {tncForAtr(Number(value.atrType))}
                    </li>
                    )}
                  </ul>
                  {parse(value.tos)}
                </div>
              </div>
              {
                value.collected?
                <button 
                    style={{width: 'auto'}}
                    className="m-auto px-4"
                    disabled
                  >
                    <span>Collected</span>
                  </button>
                :
                status? null: (
                  <button 
                    style={{width: 'auto'}}
                    className="m-auto px-4"
                    onClick={() => voucherPouch(value)}
                  >
                    <span>Collect Voucher</span>
                  </button>
                )
              }
            </>
          )
        }
      </div>
    )
  }

  const RenderCampaign = ({activeTab}) => {
    if(activeTab === 0){
      return (
        <>
          {
            vouchers.length > 0?
            vouchers.map((_cm, _i) => {
                return (
                  <AccordionItem
                    onClick={() => availableStampsTracking(_i)}
                    key={_i}
                    eventKey={`available_${_i}`}
                    renderToggle={() =>
                      <AccordionHeader index={`available_${_i}`} value={_cm}/>
                    }
                    renderContent={() =>
                      <AccordionContent index={`available_${_i}`} value={_cm}/>
                    }
                  />
                )
            })
            : (
              <div className='text-center'>
                <br/>
                <img src={Voucher} alt="" />
                <br/>
                <br/>
                <p style={{
                  fontWeight: 400,
                  fontSize: 16,
                  color: '#CDD9D9'
                }}>{t('Voucher NoVoucher')}</p>
                <br/>
              </div>
            )
          }
        </>
      )
    }else if(activeTab === 1){
      return (
        <>
          {
            voucherPurchases.length > 0? 
              voucherPurchases.map((_cm, _i) => {
                  return (
                    <AccordionItem
                      onClick={() => activeStampsTracking(_i)}
                      key={_i}
                      eventKey={`live${_i}`}
                      renderToggle={() =>
                        <AccordionHeader index={`live${_i}`} value={_cm} live={true}/>
                      }
                      renderContent={() =>
                        <AccordionContent index={`live${_i}`} value={_cm} live={true}/>
                      }
                    />
                  )
              })
            : (
              <div className='text-center'>
                <br/>
                <img src={Voucher} alt="" />
                <br/>
                <br/>
                <p style={{
                  fontWeight: 500,
                  fontSize: 20,
                  color: '#CDD9D9',
                }}>Oops!</p>
                <p style={{
                  fontWeight: 400,
                  fontSize: 14,
                  // color: '#CDD9D9'
                }}>
                  <Trans i18nKey='Voucher ActiveMessage2' />
                </p>
                <br/>
              </div>
            )
          }
        </>
      )
    }else{
      return (
        <>
          {
            unavailableVouchers.length > 0?
            unavailableVouchers.map((_cm, _i) => {
                return (
                  <AccordionItem
                    onClick={() => unavailableStampsTracking(_i)}
                    key={_i}
                    eventKey={`unavailable_${_i}`}
                    renderToggle={() =>
                      <AccordionHeader index={`unavailable_${_i}`} value={_cm} status={_cm.status}/>
                    }
                    renderContent={() =>
                      <AccordionContent index={`unavailable_${_i}`} value={_cm} status={_cm.status}/>
                    }
                  />
                )
            })
            : (
              <div className='text-center'>
                <br/>
                <img src={Voucher} alt="" />
                <br/>
                <br/>
                <p style={{
                  fontWeight: 400,
                  fontSize: 16,
                  color: '#CDD9D9'
                }}>{t('Voucher NoVoucher')}</p>
                <br/>
              </div>
            )
          } 
        </>
      )
    }
  }

  return (
    <StampContainer color1={color1} color2={color2}
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      <div className='stamp-container' id="stamp-container">
        <TopNav 
          name={t(`Home ${campaignDef.name}`)}
          back={back}
          merchant={props.merchant}
          userInfo={props.userInfo}
          signout={props.signout}
          toggleLoginModal={props.toggleLoginModal}
        />
        
        <div className='tabs'>
          <div className={classNames('tab', {
            active: activeTab === 0
          })}
          onClick={() => fetchCampaign(0)}
          >{t('Voucher Available')}</div>
          <div className={classNames('tab', {
            active: activeTab === 1
          })}
          onClick={() => fetchCampaign(1)}>{t('Voucher Active')}</div>
          <div className={classNames('tab', {
            active: activeTab === 2
          })}
          onClick={() => fetchCampaign(2)}>{t('Voucher Unavailable')}</div>
        </div>

        <Accordion activeKey={activeKey}>
          <div className='section-accordion available'>
            <div className='campaigns'>

              <RenderCampaign activeTab={activeTab} />
              
            </div>
          </div>
        </Accordion>

      </div>

      <Modal
        title={false}
        visible={showAuthModal}
        onCancel={() => {
          setShowAuthModal(false)
          setPin('')
          if (timer?.current) {
            clearInterval(timer.current);
            timer.current = null
          }
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='auth-modal'>
          <span className='title1'>Authentication</span>
          <span className='title2'>Let merchant<br/>scan this QR code</span>
          <QRCode value={pin} size={200} />
          <span className='title3'>Or let merchant enter the PIN</span>
          <span className='title4'>PIN : <span className='title5'>{pin}</span></span>
          <span className='title6'>Time remaining</span>
          <span className='title7'>00 : {authTimer < 10 ? `0${authTimer}` : authTimer}</span>
        </div>
      </Modal>

      <Modal
        title={false}
        visible={showTransactionResultModal}
        onCancel={() => {
          setShowTransactionResultModal(false)
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='auth-modal'>
          {/* <span className='title1'>Authentication</span> */}
          {
            transactionResult === 'success' ? <img src={AuthSuccess} alt="" /> : <img src={AuthFail} alt="" />
          }
          {/* <span className={transactionResult === 'success' ? 'title8 success' : 'title8 fail'}>{transactionResult === 'success' ? 'Transaction is' : 'Transaction is'}</span> */}
          <br/>
          <span className={transactionResult === 'success' ? 'title8 success' : 'title8 fail'}>{transactionResult === 'success' ? 'Successful' : 'Unsuccessful'}</span>
          <button onClick={() => setShowTransactionResultModal(false)} className={transactionResult === 'success' ? 'btn-success' : 'btn-danger'}>Done</button>
        </div>
      </Modal>

      <ModalConfirmation 
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Delete Voucher
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>Are you sure want to delete this Voucher?</span>
          </>
        }
      />
    </StampContainer>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  getBalance: getBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(Vouchers);
