import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

import {Elements} from '@stripe/react-stripe-js';
import CardPayment from './CardPayment'

// let stripePromise = loadStripe('stripePublishableKey');
// (async () => {
//   const publishableKey = await axios.get(`${client.io.io.uri}config`).then(r => r.data.publishableKey)
//   stripePromise = loadStripe(publishableKey)
// })()

const StripePayment = ({
  stripePublishableKey,
  stripeSecretKey,
  currency, 
  // stripePromise, 
  paymentMadeComplete, 
  formValue, 
  totalAmount
}) => {
  const [ stripePromise, setStripePromise ] = useState(null)
  
  useEffect(() => {
    setStripePromise(loadStripe(stripePublishableKey))
  }, [stripePublishableKey, stripeSecretKey])

  return (  
    <>
      <div className="w-100 mt-4">
          {
            (stripePromise)? (
              <Elements stripe={stripePromise}>
                <CardPayment 
                  stripeSecretKey={stripeSecretKey}
                  paymentMadeComplete={paymentMadeComplete} 
                  formValue={formValue}
                  currency={currency}
                  totalAmount={totalAmount}
                />
              </Elements>
            ): null
          }
      </div>
    </>
  );
}
 
export default StripePayment;