import { useEffect, useState, useContext } from 'react';
import { Drawer } from "antd";
import { useHistory } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion'
import parse from 'html-react-parser';
import AccordionItem from 'components/Elements/StyledAccordionItem';
import { StampContainer } from "../Stamp/style";
import { DataContext } from "contexts/DataContextContainer";
import moment from 'moment'
import {
  FaTimesCircle
} from "react-icons/fa";
import { get_campaignDefs } from "functions.js"
import { Voucher } from "assets/images"
import ReactShare from "./ReactShare"
import TopNav from "components/TopNav/index"

import client from "feathers.js"

import 'services/i18'
import { useTranslation as translation } from 'react-i18next';
// import classNames from 'classnames';

const Referral = props => {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const {t} = translation()
  
  const history = useHistory();
  const { setLoading, showToast } = useContext(DataContext)
  const [ activeKey, setActiveKey ] = useState(-1)
  // const [ activeValue, setActiveValue ] = useState({})
  const [ createdValue, setCreatedValue ] = useState({})
  const [ showModal, setShowModal ] = useState(false)
  const [ referrals, setReferrals ] = useState([])
  const [ campaignDef ] = useState(get_campaignDefs('')[3])

  useEffect(() => {
    fetchReferrals()
    /* eslint-disable-next-line */
  }, [props.merchant._id])

  const fetchReferrals = () => {
    setLoading(true)
    client.service('referrals').find({
      query: {
        // for merchant create
        active: true,
        userId: props.merchant._id,
        period_start: {
          $lt: new Date()
        },
        period_end: {
          $gt: new Date()
        },
      }
    })
    .then((res) => {
      console.log(res)
      setReferrals(res.data)
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      if(err.name === "NotAuthenticated"){
        
      }else{
        showToast('error', err.message)
      }
      setLoading(false)
    })
  }

  const back = () => {
    history.goBack()
  }

  const inviteFriends = (value) => {
    setLoading(true)
    client.authenticate()
    .then(() => {
      // if applicableTo == new
      // else other title
      return client.service('referral-shares').create({
        referralId: value._id,
        merchantId: props.merchant._id,

        member_title: value.member_title,
        member_validity: value.member_validity,
        member_validity_unit: value.member_validity_unit,

        new_signup_title: value.new_signup_title,        
        new_signup_validity: value.new_signup_validity,
        new_signup_validity_unit: value.new_signup_validity_unit,

        period_start: value.period_start,
        period_end: value.period_end,
        
        expiry_validity_date: (moment(new Date()).add(parseInt(value.expiry_validity), value.expiry_validity_unit).format()),
        tos: value.tos,
        tos1: value.tos1,
        rewardType: value.rewardType,
        applicableTo: value.applicableTo,
      })
    })
    .then((res) => {
      setCreatedValue(res)
      setShowModal(true)
      setLoading(false)
    })
    .catch(err => {
      setLoading(false)
      if(err.name === "NotAuthenticated"){  
        props.toggleLoginModal()
        setActiveKey(-1)
      }else{
        showToast('error', err.message)
      }
    })
  }

  const _activeStamp = (value, index) => {
    if(activeKey === index){
      setActiveKey(-1)
    }else{
      setActiveKey(index)
      // setActiveValue(value)
    }
  }

  const AccordionHeader = ({ index, value, status }) => {

    return (
      <div 
        className={`campaign-name ${activeKey === index? 'active': false}`} 
        onClick={() => {
          _activeStamp(value, index)
        }}
      >
        <div className='first'> 
          <div 
          // className={classNames('referral', {
          //   remove: activeKey === index
          // })}
          style={{fontWeight: 400}}
          >
            {
              activeKey === index?
              <>
                Invite your friend <span style={{fontWeight: '500', fontSize: 14, color: 'black'}}>{"{"}{value.new_signup_title}{"}"}</span> and you will be rewarded with <span style={{fontWeight: '500', fontSize: 14, color: 'black'}}>{"{"}{value.member_title}{"}"}</span> when they redeem the gift.
              </>
              :
              <>
                <span style={{
                  fontSize: 11, 
                  lineHeight: 1, 
                  color: '#3B7779'
                }}>Get Reward</span>
                <br/>
                {value.member_title}
              </>
            }
          </div>
          <span><i className={`fas fa-angle-${activeKey === index ? 'down' : 'right'}`}/></span>
        </div>
        {/* redeemed, expired */}
        {
          status &&
          // <span className='status'>{status? status: ''}</span>
          <span className='status'>View Details</span>
        }
      </div>
    )
  }

  const AccordionContent = ({ index, value, status }) => {

    return (
      <div className='campaign-detail'>
        <div className='tos'>
          <span className='title'>Terms & Conditions</span>
          <div className='detail'>
            <ul style={{marginBottom: 0}}>
              <li>
                Campaign Period {value.period_start?moment(value.period_start).format('DD/MM/YYYY'):''} - {value.period_end?moment(value.period_end).format('DD/MM/YYYY'):''}
              </li>
              <li>
                This card is valid for {value.member_validity} {value.member_validity_unit.slice(0, -1)}(s) upon first collect
              </li>
              {/*
              <li>
                The validity of the link is {value.expiry_validity} {value.expiry_validity_unit.slice(0, -1)}(s)
              </li>
              <li>
                The validity of gift redemption is {value.new_signup_validity} {value.new_signup_validity_unit.slice(0, -1)}(s)
              </li>
              <li>
                The validity of referral reward is {value.member_validity} {value.member_validity_unit.slice(0, -1)}(s)
              </li>
              <li>
                Valid for {value.applicableTo === 'new'? "new customer" : "exsiting customer"}
              </li> */}
            </ul>
            {parse(value.tos)}
          </div>
        </div>
        <button
          style={{width: 'auto', background: '#DB5655'}}
          className="m-auto px-4"
          onClick={() => inviteFriends(value)}
        >
          <span style={{color: '#F5F5F6'}}>Invite Now</span>
        </button>
      </div>
    )
  }

  return (
    <StampContainer color1={color1} color2={color2}
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      <div className='stamp-container' id="stamp-container">
        <TopNav 
          name={t(`Home ${campaignDef.name}`)}
          back={back}
          merchant={props.merchant}
          userInfo={props.userInfo}
          signout={props.signout}
          toggleLoginModal={props.toggleLoginModal}
        />

        <Accordion activeKey={activeKey}>
          <div className='section-accordion available'>
            <p style={{textAlign: 'center', fontWeight: 500}}>{t('InviteFriend Message')}</p>
            {/* <div className='title'>
              <span>Invite Your Friend</span>
            </div> */}
            <div className='campaigns' style={{
                  fontFamily: 'Poppins',
                  fontSize: 14,
                  lineHeight: 1.2
            }}>
              
              {
                referrals.length > 0?
                referrals.map((_cm, _i) => {
                  // if( (new Date() >= new Date(_cm.period_start)) && (new Date() <= new Date(_cm.period_end)) && _cm.redeemed !== true ){
                    return (
                      <AccordionItem
                        key={_i}
                        eventKey={`available_${_i}`}
                        renderToggle={() =>
                          <AccordionHeader index={`available_${_i}`} value={_cm}/>
                        }
                        renderContent={() =>
                          <AccordionContent index={`available_${_i}`} value={_cm}/>
                        }
                      />
                    )
                  // }else{ return null}
                })
                : (
                  <div className='text-center'>
                    <br/>
                    <img src={Voucher} alt="" />
                    <br/>
                    <br/>
                    <p style={{
                      fontWeight: 400,
                      fontSize: 16,
                      color: '#CDD9D9'
                    }}>{t('InviteFriend NoVoucher')}</p>
                    <br/>
                  </div>
                )
              }
              
            </div>
          </div>
        </Accordion>
      </div>

      <Drawer
        title={
        <div className='text-center'>
          <h4 style={{
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 1,
            color: '#263238'
          }}>Share Via</h4>
          <p style={{
            fontWeight: 400,
            fontSize: 13,
            lineHeight: 1,
            color: '#B3B9C7'
          }}>Share your unique link now</p>
        </div>
        }
        visible={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        placement="bottom"
        contentWrapperStyle={{
          borderRadius: "20px 20px 0px 0px",
          overflow: "hidden"
        }}
        centered
        // closable={true}
        // footer={false}
        // maskClosable={false}
        getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='auth-modal'>
          <ReactShare 
            merchant={props.merchant}
            createdValue={createdValue}
            shareUrl={window.location.origin + "/" + props.merchant.username + '/ref/' + createdValue.refLinkId}
            // shareTitle={
            // }
          />
          <button onClick={() => setShowModal(false)} className='btn-success'>Done</button>
        </div>
      </Drawer>
    </StampContainer>
  );
}

export default Referral;
