import { breakpoints, colors } from "style";
import styled from "styled-components";

export const ProfileContainer = styled.div`
  overflow: scroll;
  width: 100%;
  display: flex;
  flex: 1;
  .profile-container {
    height: max-content;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    padding: 0 20px;
    padding-bottom: 24px;
    width: 100%;
    // border-top-left-radius: 24px;
    // border-top-right-radius: 24px;
    .picture {
      position: relative;
      img {
        background: #F9F9F9;
        border: 8px solid #FFFFFF;
        box-shadow: 0px 12px 19px rgba(220, 220, 220, 0.35);
        width: 144px;
        height: 144px;
        object-fit: cover;
        border-radius: 50%;
        margin-top: -70px;
      }
      .add-picture {
        cursor: pointer;
        width: 46px;
        height: 46px;
        background: #FFB84F;
        border: 5px solid #FFFFFF;
        border-radius: 50%;
        position: absolute;
        top: 60%;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 9;
        svg {
          position: absolute;
          color: white;
          top: 50%;
          transform: translate(0, -50%);
          left: 0;
          right: 0;
          margin: auto;
          font-size: 24px;
        }
      }
    }
    .info {
      width: 100%;
      padding: 0 16px;
      padding-bottom: 40px;
      .info-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 16px;
        .section-row-2 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          input {
            width: 48%;
          }
          .ant-select {
            width: 48%;
            .ant-select-selector {
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              border: none;
            }
          }
          .ant-picker {
            width: 48%;
            padding: 0 12px;
            border-radius: 6px;
            border: none;
          }
          &:not(:first-child) {
            margin-top: 8px;
          }
        }
        .section-row-1 {
          margin-top: 8px;
          width: 100%;
        }
        .mobile-number {
          display: flex;
          .country {
            padding-bottom: 0;
            button {
              height: 50px;
              background: white;
              border: none;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              span {
                font-size: 1.2em;
              }
            }
          }
          .seperator {
            width: 1px;
            display: flex;
            align-items: center;
            background: white;
            .line {
              width: 100%;
              height: 60%;
              background: ${colors.bgGrey};
            }
          }
          .input-group {
            .input-group-text {
              height: 50px;
              border: none;
              background-color: white;
              border-radius: 0;
            }
            input {
              width: unset;
              flex: 1;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              padding-left: 0;
            }
          }
        }
        .narrator {
          font-size: 14px;
          font-weight: 500;
          color: ${colors.purple};
          margin-bottom: 4px;
        }
        .narrator-1 {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 4px;
          text-align: center;
        }
        .social {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 6px;
          .ant-space-item {
            width: 100%;
          }
          .btn {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 20%;
            width: 100%;
            height: 40px;
            color: ${colors.white};
            font-size: 14px;
            border-radius: 6px;
            i {
              margin-right: 8px;
            }
          }
          .btn-facebook {
            background: #3785cc;
          }
          .btn-twitter {
            background: #68cdf1;
          }
          .btn-google {
            background: #f04621;
          }
        }
        .registered-merchants {
          .merchant {
            display: flex;
            justify-content: space-between;
            border: 1px dashed #FFB84F;
            border-radius: 10px;
            margin-top: 8px;
            padding: 8px;
            .merchant-name {
              img {
                width: 40px;
                height: 40px;
              }
              span {
                margin-left: 12px;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: #3B3054;
              }
            }
            .action {
              background: transparent;
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              color: #FFB84F;
            }
          }
        }
      }
      input {
        height: 50px;
        background: #FFFFFF;
        box-shadow: 0px 9px 27px rgba(225, 225, 225, 0.35);
        border-radius: 7px;
        border: none;
        &:focus {
          box-shadow: none;
        }
      }
      .btn-update {
        font-size: 16px;
        margin-top: 16px;
        width: 100%;
        height: unset;
        background: #3B7779;
        box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
        border-radius: 7px;
        color: white;
        padding: 10px 26px;
        // &:active {
        //   border: none;
        // };
      }
      .btn-facebook {
        font-size: 16px;
        margin-top: 16px;
        width: 100%;
        height: unset;
        background: #3D88CD;
        box-shadow: 0px 9px 27px rgba(225, 225, 225, 0.25);
        border-radius: 7px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 26px;
        img {
          margin-right: 10px;
        }
      }
      .btn-google {
        font-size: 16px;
        margin-top: 16px;
        width: 100%;
        height: unset;
        background: #F04B27;
        box-shadow: 0px 9px 27px rgba(225, 225, 225, 0.25);
        border-radius: 7px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 26px;
        img {
          margin-right: 10px;
        }
      }
      .btn-logout {
        margin-top: 24px;
        width: unset;
        height: unset;
        border-radius: 8px;
        background: #EE1919;
        color: white;
        padding: 10px 26px;
        // &:active {
        //   border: none;
        // };
      }
      .input-group-text, .form-control {
        border: none;
        background-color: white;
      }
      .facebook {
        color: #3D88CE;
        font-size: 24px;
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
      }
      .twitter {
        color: #6DCEF1;
        font-size: 24px;
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
      }
      .google {
        color: #F04C28;
        font-size: 24px;
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
      }
      .social-input {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
