import { breakpoints } from "style";
import styled from "styled-components";

export const MerchantContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  .merchant-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
    margin: 0 auto;
    position: relative;
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
