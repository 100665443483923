import styled from "styled-components";

export const TopNavContainer = styled.div`
  .title-nav {
    display: flex;
    align-items: center;
    position: relative;
    background-color: ${props => props.color1? props.color1: "#3B7779"};
    padding: 25px 20px 6px 20px;
    .btn-back {
      display: flex;
      align-items: center;
      color: #FFFFFF;
      position: absolute;
      z-index: 2;
    }
    .btn-back svg {
      font-size: 24px;
      margin-right: 6px;
    }
    .btn-back span {
      font-weight: 500;
      font-size: 12px;
      // line-height: 14px;
    }
    .title {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      img {
        width: 35px;
        margin-right: 16px;
      }
      span {
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;          
      }
    }
    button {
      font-size: 10px;
      font-weight: 500;
      position: absolute;
      right: 12px;
      padding: 1px 4px;
      border-radius: 4px;
    }
  }
  .sub-title {
    background-color: ${props => props.color1? props.color1: "#3B7779"};
    text-align: center;
    color: white;
    font-size: 12px;
    padding-bottom: 4px;
  }
`;
