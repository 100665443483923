import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'lib/Translate/locales/translationEN.json';
import ms from 'lib/Translate/locales/translationMS.json';
import zhcn from 'lib/Translate/locales/translationZHCN.json';
import zhcht from 'lib/Translate/locales/translationZHCHT.json';

const language = localStorage.getItem('selectedLanguage')

const resources = {
  en: {
    translation: en
  },
  ms: {
    translation: ms
  },
  zhcn: {
    translation: zhcn
  },
  zhcht: {
    translation: zhcht
  }
};

i18n
.use(initReactI18next)
.init({
  resources,
  lng: language,
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export default i18n;
