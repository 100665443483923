import roleReducer from './roleReducer';
import extraReducer from './extraReducer';

import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    role: roleReducer,
    extra: extraReducer,
});

export default rootReducer;