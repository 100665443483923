import React from "react";
import { Modal } from "antd";
import {
    FaTimesCircle
} from "react-icons/fa";
import { ModalContainer } from "./style"
import { useTranslation as translation } from "react-i18next";

const ModalPreview = (props) => {

  const {t} = translation();

  return ( 
    <>
    <Modal
        title={false}
        visible={props.modalVisible}
        onCancel={() => {
          props.toggleModal()
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        // getContainer={() => document.getElementById("merchant-container")}
        // getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <ModalContainer>
          
          <div className="header">
              {props.header}
          </div>

          <div className="body">
              {props.body}
          </div>
          
          <div className='footer'>
            <button onClick={() => props.toggleModal()} className='btn btnConfirm mr-1'>{t('Share Cancel')}</button>
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
}
 
export default ModalPreview;