import React, { useEffect, useState, useContext } from 'react';
import { TransactionsContainer } from "./style";
import { DataContext } from "contexts/DataContextContainer";
import moment from 'moment'
import { useHistory } from "react-router-dom";
import TopNav from "components/TopNav/index"

import client from "feathers.js"
import { Row, Col } from 'react-bootstrap';
import getSymbolFromCurrency from 'currency-symbol-map';

const Transactions = props => {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const history = useHistory();
  const { setLoading, showToast } = useContext(DataContext)
  const [ done, setDone ] = useState(false)
  const [ skip, setSkip ] = useState(0)
  const [ data, setData ] = useState([])

  useEffect(() => {
    fetchTransactions(0)
    /* eslint-disable-next-line */
  }, [])

  const fetchTransactions = (innerSkip) => {
    setLoading(true)

    var skipped = skip+innerSkip
    client.authenticate()
    .then(() => {
      return client.service('transactions').find({
        query: {
          userId: props.userInfo._id,
          $skip: skipped,
          type: {
            $in: ["wallet", "payment", "package"]
          },
          $sort: {
            createdAt: -1
          },
          $limit: 10
        }
      })
    })
    .then((res) => {
      setSkip(skipped)
      setData(data.concat(res.data))
      setLoading(false)
      if(res.data.length === 0){
        setDone(true)
      }
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){  
        props.toggleLoginModal()
      }else{
        showToast('error', err.message)
      }
      setLoading(false)
    })
  }

  const back = () => {
    history.goBack()
  }

  return (
    <TransactionsContainer color1={color1} color2={color2}
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      <div className='transactions-container' id="transactions-container">
        <TopNav 
          name={"Transactions"}
          back={back}
          merchant={props.merchant}
          userInfo={props.userInfo}
          signout={props.signout}
          toggleLoginModal={props.toggleLoginModal}
        />
       <div className='transactions-page-body'>
         <Row className='header mb-2'>
           <Col>Date</Col>
           {/* <Col>Time</Col> */}
           <Col>Type</Col>
           {/* <Col>Description</Col> */}
           <Col>Amount</Col>
         </Row>
         {
          data.length > 0?
          data.map((x, i) => {
            return (
              <div key={i} className="body">
                <Row className="align-items-center mb-2">
                  <Col>
                    <p>
                      {moment(x.createdAt).format('DD MMM YY')}
                      <br/>
                      {moment(x.createdAt).format('HH:MM')}
                    </p>
                  </Col>
                  <Col>{x.paymentType}</Col>
                  <Col>
                    {
                      x.creditDebit === 'credit'? 
                      <span className='text-success'>{'+ ' + getSymbolFromCurrency(x.currency) + x.amount}</span>
                      : 
                      <span className='text-danger'>{'- ' + getSymbolFromCurrency(x.currency) + x.amount}</span>
                    }
                  </Col>
                </Row>
                <hr/>
              </div>
            )
          }): <p style={{textAlign: 'center', color: '#BDBDBD'}}>No Transaction Yet</p>
        }
        {
          !done && data.length >= 10? (
            <button 
              className='load-more'
              onClick={() => fetchTransactions(10)}
            >Load more</button>
          ): null
        }
       </div>
      </div>
    </TransactionsContainer>
  );
}

export default Transactions;
