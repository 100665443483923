import React from 'react';
import { RewardContainer } from "./style";
import { connect } from 'react-redux'
import { Modal } from "antd";
import {
  FaTimesCircle
} from "react-icons/fa";
import { RewardSuccess } from "assets/images"

const SuccessModal = props => {

  return (
    <Modal
      title={false}
      visible={props.showModal}
      onCancel={() => {
        props.toggleModal()
      }}
      centered
      closable={true}
      footer={false}
      // maskClosable={false}
      // getContainer={() => document.getElementById("reward-container")}
      width='90%'
      className='login-antd-modal'
      closeIcon={<FaTimesCircle className='close-icon'/>}
    >
      <RewardContainer>
        <div className='reward-container'>
          <div className='success'>
            <img src={RewardSuccess} alt="" />
            <h5>
              Congratualation! You have signup successfully. The voucher is added to your voucher pouch.
            </h5>
          </div>
          <div className='pouch'>
            <button 
              type="button" 
              onClick={() => 
                props.emailRegister?
                  props.toggleModal(true)
                :
                  props.viewVoucher()
              }
            >
              {
                props.emailRegister?
                "Login and View Voucher"
                :
                "Go to Voucher Pouch"
              }
            </button>
          </div>
        </div>
      </RewardContainer>
    </Modal>
  );
}

const mapStateToProps = state => ({
  auth: state.role.auth,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal);
