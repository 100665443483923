import { colors } from "style";
import styled from "styled-components";

export const NotificationContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  // box-shadow:inset 0 0 0 2000px rgb(41 41 41 / 62%);
  background-color: ${props => props.color1};
  .notification-container {
    // margin-top: 10px;
    // padding: 20px;
    flex: 1;
    height: 100%;
    width: 100%;
    background: ${colors.white};
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    .notification-page-body {
      height: 82%;
      overflow: scroll;
      // margin-top: 30px;
      padding: 20px 30px;
      .card-wrapper {
        background: #FFFFFF;
        box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.25);
        border-radius: 10px;
        padding: 10px 3px; 
      }
      > div {
        // width: 100%;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // margin-bottom: 12px;
        .desc-wrap {
          display: flex;
          div {
            display: inline-block;
          }
          .date {
            width: 22%;
            text-transform: capitalize;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            // text-align: center;
            color: #3B7779;
          }
          .desc {
            // margin-right: 12px;
            width: 100%;
            // line-height: 1.2;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #263238;
          }
        }
        .uncheck {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #DB5655;
        }
      }
      .load-more {
        background: #3B7779;
        box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
        border-radius: 7px;
        color: #F5F5F6;
        font-weight: 700;
        font-size: 15px;
        width: 100%;
        padding: 6px 14px;
        margin-bottom: 20px;
        :active, : hover {
          background-color: transparent;
        }
      }
    }
  }
`;
