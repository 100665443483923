/* eslint-disable */
import React, { Component } from 'react';
import { message } from "antd";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';

import { useTranslation as translation } from 'react-i18next';
import 'services/i18'

// import './Demo.css';
// import exampleImage from './react-share-pin-example.png';

const exampleImage = ""

const Demo = props => {

  const {t} = translation();

  const shareUrl = props.shareUrl
  const title = props.shareTitle

  const shareToTelegram = () => {
    ReactGA.event({
      category: "click",
      action: "click_share_web_telegram",
    });
  }

  const shareToWhatsapp = () => {
    ReactGA.event({
      category: "click",
      action: "click_share_web_whatsapp",
    });
  }

  const shareToMessenger = () => {
    ReactGA.event({
      category: "click",
      action: "click_share_web_messenger",
    });
  }

  const shareToFacebook = () => {
    ReactGA.event({
      category: "click",
      action: "click_share_web_facebook",
    });
  }

  const shareToEmail = () => {
    ReactGA.event({
      category: "click",
      action: "click_share_web_email",
    });
  }

  const copyLink = () => {
    ReactGA.event({
      category: "click",
      action: "click_share_web_copyLink",
    });
  }
  

  return (
    <div className="Demo__container">

      {/* <div className="Demo__some-network">
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div> */}

      <div className="Demo__some-network">
        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
          onClick={() => shareToTelegram()}
        >
          <TelegramIcon size={36} round />
        </TelegramShareButton>
        <br/>
        <p>
          {t('Share Telegram')}
        </p>
        {/* <div className="Demo__some-network__share-count">&nbsp;</div> */}
      </div>

      <div className="Demo__some-network">
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="Demo__some-network__share-button"
          onClick={() => shareToWhatsapp()}
        >
          <WhatsappIcon size={36} round />
        </WhatsappShareButton>
        <p>
        {t('Share Whatsapp')}
        </p>
        {/* <div className="Demo__some-network__share-count">&nbsp;</div> */}
      </div>

      <div className="Demo__some-network">
        <FacebookMessengerShareButton
          url={shareUrl}
          appId="521270401588372"
          className="Demo__some-network__share-button"
          onClick={() => shareToMessenger()}
        >
          <FacebookMessengerIcon size={36} round />
        </FacebookMessengerShareButton>
        <p>
        {t('Share Messanger')}
        </p>
      </div>

      <div className="Demo__some-network">
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="Demo__some-network__share-button"
          onClick={() => shareToFacebook()}
        >
          <FacebookIcon size={36} round />
        </FacebookShareButton>
        <p>
        {t('Share Facebook')}
        </p>
        {/* <div>
          <FacebookShareCount url={shareUrl} className="Demo__some-network__share-count">
            {count => count}
          </FacebookShareCount>
        </div> */}
      </div>

      <div className="Demo__some-network">
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className="Demo__some-network__share-button"
          onClick={() => shareToEmail()}
        >
          <EmailIcon size={36} round />
        </EmailShareButton>
        <p>
        {t('Share Email')}
        </p>
      </div>

      <div className="Demo__some-network">
        <button style={{
          color: "#2f6f9f",
          borderRadius: '50%',
          width: 36,
          height: 36
        }}
        onClick={() => {
          message.success('Copied to clipboard!')
          navigator.clipboard.writeText(window.location.origin + "/" + props.merchant.username)
          copyLink()
        }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-link" viewBox="0 0 16 16">
            <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
            <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
          </svg>
        </button>
        <p>
        {t('Share Link')}
        </p>
      </div>

      {/* <div className="Demo__some-network">
        <button style={{
          color: "#2f6f9f",
          borderRadius: '50%',
          width: 36,
          height: 36
        }}
        onClick={() => {
          message.success('Copied to clipboard!')
          navigator.clipboard.writeText(window.location.origin + "/" + props.merchant.username)
        }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
          </svg>
        </button>
        <p>
          More
        </p>
      </div> */}

    </div>
  );
}

export default Demo;