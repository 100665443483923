import { breakpoints, colors } from "style";
import styled from "styled-components";
import { StarBg } from "assets/images"

export const TopupContainer = styled.div`
  // overflow: scroll;
  width: 100%;
  display: flex;
  flex: 1;
  // box-shadow:inset 0 0 0 2000px rgb(41 41 41 / 62%);
  background-color: ${props => props.color1};

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .topup-container {
    margin-top: 10px;
    // padding: 20px;
    flex: 1;
    width: 100%;
    background: ${colors.white};
    .topup-page-body {
      // flex-wrap: wrap;
      // flex: 1;
      height: 100%;
      // display: flex;
      margin-top: 30px;
      padding: 0px 18px;
      .balance-top-up-wrap {
        margin-bottom: 30px;
        background-color: ${props => props.color2};
        background-image: url(${StarBg});
        background-repeat: no-repeat;
        background-position: right;
        border-radius: 10px;
        margin-top: 14px;
        padding: 20px 18px;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.25);
        .balance-wrap {
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #263238;          
          }
          .balance {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 28px;
            line-height: 33px;
            color: #FFFFFF;
            img {
              width: 25px;
              padding: 1px;
              margin-right: 6px;
            }
          }
        }
        button.top-up {
          background: #3B7779;
          box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
          border-radius: 7px;
  
          font-weight: 700;
          font-size: 15px;
          color: #FFFFFF;
          padding: 8px 22px;
        }
      }
      // .balance-wrap {
      //   width: 100%;
      //   display: flex;
      //   justify-content: flex-start;
      //   align-items: center;
      //   span {
      //     font-size: 18px;
      //   }
      //   .balance {
      //     font-size: 24px;
      //     font-weight: bold;
      //     color: var(--mr-color-2);
      //     margin-left: 8px;
      //   }
      // }
      .topup-amount {
        width: 100%;
        span {
          font-weight: 400;
          font-size: 14px;
          color: #3B3054;
        }
        .buttons {
          margin-top: 12px;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-around;
          button {
            background: #FFFFFF;
            box-shadow: 0px 9px 27px rgba(225, 225, 225, 0.35);
            border-radius: 7px;

            font-weight: 600;
            font-size: 18px;
            color: #263238;

            padding: 10px 16px;
          }
        }
        .active {
          background-color: #3B7779 !important;
          border-color: #3B7779 !important;
          color: white !important; 
        }
      }
      .enter-amount {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        .first {
          p {
            font-weight: 400;
            font-size: 18px;
            color: #3B3054;
            margin: 0;
          }
          .small-text {
            font-weight: 400;
            font-size: 14px;
            color: #B3B9C7;
            padding-bottom: 8px;
          }
        }
        .amount-wrap {
          ::after {
            content: "";
            width: 40%;
            display: block;
            border: solid 1px;
            margin: auto;
          }
          p {
            display: inline-block;
            color: var(--mr-color-2);
            font-size: 72px;        
          }
        }
        .amount {
          background: transparent;
          font-weight: 700;
          font-size: 64px;
          color: #3B3054;
          border: none;
          width: 100%;
          text-align: center;
          :focus {
            outline: none;
          }
        }
      }
      .pay-with-wallet {
        width: 100%;
        padding: 0px 14px;
        text-align: center;
        margin-bottom: 35px;
        button:nth-child(1), button:nth-child(3) {
          background-color: ${props => props.color1};
          box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
          border-radius: 7px;
          font-weight: 700;
          font-size: 15px;
          color: #FFFFFF;
          text-transform: uppercase;
          width: 100%;
          padding: 14px;
          margin-bottom: 12px;
          :active, : hover {
            background-color: transparent;
            border: solid 1px #DB5655;
            color: #DB5655;
          }
        }
        button:nth-child(2) {
          background-color: ${props => props.color2};
          box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
          border-radius: 7px;
          font-weight: 700;
          font-size: 15px;
          color: #FFFFFF;
          text-transform: uppercase;
          width: 100%;
          padding: 14px;
          margin-bottom: 12px;
          :active, : hover {
            background-color: transparent;
            border: solid 1px #DB5655;
            color: #DB5655;
          }
        }
        span {
          font-weight: 400;
          font-size: 16px;
          color: #FFB84F;
        }
      }
    }
    .ant-modal-content {
      border-radius: 25px;
      .close-icon {
        font-size: 30px;
        color: #3B7779;
      }
    }
    .auth-modal {
      display: flex;
      flex-direction: column;
      justfy-content: center;
      align-items: center;
      .title1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #575757;
      }
      .title2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #979797;
        margin: 16px 0;
      }
      .title3 {
        font-weight: 400;
        font-size: 16px;
        color: #3B3054;
        opacity: 0.5;
        margin: 16px 0;
      }
      .title4 {
        font-style: italic;
        font-weight: 100;
        font-size: 32px;
        line-height: 36px;
        color: #3B3054;
      }
      .title5 {
        color: ${colors.red};
      }
      .title6 {
        font-weight: 400;
        font-size: 14px;
        color: ${colors.purple};
        margin-top: 20px;
      }
      .title7 {
        font-weight: 700;
        font-size: 20px;
        color: #3B7779;
      }
      .status-icon-success {
        font-size: 100px;
        color: ${colors.green};
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .status-icon-fail {
        font-size: 100px;
        color: red;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .title8 {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
      }
      .title9 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #979797;        
        margin-top: 16px;
      }
      .btn-default, .btnEmpty {
        width: 80%;
        height: 40px;
        border-radius: 20px;
        margin-top: 20px;
      }
      .btn-success {
        width: 80%;
        height: 40px;
        background: #3B7779;
        border-radius: 10px;
        margin-top: 20px;
      }
      .btn-danger {
        width: 80%;
        height: 40px;
        background: #3B7779;
        border-radius: 10px;
        margin-top: 20px;
        background: red;
      }

      .success {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #3B3054;
        text-transform: uppercase;
      }
      .fail {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #3B3054;
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
