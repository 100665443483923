import React from "react";
import { useHistory } from "react-router-dom";
import { TabBarContainer, FooterLogoContainer } from "./style";
import { Group427, PoweredBy } from "assets/images"

import 'services/i18'
import { useTranslation as translation } from 'react-i18next'

function TabBar(props) {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const history = useHistory();

  const {t} = translation()

  const switchTab = _route => {
    history.push(`/${props.merchant.username}` + _route);
  }
  const path = window.location.href.split('/')[4]

  return (
    <>
      <div style={{height: 80}}></div>
      <TabBarContainer 
        className="w-100" 
        id="merchant-footer"
        color1={color1}
        color2={color2}
      >
        <div className="tab-wrap" id="tab-wrap">
          <div className={`tab-item ${path === undefined && 'active'}`} onClick={() => {
            switchTab('')
            }}>
            <span className='like'><i className="fas fa-home"/></span>
            <span style={{fontSize: '8.9px'}}>{t('Home HomeButton')}</span>
          </div>
          {
            props.auth? (
              <div className={`tab-item ${(path === 'login' || path === 'profile') && 'active'}`} onClick={() => {
                // props.signout()
                switchTab('/profile')
              }}>
                <span className='like'><i className="fas fa-user"/></span>
                <span>{t('Home Profile')}</span>
              </div>
            ): (
              <div className={`tab-item ${(path === 'login' || path === 'profile') && 'active'}`} onClick={() => {
                props.toggleLoginModal()
                }}>
                <span className='like'><i className="fas fa-sign-in-alt"/></span>
                <span>{t('Home Login/Signup')}</span>
              </div>
            ) 
          }
          <div className={`tab-item ${path === 'notifications' && 'active'}`} onClick={() => {
            switchTab('/notifications')
            }}>
            <span className='like'><i className="fas fa-bell"/></span>
            <span>{t('Home Notifications')}</span>
          </div>
          <div className={`tab-item ${path === 'contact' && 'active'}`} onClick={() => {
            switchTab('/contact')
            }}>
            <span className='like'><i className="fas fa-id-badge"/></span>
            <span>{t('Home Contact')}</span>
          </div>
          <div className={`tab-item ${path === 'outlet' && 'active'}`} onClick={() => 
            switchTab('/outlet')
            }>
            <span className='like'><i className="fas fa-map-marked-alt"/></span>
            <span>{t('Home Outlet')}</span>
          </div>
        </div>
        <FooterLogoContainer
          color1={color1}
          color2={color2}
        >
          <a href="https://morerewardz.com/">
            <img src={PoweredBy} alt="" />
            {/* <img src={Group600} alt="" /> */}
            <img src={Group427} alt="" width="80" />
          </a>
        </FooterLogoContainer>
      </TabBarContainer>
    </>
  );
}

export default TabBar;
