import { breakpoints } from "style";
import styled from "styled-components";

export const AccordionItemContainer = styled.div`
  box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.25);
  border-radius: 10px;
  margin-bottom: 12px;

  .custom-accordion-toggler {
    color: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 45px;
  }
  @media (max-width: ${breakpoints.sm}px) {
    .custom-accordion-toggler {
      height: unset;
    }
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
