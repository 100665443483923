import { colors } from "style";
import styled from "styled-components";

export const OutletContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  // box-shadow:inset 0 0 0 2000px rgb(41 41 41 / 62%);
  background-color: ${props => props.color1};
  .outlet-container {
    // margin-top: 10px;
    // padding: 20px;
    flex: 1;
    height: 100%;
    width: 100%;
    background: ${colors.white};
    .outlet-page-body {
      margin-top: 30px;
      padding: 0px 18px;
      height: 82%;
      overflow: scroll;
      > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        .desc-wrap {
          display: flex;
          background: #FFFFFF;
          box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.35);
          border-radius: 10px;
          padding: 10px;
          width: 100%;

          .store-img img {
            width: 81px;
            height: 81px;
            border-radius: 5.42373px;
          }
          div {
            display: inline-block;
          }
          .desc {
            margin-left: 12px;
            margin-right: 12px;
            position: relative;
            h1 {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              text-transform: capitalize;
              color: #263238;
              margin-bottom: 0;
            }
            .p-wrapper {
              text-overflow: ellipsis;
              width: 200px;
              overflow: hidden;
              white-space: nowrap;
              height: 74px;
              display: inline-block;
              font-weight: 400;
              font-size: 12px;
              color: #979797;
              margin-bottom: 0;
            }
            a {
              position: absolute;
              right: -13px;
              display: inline-block;
              line-height: 1;
              font-size: 10px;
              font-weight: 400;
              color: var(--mr-color-4);
            }
            h6 {
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              color: #3B7779;
              margin-bottom: 0;
            }
          }
          .arrow svg {
            font-size: 20px;
            // margin-right: 6px;
          }
        }
        .uncheck {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #DB5655;
        }
      }
      .no-store {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        p {
          font-weight: 400;
          font-size: 16px;
          // color: #CDD9D9;
          margin-bottom: 0;
        }
      }
    }
  }
`;
