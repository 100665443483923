import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from "react-router-dom";
import { InfoContainer, CampaignsMessage } from "./style";
import { Button, Dropdown, Space, Rate } from 'antd';
import {connect} from 'react-redux';
import ReactShare from './ReactShare';

import { getAverageRates, getTotalReviews } from 'redux/actions/extraActions';
import ModalPreview from "components/Extra/ModalPreview"
import { Tooltip } from 'antd';

import client from "feathers.js"
import axios from 'axios';

import { isEmpty } from 'lodash';
import { RWebShare } from 'react-web-share';
import { isMobile } from 'react-device-detect';

import 'services/i18'
import { useTranslation as translation } from 'react-i18next';

var typingTimer = 0;

const MerchantInfo = ({
  merchant,
  userInfo,
  toggleLoginModal,
  getAverageRates,
  getTotalReviews,
  averageRates,
  totalReviews, 
}) => {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const {t} = translation()

  const ref = useRef(null)
  const firstLoad = useRef(true);
  const history = useHistory();
  const [ height, setHeight ] = useState(0)
  const [ likes, setLikes ] = useState(0)
  const [ dislikes, setDislikes ] = useState(0)
  const [ likeAction, setLikeAction ] = useState(0)
  const [ isSavedId, setIsSavedId ] = useState('')
  const [ modalVisible, setModalVisible ] = useState(false)
  const [ selectedLanguage, setSelectedLanguage ] = useState(localStorage.getItem('selectedLanguage'))

  console.log(merchant)

  const items = [
    {
      key: '1',
      label: (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px'}}>
          <div className='item' onClick={() => changeLanguage('en')}>
            🇬🇧
          </div>
          <div className='item' onClick={() => changeLanguage('ms')}>
            🇲🇾
          </div>
          <div className='item' onClick={() => changeLanguage('zhcn')}>
            🇨🇳
          </div>
          <div className='item' onClick={() => changeLanguage('zhcht')}>
            🇹🇼
          </div>
        </div>
      )
    } 
  ]

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  useEffect(() => {
    // let merchantContainer = document.getElementById('merchant-container')
    // let merchantInfo = document.getElementById('merchant-info')
    // let merchantCampaigns = document.getElementById('merchant-campaigns')
    // let merchantFooter = document.getElementById('merchant-footer')

    // if(merchantContainer && merchantInfo && merchantCampaigns && merchantFooter){
    //   setHeight(merchantContainer.offsetHeight - merchantInfo.offsetHeight - merchantCampaigns.offsetHeight - merchantFooter.offsetHeight)
    // }

    // if(document.getElementById('root').offsetHeight <= 667){
    //   setHeight(ref.current.offsetWidth/2)
    // }else{
    //   setHeight(ref.current.offsetWidth/1.5)
    // }
    setHeight(120)
    
  }, [])

  useEffect(() => {
    setLikeAction(merchant.isLike)
  }, [merchant.isLike])

  useEffect(() => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      
      console.log(likeAction)

      if (firstLoad.current) {
        firstLoad.current = false;
        return;
      }else{

        client.authenticate()
        .then(async() => {
          let findLike = await client.service('likes').find({
            query: {
              merchantId: merchant._id,
              userId: userInfo._id,
              $limit: 1,
              $select: ['_id']
            }
          });
          
          if(findLike.data.length > 0){
            client.service('likes').patch(findLike.data[0]._id, {
              isLike: likeAction,
            })
          }else{
            client.service('likes').create({
              isLike: likeAction,
              merchantId: merchant._id,
              userId: userInfo._id,
            })
          }
        })
        .catch(err => {
          console.log(err)
          // toggleLoginModal()
        })
        
      }
  
    }, 1000);
    /* eslint-disable-next-line */
  }, [likeAction, userInfo._id, merchant._id])

  useEffect(() => {
    axios.post(`${client.io.io.uri}getLikesAndDislikes`, {
      merchantId: merchant._id,
    })
    .then((res) => {
      if(res.status === 200){
        setLikes(res.data.likes)
        setDislikes(res.data.dislikes)
      }
    })
    .catch(err => {
      console.log(err)
    })
  }, [merchant._id])


  useEffect(() => {
    axios.post(`${client.io.io.uri}getAverageRating`, {
      merchantId: merchant._id,
    })
    .then((res) => {
      if(res.status === 200){
        getAverageRates(Number((res.data.averageRates).toFixed(1)))
        getTotalReviews(res.data.totalCount)
      }else{
        getAverageRates(0)
        getTotalReviews(0)
      }
    })
    .catch(err => {
      console.log(err)
      getAverageRates(0)
      getTotalReviews(0)
    })
    /* eslint-disable */
  }, [merchant._id])

  useEffect(() => {
    client.authenticate()
    .then(() => {
      return client.service('save-merchants').find({
        query: {
          merchantId: merchant._id,
          userId: userInfo._id,
          $limit: 1,
          $sort: {
            createdAt: -1
          }
        }
      });
    })
    .then((res) => {
      if(res.data.length > 0){
        setIsSavedId(res.data[0]._id)
      }else{
        setIsSavedId('')
      }
    })
    .catch(err => {
      console.log(err)
      setIsSavedId('')
      // toggleLoginModal()
    })
  }, [merchant._id])

  const changeLanguage = (value) => {
    setSelectedLanguage(value)

    localStorage.setItem('selectedLanguage', value)

    setTimeout(() => window.location.reload(), 500)
  }

  const isoCodeToFlagConverter = (value) => {
    switch (value) {
      case 'en': return '🇬🇧';
      case 'ms': return '🇲🇾';
      case 'zhcn': return '🇨🇳';
      case 'zhcht': return '🇹🇼';
      default: return '🇬🇧';
    }
  }

  const handleLike = () => {
    if(!firstLoad.current){
      client.authenticate()
      .then(() => {
        if(likeAction === 0){
          setLikeAction(1)
          setLikes(likes + 1)
        }else if(likeAction === 1){
          setLikeAction(0)
          setLikes(likes - 1)
        }
      })
      .catch(err => {
        console.log(err)
        toggleLoginModal()
      })
    }
  }

  const handleDislike = () => {
    if(!firstLoad.current){
      client.authenticate()
      .then(() => {
        if(likeAction === 0){
          setLikeAction(2)
          setDislikes(dislikes + 1)
        }else if(likeAction === 2){
          setLikeAction(0)
          setDislikes(dislikes - 1)
        }
      })
      .catch(err => {
        console.log(err)
        toggleLoginModal()
      })
    }
  }

  const redirectCampaign = () => {
    let campaignName = merchant.notification.split("||")[0]
    if(campaignName === "Package"){
      history.push(`/${merchant.username}/package`);
    }else if(campaignName === "Voucher"){
      history.push(`/${merchant.username}/voucher`);
    }else if(campaignName === "Referral"){
      history.push(`/${merchant.username}/referral`);
    }else{
      history.push(`/${merchant.username}/stamp`);
    }
  }

  const savetMerchant = () => {
    if(isSavedId){
      client.authenticate()
      .then(() => {
        return client.service('save-merchants').remove(isSavedId)
      })
      .then(() => {
        setIsSavedId('')
      })
      .catch(err => {
        console.log(err)
        toggleLoginModal()
      })
    }else{
      client.authenticate()
      .then(() => {
        return client.service('save-merchants').create({
          merchantId: merchant._id,
          merchantName: merchant.username,
          merchantBusinessName: merchant.business_name,
          userId: userInfo._id,
        });
      })
      .then((res) => {
        setIsSavedId(res._id)
      })
      .catch(err => {
        console.log(err)
        toggleLoginModal()
      })
    }
  }

  const shareMerchantUrl = () => {
    !isMobile && toggleModal();
  }

  const shareMerchantUrlMobile = () => {
    ReactGA.event({
      category: "click",
      action: "click_share_mobile",
    });
  }

  const googleMapRedirect = () => {
    ReactGA.event({
      category: "click",
      action: "click_seemore_googlemap",
    });
  }

  const starRating = () => {
    ReactGA.event({
      category: "click",
      action: "click_ratings_starRating",
    });
  }

  return (
    <InfoContainer id="merchant-info" color1={color1} color2={color2}>
      <div className='info-wrapper shadow-sm'>
        <div 
          ref={ref}
          className='info' 
          style={{ 
            backgroundImage: `url(${client.io.io.uri}${merchant.banner})`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center', 
            backgroundRepeat: 'no-repeat',
            height: height,
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <img src={`${client.io.io.uri}${merchant.logo}`} className='brand-logo' alt='brand-logo'/>
        </div>
        <div className='info-details'>
          {/* <img src={LogoWhite} className='app-logo' alt='logo-white'/> */}
          <div className='social'>
            <div className='w-100'>
              <div className='social-actions' style={{display: 'flex', gap: '5px', marginTop: '15px'}}>
                <div 
                  className={classNames('like', {
                    active: isSavedId
                  })}
                  onClick={() => savetMerchant()}
                ><i className="fas fa-heart"/></div>
                { isMobile ? (
                    <RWebShare
                      data={{
                        title: !merchant.seo_title ? `${merchant.business_name} has MoreRewardz` : merchant.seo_title,
                        text: !merchant.seo_desc ? `Check out all the awesome promotions from ${merchant.business_name}!` : merchant.seo_desc,
                        url: window.location.origin + "/" + merchant.username,
                      }}
                      onClick={() => shareMerchantUrlMobile()}
                    >
                      <div 
                        className='share'
                      ><i className="fas fa-share-alt"/></div>
                    </RWebShare>
                  ) : (
                    <div 
                      className='share'
                      onClick={() => shareMerchantUrl()}
                    ><i className="fas fa-share-alt"/></div>
                  )
                }
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomRight"
                  >
                  <button style={{background: 'transparent', boxShadow: '2px 3px #FAFAFA', borderRadius: '20px'}}>{isoCodeToFlagConverter(selectedLanguage)}</button>
                </Dropdown>
              </div>
              <div className='brand-info'>
                  <div className="header">
                    <h3 className='text-capitalize'>{merchant.business_name}</h3>
                    {
                      merchant.business_verified? (
                        <Tooltip 
                          title="Blue checkmark displayed against merchant indicated that MoreRewardz had taken steps to ensure that the account was actually owned by the person or organization whom it claimed to represent." 
                          color="#3B7779"
                          placement="right"
                        >
                          <div className='business-checked'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                            </svg>
                          </div>
                        </Tooltip>
                      ): null
                    }
                  </div>
                  {
                    !isEmpty(merchant.outlet)? 
                    merchant.outlet.formatted_address && (
                      <>
                        <div className='maps'>
                          <p>
                            <i className="fas fa-map-marker-alt"/> 
                            {" "}
                            {merchant.outlet.formatted_address}
                            {" "}
                          </p>
                          <a onClick={() => googleMapRedirect()} href={`${merchant.outlet.google_map_url}`} target="_blank" rel="noreferrer">{(`${t('Home SeeMore')}...`)}</a>
                        </div>
                      </>
                    ): null
                  }
                <div className='ratings d-flex'>
                  <div style={{width: '80%'}}>
                    <div 
                      style={{cursor: 'pointer'}} 
                      onClick={() => history.push(`/${merchant.username}/ratings`)}
                    >
                      <Rate 
                        disabled
                        allowHalf
                        value={averageRates}
                        onClick={() => starRating()}  
                      />
                      <p className="text d-inline-block ml-1">
                      {" "}
                      {t('Home Rating')}: {(averageRates).toFixed(1)} · {totalReviews} {t('Home Review')}
                      </p>
                    </div>
                  </div>
                  <div style={{width: '20%'}}>
                    <div className='d-flex justify-content-end'>
                      <div 
                        style={{cursor: 'pointer'}} 
                        onClick={handleLike}
                        className={classNames('thumbs-wrap mr-3', {
                          "liked": likeAction === 1
                        })}>
                        <i className="fas fa-thumbs-up"/>
                        <span className="text">
                          {" "}{likes}
                        </span>
                      </div>

                      <div
                        style={{cursor: 'pointer'}} 
                        onClick={handleDislike}
                        className={classNames('thumbs-wrap', {
                          "disliked": likeAction === 2
                        })}>
                        <i className="fas fa-thumbs-down"/>
                        <span className="text">
                        {" "}{dislikes}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CampaignsMessage onClick={() => redirectCampaign()}>
        {merchant.notification?
          (
            <div>
              {merchant.notification.split("||")[1]}
            </div>
          )
          :
          (
            <div>
              Welcome to <span>{merchant.business_name}</span>
            </div>
          )
        }
      </CampaignsMessage>

      <ModalPreview 
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        header={
          <>
            <br/>
            {t('Share Header')}
          </>
        }
        body={
          <>
            <ReactShare 
              merchant={merchant}
              shareUrl={window.location.origin + "/" + merchant.username}
              // shareTitle={
              //   ``
              // }
            />
          </>
        }
      />
    </InfoContainer>
  );
}

const mapStateToProps = state => ({
  averageRates: state.extra.averageRates?state.extra.averageRates:0,
  totalReviews: state.extra.totalReviews?state.extra.totalReviews:0,
});

const mapDispatchToProps = {
  getAverageRates: getAverageRates, 
  getTotalReviews: getTotalReviews
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantInfo);
