import { useContext, useEffect } from 'react';
import { MerchantContainer } from "./style";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Col, Form } from "react-bootstrap";
import StyledButton from "components/Elements/StyledButton";
import InfoContainer from "components/customer/MerchantInfo";
import LoadingView from "components/Elements/LoadingView";
import { DataContext } from "contexts/DataContextContainer";
import { getBalance, getPointsBalance } from 'redux/actions/roleActions';
import { getAverageRates, getTotalReviews } from 'redux/actions/extraActions';
import {connect} from 'react-redux';
import isEmpty from "lodash/isEmpty"

import client from "feathers.js"
import moment from 'moment';

const LoginSchema = Yup.object().shape({
  password: Yup.string()
  .required('Please Enter your password')
  .min(8,'password must contain 8 or more characters'),
  passwordConfirmation: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords must match')
  .required("Required!"),
});

const Merchant = (props) => {
  const params = useParams();
  const { setLoading, showToast } = useContext(DataContext)

  useEffect(() => {
    async function checkLink(){
      try{
        let getForgotPassword = await client.service('forgot-passwords').get(params.id)
        if(getForgotPassword){
          if(getForgotPassword.isReset === true){
            // showToast('error', 'Reset link is not valid!')  
            props.history.push(`/${props.merchant.username}`)
          }

          if(!(new Date(moment(new Date()).subtract(2, 'days')) <= new Date(getForgotPassword.createdAt))){
            // showToast('error', 'Reset link is not valid!')
            props.history.push(`/${props.merchant.username}`)
          }
        }else{
          // showToast('error', 'Reset link is not valid!')
          props.history.push(`/${props.merchant.username}`)
        }
      }catch(err){
        console.log(err)
        showToast('error', 'Reset link is not valid!')
        props.history.push(`/${props.merchant.username}`)
      }
    }
    checkLink()
    /* eslint-disable-next-line */
  }, [params.id])

  const signin = async (value, { resetForm }) => {
    setLoading(true)
    
    try{
      let getForgotPassword = await client.service('forgot-passwords').get(params.id)
      if(!getForgotPassword.isReset){
        client.service('users').patch(getForgotPassword.user_id, {
          password: value.password
        })
        .then((res) => {
          client.service('forgot-passwords').patch(params.id, {
            isReset: true
          })

          resetForm()
          showToast('success', 'The new password has been successfully changed.')
          setLoading(false)
    
          setTimeout(() => {
            props.history.push(`/${props.merchant.username}`)
          }, 
            500
          );
        })
        .catch((err)=>{
          showToast('error', err.message)
          setLoading(false)
        })
      }else{
        showToast('error', 'Reset link is not available!')
        setLoading(false)
      }
    }catch{
      showToast('error', 'Reset link is not valid!')
      setLoading(false)
    }
  }

  return (
    <MerchantContainer
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      {
        !isEmpty(props.merchant) ?
        <div className="merchant-container" id="stamp-container">
          <InfoContainer 
            merchant={props.merchant} 
            userInfo={props.userInfo}
            toggleLoginModal={props.toggleLoginModal}
          />
          <div className='review-ratings'>
            <div className='ratings-wrap'>
              <div>
                <h3>
                  Reset Password
                </h3>
                <p className='mb-0'>
                  Enter your new password below.
                  {/* Use at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character */}
                </p>
              </div>
            </div>
            <Formik
            validationSchema={LoginSchema}
            onSubmit={signin}
            initialValues={{
              password: "",
              passwordConfirmation: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              isValid,
              errors,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>

              <Form.Row className="mt-3">
                <Col md="12">
                  <div className="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100">
                    {/* <label htmlFor="form-field-name" className="elementor-field-label elementor-screen-only">
                      Password
                    </label> */}
                    <Form.Control
                      type="password" 
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      className="elementor-field elementor-size-sm  elementor-field-textual" 
                      placeholder="Enter New Password" 
                    />
                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                  </div>
                  </Col>
                  </Form.Row>

                  <Form.Row className="mt-3">
                    <Col md="12">
                  <div className="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100">
                    {/* <label htmlFor="form-field-name" className="elementor-field-label elementor-screen-only">
                      Merchant ID
                    </label> */}
                    <Form.Control
                      type="password"
                      name="passwordConfirmation"
                      value={values.passwordConfirmation}
                      isInvalid={!!errors.passwordConfirmation}
                      onChange={handleChange}
                      className="elementor-field elementor-size-sm  elementor-field-textual" 
                      placeholder="Confirm Password"
                    />
                    <Form.Control.Feedback type="invalid">{errors.passwordConfirmation}</Form.Control.Feedback>
                  </div>
                  </Col>
                  </Form.Row>

                  <div className="w-100 d-flex justify-content-center">
                    <StyledButton
                      type="submit"
                      sizeh={46}
                      width={'35%'}
                      radius="7"
                      bgcolor={'#FDB256'}
                      className="btn-blue mt-3"
                      title="Confirm"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        :
        <LoadingView isLoading/>
      }

    </MerchantContainer>
  );
}

const mapStateToProps = state => ({
  averageRates: state.extra.averageRates?state.extra.averageRates:0,
  totalReviews: state.extra.totalReviews?state.extra.totalReviews:0,
});

const mapDispatchToProps = {
  getBalance: getBalance,
  getPointsBalance: getPointsBalance,
  getAverageRates: getAverageRates, 
  getTotalReviews: getTotalReviews
};

export default connect(mapStateToProps, mapDispatchToProps)(Merchant);
