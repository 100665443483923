import { breakpoints } from "style";
import styled from "styled-components";

export const MerchantContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  overflow: scroll;
  .merchant-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    position: relative;
    .reviews-wrap {
      padding-bottom: 40px;
    }
    .review-ratings {
      border: none;
      border-radius: 15px;
      margin: 6px 20px 0px 20px;
      background: #fff;
      padding: 12px;
      box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.35);
      .ratings-wrap {
        display: flex;
        align-items: center;
        position: relative;
        text-align: center;
        justify-content: center;
        h3 {
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #3B7779;
        }
        p {
          font-weight: 500;
          font-size: 14px;
          color: #DBDBDB;
        }
      }
    }
    textarea::placeholder {
      font-size: 12px !important;
    }
    textarea {
      font-weight: 500;
      font-size: 12px !important;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
