import { colors } from "style";
import styled from "styled-components";

export const TransactionsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  // box-shadow:inset 0 0 0 2000px rgb(41 41 41 / 62%);
  background-color: ${props => props.color1};
  .transactions-container {
    // margin-top: 20px;
    // padding: 20px;
    flex: 1;
    height: 100%;
    width: 100%;
    background: ${colors.white};
    .transactions-page-body {
      height: 82%;
      overflow: scroll;
      // margin-top: 20px;
      padding: 20px;
      .header {
        color: #848484;
        text-align: center;
        // font-size: 12px;
      }
      .body {
        p {
          margin: 0;
        }
        text-transform: capitalize;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #3B7779;
      }
      .load-more {
        background-color: #db5655; 
        color: #F5F5F6;
        font-size: 14px;
        width: 100%;
        border-radius: 20px;
        padding: 6px 14px;
        margin-bottom: 12px;
        :active, : hover {
          background-color: transparent;
          border: solid 1px #DB5655;
          color: #DB5655;
        }
      }
    }
  }
`;
