import React, { useEffect, useState } from 'react';
import { OutletContainer } from "./style";
import { useHistory } from "react-router-dom";
import { noStore } from "assets/images"
import TopNav from "components/TopNav/index"

import client from "feathers.js"

import 'services/i18'
import { useTranslation as translation } from 'react-i18next';

const Outlet = props => {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const {t} = translation()

  const history = useHistory();
  const [ outlets, setOutlets ] = useState([])
  const [ seeMoreList, setSeeMoreList ] = useState([])
  const [ loadDone, setLoadDone ] = useState(false)
  
  useEffect(() => {
    client.service('outlets').find({
      query: {
        userId: props.merchant._id,
      }
    })
    .then((res) => {
      setOutlets(res.data)
      setLoadDone(true)
    })
    .catch((err)=>{
      setLoadDone(true)
      console.log(err)
    })
  }, [props.merchant._id])

  /* eslint-disable-next-line */
  const seeDetails = (e, i) => {
    e.preventDefault()
    setSeeMoreList(seeMoreList.concat(i))
  }

  const back = () => {
    history.goBack()
  }

  return (
    <OutletContainer color1={color1} color2={color2}
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      <div className='outlet-container' id="outlet-container">
        <TopNav 
          name={t('Outlet Title')}
          back={back}
          merchant={props.merchant}
          userInfo={props.userInfo}
          signout={props.signout}
          toggleLoginModal={props.toggleLoginModal}
        />

       <div className='outlet-page-body'>
        {
          // props.merchant.physicalStore === true || props.merchant.physicalStore === 'true'? (
          true? (
            <>
            {
              outlets.length > 0?
              outlets.map((x, i) => {
               return (
                <div key={i}>
                  <div className='desc-wrap'>
                    <div className='store-img'>
                      {/* <img src={StoreImg} alt="" /> */}
                      <a style={{display: 'block'}} href={`${x.google_map_url}`} target="_blank" rel="noreferrer">
                        <img 
                          src={`${client.io.io.uri}${x.canvasMap}`} 
                          alt="" 
                        />
                      </a>
                    </div>
                    <div className='desc' onClick={(e) => seeDetails(e, i)} style={{cursor: 'pointer'}}>
                      <h1 className='mb-1'>{x.storeName}</h1>
                      <div 
                        className='text-capitalize p-wrapper'
                        style={
                          seeMoreList.includes(i)? {
                            whiteSpace: 'unset',
                            width: 'unset',
                            height: 'unset'
                          }: null
                        }
                        >
                            <p className='d-flex mb-2' style={{lineHeight: 1.2}}>
                              <i className="fas fa-map-marker-alt"/> 
                              <span className='ml-1'>
                                {x.formatted_address}
                              </span>
                            </p>

                            <p className='d-flex mb-2' style={{lineHeight: 1.2}}>
                              <i className="fas fa-phone"/> 
                              <span className='ml-1'>
                                {x.outletPhonePrefix + " " + x.outletContactNumber}
                              </span>
                            </p>

                            <p className='d-flex mb-2' style={{lineHeight: 1.2}}>
                              <i className="fas fa-clock"/> 
                              <span className='ml-1'>
                                <span style={{textDecoration: 'underline'}}>
                                {t('Outlet OperatingHours')}
                                </span>
                                <br/>
                                {
                                  Object.entries(x.workingHours).map((x, i) => {
                                    if(x[1].length > 0){
                                      return (
                                        <span key={i}>
                                          {x[0]}{" "}
                                          {x[1][0].startTime} - {x[1][0].endTime}
                                          <br/>
                                        </span>
                                      )
                                    }else{return null}
                                  })
                                }
                              </span>
                            </p>
                        </div>
                      {
                        seeMoreList.includes(i)? 
                        null
                        : <a href="/#" className='arrow'>
                            <i className="fas fa-angle-right"></i> 
                          </a>
                      }
                      {/* <h6>Distance: 1.5 km</h6> */}
                    </div>
                  </div>
                  {/* <div className='store-map'>
                    <img src={StoreMap} alt="" />
                  </div> */}
                </div>
               )
             }): (
              <>
              {
                loadDone &&
                <div className='no-store'>
                  <p>There is no Physical Store</p>
                  <p>listed by the Merchant.</p>
                  <img src={noStore} alt="" />
                </div>
              }
              </>
             )
            }
            </>
          ): (
            <>
              {/* <div className='no-store'>
                <p>There is no Physical Store</p>
                <p>listed by the Merchant.</p>
                <img src={noStore} alt="" />
              </div> */}
            </>
          )
        }
       </div>
      </div>
    </OutletContainer>
  );
}

export default Outlet;
