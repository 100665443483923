import React, { useState } from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import StatusMessages, {useMessages} from './StatusMessages';

import axios from "axios";
import client from "feathers.js"

const CardForm = ({
  stripeSecretKey,
  paymentMadeComplete, 
  formValue, 
  currency,
  totalAmount
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [messages, addMessage] = useMessages();
  const [ loading, setLoading ] = useState(false)

  const handleSubmit = async (e) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    const cardElementContainer = document.querySelector('#card-element');
    let cardElementEmpty = cardElementContainer.classList.contains('StripeElement--empty');
    if(cardElementEmpty){
      addMessage('Payment Field must not be blank!')
    }else{

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        addMessage('Stripe.js has not yet loaded.');
        return;
      }

      setLoading(true)

      // Create PaymentIntent as soon as the page loads
      let clientSecret = await client.authenticate()
      .then((auth) => {
        return axios.post(`${client.io.io.uri}create-payment-intent`, {
          stripeSecretKey,
          paymentMethodType: 'card',
          currency: currency,
          amount: totalAmount.toFixed(2)
        },
        {
          headers: {
            'Authorization': auth.accessToken
          }
        }
        )
      })
      .then(res => {
        return res.data.clientSecret
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
        addMessage("Something went wrong!");
        return false
      })

      addMessage('Client secret returned');

      const {error: stripeError, paymentIntent} = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: (formValue.firstName + " " + formValue.lastName),
              email: formValue.email,
              phone: formValue.phoneNumber
            },
          },
        }
      );

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        addMessage(stripeError.message);
        setLoading(false)
        return;
      }

      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
      setLoading(false)
      paymentMadeComplete(paymentIntent)
    }
  };

  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-md-8'>
          <form id="payment-form" onSubmit={handleSubmit}>
            {/* <label htmlFor="card">Card</label> */}
            <CardElement id="card-element" className='Input' />

            <button 
              disabled={loading?true:false} 
              type="submit" 
              className='stipe-button w-100'
            >
              {loading ? 'Loading…' : 'Pay'}
            </button>
          </form>
          <StatusMessages messages={messages} />
        </div>
      </div>
    </>
  );
};

export default CardForm;
