import { useRef, useEffect, useState, useContext } from 'react';
import ReactGA from 'react-ga4';
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion'
import parse from 'html-react-parser';
import QRCode from "react-qr-code";
import randomstring from  'randomstring';
import AccordionItem from 'components/Elements/StyledAccordionItem';
import ModalConfirmation from 'components/Extra/ModalConfirmation';
import { StampContainer } from "../Stamp/style";
import { DataContext } from "contexts/DataContextContainer";
import moment from 'moment'
import {
 FaTimesCircle
} from "react-icons/fa"; 
import { isEmpty, cloneDeep } from 'lodash';
import { get_campaignDefs } from "functions.js"
import {connect} from 'react-redux';
import { Voucher, AuthSuccess, AuthFail, Stamped } from "assets/images"
import TopNav from "components/TopNav/index"

import client from "feathers.js"
import classNames from 'classnames';

import 'services/i18'
import { useTranslation as translation, Trans } from 'react-i18next';

const Stamps = props => {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const {t} = translation()

  const history = useHistory();
  const timer = useRef(null);
  const { setLoading, showToast } = useContext(DataContext)
  const [ activeTab, setActiveTab ] = useState(1)
  const [ activeKey, setActiveKey ] = useState(-1)
  const [ deleteId, setDeleteId ] = useState('')
  const [ activeCampaign, setActiveCampaign ] = useState({})
  const [ reverseValue, setReverseValue ] = useState({})
  const [ confirmAction, setConfirmAction ] = useState('delete')
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [ chops, setChops ] = useState([])
  const [showTransactionResultModal, setShowTransactionResultModal] = useState(false)
  const [pin, setPin] = useState('')
  const [authTimer, setAuthTimer] = useState(0)
  const [transactionResult, setTransactionResult] = useState(null)
  const [ stamps, setStamps ] = useState([])
  const [ stampCollected, setStampCollected ] = useState([])
  const [ unavailableStamps, setUnavailableStamps ] = useState([])
  const [ campaignDef ] = useState(get_campaignDefs('')[0])

  useEffect(() => {
    if(activeTab === 0){
      fetchStamps()
    }else{
      fetchCampaignPouch(props.merchant._id, props.userInfo._id)
    }
    /* eslint-disable-next-line */
  }, [props.merchant._id, props.userInfo._id, activeTab])

  const fetchCampaign = (key) => {
    if (key === 0) {
      ReactGA.event({
        category: "click",
        action: "click_stamps_availableTab",
      });
    }

    if (key === 1) {
      ReactGA.event({
        category: "click",
        action: "click_stamps_activeTab",
      });
    }

    if (key === 2) {
      ReactGA.event({
        category: "click",
        action: "click_stamps_unavailableTab",
      });
    }
   
    setActiveTab(key)
    // setLoading(true)
  }

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const modalCallback = () => {
    if(confirmAction === 'reverse'){
      reverseChop(activeCampaign, reverseValue, 0)
    }else{
      deleteCampaign(deleteId)
    }
  }

  const availableStampsTracking = (value) => {
    ReactGA.event({
      category: "click",
      action: `click_stamps_availableStamps`,
      label: value,
    });
  }

  const activeStampsTracking = (value) => {
    ReactGA.event({
      category: "click",
      action: `click_stamps_activeStamps`,
      label: value,
    });
  }

  const unavailableStampsTracking = (value) => {
    ReactGA.event({
      category: "click",
      action: `click_stamps_unavailableStamps`,
      label: value,
    });
  }


  const fetchStamps = () => {
    setLoading(true)
    client.service('stamps').find({
      query: {
        userId: props.merchant._id,
        active: true,
        period_start: {
          $lt: new Date()
        },
        period_end: {
          $gt: new Date()
        },
      }
    })
    .then((res) => {
      fetchStampPouches(res.data, props.merchant._id, props.userInfo._id)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      if(err.name === "NotAuthenticated"){
        
      }else{
        showToast('error', err.message)
      }
    })
  }

  const fetchStampPouches = (data, merchantId, userId) => {
    client.authenticate()
    .then(() => {
      return client.service('stamp-pouches').find({
        query: {
          getTotalCompleted: true,
          merchantId: merchantId,
          userId: userId,
          redeemed: false,
          stampId: {
            $in: cloneDeep(data).map(x => x._id)
          }
        }
      })
    })
    .then((res) => {

      let cloneStamps = cloneDeep(data)
      if(res.data.length > 0){
        res.data.map(x => {
          let findStampIndex = cloneStamps.findIndex(e => e._id === x.stampId)
          if(findStampIndex !== -1){
            // if not yet expired
            if(moment(new Date()) <= moment(x.createdAt).add(parseInt(cloneStamps[findStampIndex].campaign_validity), cloneStamps[findStampIndex].campaign_validity_unit)){
              // if expired
              if(!x.totalCompleted){
                // if not yet completed all
                cloneStamps[findStampIndex].collected = true
              }
            }else{
              // if stamp pouch expired
            }
            // cloneStamps.splice(findStampIndex, 1)
          }
          return x
        })
      }
        
      setStamps(cloneStamps)
      setLoading(false)

    })
    .catch(err => {
      console.log(err)
      setStamps(data)
      setLoading(false)
    })
  }

  const fetchCampaignPouch = (merchantId, userId) => {
    client.authenticate()
    .then(() => {
      return client.service('stamp-pouches').find({
        query: {
          getTotalCompleted: true,
          getStampInfo: true,
          getLastActivity: true,
          hide: {
            $ne: true
          },
          merchantId: merchantId,
          userId: userId,
          $sort: {
            createdAt: -1
          }
        }
      })
    })
    .then((res) => {

      let stampPouches = [] 
     
      function filterNotAvailable(stampPouches){
        let notAvailable = []
        stampPouches.map(x => {
          if(!x.canRedeem){
            notAvailable.push(x)
            stampPouches = stampPouches.filter(e => e._id !== x._id)
            return x
          }else{ 
            // let awaiting = new Date() < new Date(x.period_start)
            let expired = new Date() > new Date(x.cardExpiry)
            let status = x.redeemed? "Redeemed": ("Expired")
            
            if( (expired) || x.redeemed ){
              x.status = status
              notAvailable.push(x)
              stampPouches = stampPouches.filter(e => e._id !== x._id)
              return x
            }else{ return x }
          }
        })

        // remove hide
        if(notAvailable.length > 0){
          notAvailable = notAvailable.filter(e => !e.hide)
        }
        
        setStampCollected(stampPouches)
        setUnavailableStamps(notAvailable)
      }

      if(res.data.length > 0){
        res.data.map(x => {
          // purchased's _id to origin's _id
          // all activities under purchased package
          let cloneToPush = x.stampInfo
          // if pouch
          if(x.hasOneActivityDate){
            // if not completed
            if(!x.totalCompleted){
              cloneToPush.cardExpiry = (moment(x.createdAt).add(parseInt(cloneToPush.campaign_validity), cloneToPush.campaign_validity_unit).format())
            }else{
              // if completed all, check redeem date
              cloneToPush.cardExpiry = (moment(x.createdAt).add(parseInt(cloneToPush.redeem_validity), cloneToPush.redeem_validity_unit).format())
            }
          }else{
            cloneToPush.cardExpiry = (moment(cloneToPush.period_end).format())
          }

          // check for if able to redeem and update status 
          // if not yet redeemed
          if(!x.redeemed){
            // if expired
            if(moment(new Date()) <= moment(x.createdAt).add(parseInt(cloneToPush.campaign_validity), cloneToPush.campaign_validity_unit)){
              // if not yet completed all and no expired
              if(!x.totalCompleted){
                // continue
                cloneToPush.canRedeem = true
              }else{
                // if completed all, check redeem date
                // lastActivity is the last stamp of the stamp card -> check only redeem date if completed stamp card
                let checkRedeem = (new Date() >  new Date(moment(x.lastActivity.createdAt).add(parseInt(cloneToPush.redeem_validity), cloneToPush.redeem_validity_unit)))? false: true
                cloneToPush.canRedeem = checkRedeem
                cloneToPush.status = checkRedeem?"":"Redeem Expired"
              }
            }else{
              // if stamp pouch expired
              cloneToPush.canRedeem = false
              cloneToPush.status = "Card Expired"
            }
          }else{
            cloneToPush.canRedeem = false
            cloneToPush.status = "Redeemed"
          }
          
          // get child data
          cloneToPush._id = x._id
          cloneToPush.redeemBeforeDate = moment(x.lastActivity.createdAt).add(parseInt(cloneToPush.redeem_validity), cloneToPush.redeem_validity_unit)
          cloneToPush.hasOneActivityDate = x.hasOneActivityDate
          cloneToPush.redeemed = x.redeemed
          cloneToPush.redeemDate = x.redeemDate
          cloneToPush.hide = x.hide
          cloneToPush.repName = x.repName
          cloneToPush.remarks = x.remarks
          cloneToPush.createdAt = x.createdAt
          cloneToPush.updatedAt = x.updatedAt
          stampPouches.push(cloneToPush)
          return x
        })

        filterNotAvailable(stampPouches)
      }else{
        filterNotAvailable(res.data)
        // there is purchase
      }
      setLoading(false)

    })
    .catch(err => {
      console.log(err)
      setLoading(false)
    })
  }

  const back = () => {
    history.goBack()
  }

  const getChops = (stampId, userId, value, index) => {
    if(userId){
      client.service('activities').find({
        query: {
          title: value.title,
          campaignType: "stamp",
          campaignId: stampId, 
          userId,
          status: 'authorized', 
          action: { $ne: "reverse" },
          $limit: value.count
        }
      })
      .then((res) => {
        let totalChops = (value.count - res.total)
        for(let i = 0; i < totalChops; i++){
          res.data.push({})
        }
        setChops(res.data)
      })
      .catch((err)=>{
        console.log(err)
      })
    }else{
      let chops = []
      let totalChops = (value.count)
        for(let i = 0; i < totalChops; i++){
          chops.push({})
        }
        setChops(chops)
    }
  }
  
  const collectChop = (value, stamp, index) => {

    if(!props.checkSubEnd(props.merchant)){
      return false
    }

    if(isEmpty(stamp)){
      
      const _pin = randomstring.generate({length: 4, charset: 'numeric'})

      client.authenticate()
      .then(() => {
        return client.service('activities').create({
          title: value.title,
          customer_email: props.userInfo.email,
          merchantId: props.merchant._id,
          merchantName: props.merchant.username,
          campaignType: "stamp",
          campaignId: value._id, 
          pin: _pin, 
          countLeft: cloneDeep(chops).filter(e => isEmpty(e)).length
        })
      })
      .then((res) => {

        setShowAuthModal(true)
        setLoading(false)
        setPin(_pin)

        if (timer?.current) {
          clearInterval(timer.current);
        }

        setAuthTimer(60)
        timer.current = setInterval(() => {
          console.log(authTimer)

          setAuthTimer(preVal => {
            if (preVal > 1) {
              return preVal - 1
            } else {
              setShowAuthModal(false)
              setPin('')
              if (timer?.current) {
                clearInterval(timer.current);
                timer.current = null
              }
              return 0
            }
           });
        }, 1000)

        getActivityAuthResponse(res._id, _pin, value, index)

      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){  
          props.toggleLoginModal()
          setActiveKey(-1)
        }else{
          showToast('error', err.message)
        }
      })
    }
  }  

  const getActivityAuthResponse = async (chopId, _pin, value, index) => {

    var result = await client.service('activities').get(chopId)

    if (result.status === 'unauthorized') {
      if (timer?.current) {
        setTimeout(() => {
          getActivityAuthResponse(chopId, _pin, value, index)
        }, 1500)
      }
    } else if (result.status === 'authorized') {

      // let cloneChops = cloneDeep(chops)
      // let findChopIndex = cloneChops.findIndex(e => isEmpty(e))
      // cloneChops[findChopIndex] = result

      // setChops(cloneChops)
      // fetchStamps()
      fetchCampaignPouch(props.merchant._id, props.userInfo._id)
      getChops(value._id, props.userInfo._id, value, index)

      setShowAuthModal(false)
      setPin('')
      if (timer?.current) {
        clearInterval(timer.current);
        timer.current = null
      }

      setTransactionResult('success')
      setShowTransactionResultModal(true)

    } else if (result.status === 'declined') {
      setShowAuthModal(false)
      setPin('')
      if (timer?.current) {
        clearInterval(timer.current);
        timer.current = null
      }

      setTransactionResult('fail')
      setShowTransactionResultModal(true)
    }
    
  }

  const reverseChop = (value, stamp, index) => {

    if(!props.checkSubEnd(props.merchant)){
      return false
    }

    if(!isEmpty(stamp)){
      
      const _pin = randomstring.generate({length: 4, charset: 'numeric'})

      client.authenticate()
      .then(() => {
        return client.service('activities').create({
          title: value.title,
          customer_email: props.userInfo.email,
          merchantId: props.merchant._id,
          merchantName: props.merchant.username,
          campaignType: "stamp",
          campaignId: value._id, 
          pin: _pin, 
          countLeft: cloneDeep(chops).filter(e => isEmpty(e)).length,
          action: "reverse",
          reverseId: stamp._id
        })
      })
      .then((res) => {

        setShowAuthModal(true)
        setLoading(false)
        setPin(_pin)

        if (timer?.current) {
          clearInterval(timer.current);
        }

        setAuthTimer(60)
        timer.current = setInterval(() => {
          console.log(authTimer)

          setAuthTimer(preVal => {
            if (preVal > 1) {
              return preVal - 1
            } else {
              setShowAuthModal(false)
              setPin('')
              if (timer?.current) {
                clearInterval(timer.current);
                timer.current = null
              }
              return 0
            }
           });
        }, 1000)

        getActivityAuthResponse(res._id, _pin, value, index)

      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){  
          props.toggleLoginModal()
          setActiveKey(-1)
        }else{
          showToast('error', err.message)
        }
      })
    }
  } 

  const stampCollect = async (value) => {

    if(!props.checkSubEnd(props.merchant)){
      return false
    }

    setLoading(true)
    client.service('stamp-pouches').create({
      merchantId: props.merchant._id,
      stampId: value._id,
      userId: props.userInfo._id,
      count: value.count
    })
    .then((res) => {
      setLoading(false)
      fetchStamps()
      setActiveKey(-1)
      setActiveTab(1)
    })
    .catch(err =>{
      if(err.name === "NotAuthenticated"){  
        props.toggleLoginModal()
        setActiveKey(-1)
      }else{
        showToast('error', err.message)
      }
      setLoading(false)
    })
  }

  const redeemStamp = async (value) => {

    if(!props.checkSubEnd(props.merchant)){
      return false
    }
    
    // setLoading(true)
    const _pin = randomstring.generate({length: 4, charset: 'numeric'})

    client.authenticate()
      .then(() => {
        return client.service('activities').create({
          title: value.title,
          customer_email: props.userInfo.email,
          merchantId: props.merchant._id,
          merchantName: props.merchant.username,
          campaignType: "stamp",
          redeem: true,
          action: 'redeemed',
          campaignId: value._id, 
          pin: _pin, 
          activities: chops.map(act => act._id)
        })
      })
      .then((res) => {

        setShowAuthModal(true)
        setLoading(false)
        setPin(_pin)

        if (timer?.current) {
          clearInterval(timer.current);
        }

        setAuthTimer(60)
        timer.current = setInterval(() => {
          console.log(authTimer)

          setAuthTimer(preVal => {
            if (preVal > 1) {
              return preVal - 1
            } else {
              setShowAuthModal(false)
              setPin('')
              if (timer?.current) {
                clearInterval(timer.current);
                timer.current = null
              }
              return 0
            }
           });
        }, 1000)

        getRedeemAuthResponse(res._id, _pin, value._id)

      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
        
        }else{
          showToast('error', err.message)
        }
        setLoading(false)
      })
  }

  const getRedeemAuthResponse = async (activityId, _pin, stampId) => {

    const result = await client.service('activities').get(activityId)

    if (result.status === 'unauthorized') {
      if (timer?.current) {
        setTimeout(() => {
          getRedeemAuthResponse(activityId, _pin, stampId)
        }, 1500)
      }
    } else if (result.status === 'authorized') {

      // mobile patch stamp
      // redeemed
      // re fetch stamp status
      // let stampIndex = stamps.findIndex(e => e._id === stampId)
      // stamps[stampIndex].redeemed = true
      // setStamps(stamps)
      // fetchStamps()
      fetchCampaignPouch(props.merchant._id, props.userInfo._id)
      setActiveKey(-1)

      setShowAuthModal(false)
      setPin('')
      if (timer?.current) {
        clearInterval(timer.current);
        timer.current = null
      }

      setTransactionResult('success')
      setShowTransactionResultModal(true)

    } else if (result.status === 'declined') {
      setShowAuthModal(false)
      setPin('')
      if (timer?.current) {
        clearInterval(timer.current);
        timer.current = null
      }

      setTransactionResult('fail')
      setShowTransactionResultModal(true)
    }
  }

  const _activeStamp = (value, index) => {    
    if(activeKey === index){
      setActiveKey(-1)
    }else{
      setActiveKey(index)
      getChops(value._id, props.userInfo._id, value, index)
    }
  }

  const deleteCampaign = (id) => {
    setLoading(true)
    client.authenticate()
    .then(() => {
      return client.service('stamp-pouches').patch(id, {hide: true})
    })
    .then((res) => {
      let cloneData = cloneDeep(unavailableStamps)
      cloneData = cloneData.filter(e => e._id !== id)
      setUnavailableStamps(cloneData)

      setActiveKey(-1)
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      if(err.name === "NotAuthenticated"){
        
      }else{
        showToast('error', err.message)
      }
    })
  }

  const AccordionHeader = ({ index, value, live }) => {

    return (
      <div 
        className={`campaign-name ${activeKey === index? 'active': false}`} 
        onClick={() => 
          _activeStamp(value, index)
        }
      >
        <div className='first'> 
          <div className='w-100 d-flex justify-content-between align-items-center'>
            <span>{value.title}</span>
             {/* redeemed, expired */}
              {
                value.collected?
                <span className='collected'>Active</span>
                :null
              }
          </div>
          <span><i className={`fas fa-angle-${activeKey === index ? 'down' : 'right'}`}/></span>
        </div>
          {
            value.status &&
            <div className='second'>
              <div className={classNames('badge', {
                'redeemed': value.status === 'Redeemed',
                'otherBadge': value.status !== 'Redeemed',
                'redeemExpired': value.status === 'Redeem Expired'
              })}>
                {value.status}
              </div>
              {
                value.status !== 'Awaiting'? (
                  <div className='badge delete' onClick={() => {
                    setDeleteId(value._id)
                    setConfirmAction('delete')
                    toggleModal()
                  }}>
                    Delete
                  </div>
                ): null
              }
            </div>
          }
      </div>
    )
  }

  const RenderChops = ({value, index, status, available, live}) => {
    return (
      <>
        <div>
          {
            value.status?
            <span className='expire'>Complete this card by: {moment(value.cardExpiry).format('DD/MM/YYYY - HH:mm')}</span>
            : (
              live &&
              <span className='expire'>Complete this card by: {moment(value.cardExpiry).format('DD/MM/YYYY - HH:mm')}</span>
            )
          }
        </div>
        <div className='row cards no-gutters'>
          {
            chops.map((_chop, _si) =>
              _chop.status === 'authorized' ? 
              (
                value.status? (
                <div 
                  className='col-4 col-sm-4 col-md-4' 
                  key={_si} 
                >
                  <div className='card'>
                    <div className='stamp-wrapper'>
                      <div className='status'>
                        {/* <span>STAMPED</span> */}
                        <img className='stamped' src={Stamped} alt="" />
                      </div>
                      <div className='stamp-detail'>
                        <span>{moment(_chop.createdAt).format('DD/MM/YY - HH:mm')}</span>
                        <span>{_chop.repName?_chop.repName:'-'}</span>
                        <span>{(_chop.remarks?_chop.remarks:'-')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                ): (
                  <div 
                    className='col-4 col-sm-4 col-md-4' 
                    key={_si} 
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      setActiveCampaign(value)
                      setReverseValue(_chop)
                      setConfirmAction('reverse')
                      toggleModal()
                    }}
                  >
                    <div className='card'>
                      <div className='stamp-wrapper'>
                        <div className='status'>
                          {/* <span>STAMPED</span> */}
                          <img className='stamped' src={Stamped} alt="" />
                        </div>
                        <div className='stamp-detail'>
                          <span>{moment(_chop.createdAt).format('DD/MM/YY - HH:mm')}</span>
                          <span>{_chop.repName?_chop.repName:'-'}</span>
                          <span>{(_chop.remarks?_chop.remarks:'-')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )
              : 
              status? (
                <div 
                  className='col-4 col-sm-4 col-md-4' 
                  key={_si} 
                >
                  <div className='card'>
                    <span className='card-no'>{_si+1}</span>
                  </div>
                </div>
              )
              : 
                (available? (
                  <div 
                    className='col-4 col-sm-4 col-md-4' 
                    key={_si} 
                    style={{cursor: 'pointer'}}
                    onClick={() => collectChop(value, _chop, index)}
                  >
                    <div className='card'>
                      <span className='card-no'>{_si+1}</span>
                    </div>
                  </div>
                ): (
                  <div 
                    className='col-4 col-sm-4 col-md-4' 
                    key={_si} 
                  >
                    <div className='card'>
                      <span className='card-no'>{_si+1}</span>
                    </div>
                  </div>
                )
              )
            )
          }
        </div>
      </>
    )
  }

  const AccordionContent = ({ index, value, live }) => {

    return (
      <div className='campaign-detail'>
        {
          live || value.status === 'Redeemed'? (
            <>
              {
                chops && <RenderChops value={value} index={index} status={value.status} available={true} live={live} />
              }

              <div className='tos'>
                <span className='title'>Terms & Conditions</span>
                <div className='detail'>
                  <ul style={{marginBottom: 0}}>
                    <li>
                      Campaign Period {moment(value.period_start).format('DD/MM/YYYY')} - {moment(value.period_end).format('DD/MM/YYYY')}
                    </li>
                    <li>
                      Complete this stamp card within {value.campaign_validity} {value.campaign_validity_unit.slice(0, -1)}(s) after collecting first stamp
                    </li>
                    <li>
                      Redeem reward within {value.redeem_validity} {value.redeem_validity_unit.slice(0, -1)}(s) upon completing
                    </li>
                  </ul>
                  {parse(value.tos)}
                </div>
              </div>
              
              {
                (value.canRedeem && (value.count === (cloneDeep(chops).filter(e => JSON.stringify(e) !== '{}').length ) )) &&
                <button 
                  onClick={() => redeemStamp(value)}
                >
                  <span>Redeem Reward</span>
                  <span>Before {
                    new Date(value.redeemBeforeDate) > new Date(value.cardExpiry)?
                    moment(value.cardExpiry).format('DD/MM/YYYY - HH:mm'):
                    moment(value.redeemBeforeDate).format('DD/MM/YYYY - HH:mm')
                  }</span>
                </button>
              }
              {
                value.status === 'Redeemed'? (
                  <button type="button" disabled>
                    <span>Redeemed : {value.redeemDate? moment(value.redeemDate).format('DD/MM/YYYY'): ''}</span>
                    <span>{
                      (value.repName?value.repName:'-') + ": " +
                      (value.remarks?value.remarks:'-')
                    }</span>
                  </button>
                ): null
              }
              {/* {
                live? (
                  <button type="button" disabled>
                    <span>Redeemed : {chops[chops.length - 1].createdAt? moment(chops[chops.length - 1].createdAt).format('DD/MM/YYYY - HH:mm'): ''}</span>
                    <span>{chops[chops.length - 1].repName + ", " + chops[chops.length - 1].remarks}</span>
                  </button>
                ): null
              } */}
            </>
          ): (
            <>
              {
                chops && <RenderChops value={value} index={index} status={value.status} available={false} live={live} />
              }
              <div className='tos'>
                <span className='title'>Terms & Conditions</span>
                <div className='detail'>
                  <ul style={{marginBottom: 0}}>
                    <li>
                      Campaign Period {moment(value.period_start).format('DD/MM/YYYY')} - {moment(value.period_end).format('DD/MM/YYYY')}
                    </li>
                    <li>
                      Complete this stamp card within {value.campaign_validity} {value.campaign_validity_unit.slice(0, -1)}(s) after collecting first stamp
                    </li>
                    <li>
                      Redeem reward within {value.redeem_validity} {value.redeem_validity_unit.slice(0, -1)}(s) upon completing
                    </li>
                  </ul>
                  {parse(value.tos)}
                </div>
              </div>
              {
                value.collected?
                <button 
                  style={{width: 'auto'}}
                  className="m-auto px-4"
                  disabled
                >
                  <span>Collected</span>
                </button>
                :
                value.status? null: (
                  <button 
                    style={{width: 'auto'}}
                    className="m-auto px-4"
                    onClick={() => stampCollect(value)}
                  >
                    <span>{t('Stamp CollectCard')}</span>
                  </button>
                )
              }
              {
                value.status === 'Redeem Expired'? (
                  <button type="button" disabled>
                    <span>Redeem Expired : {value.redeemBeforeDate? moment(value.redeemBeforeDate).format('DD/MM/YYYY - HH:mm'): ''}</span>
                  </button>
                ): null
              }
            </>
          )
        }
      </div>
    )
  }

  const RenderCampaign = ({activeTab}) => {
    if(activeTab === 0){
      return (
        <>
        {
          stamps.length > 0?
          stamps.map((_cm, _i) => {
              return (
                <AccordionItem
                onClick={() => availableStampsTracking(_i)}
                  key={_i}
                  eventKey={`available_${_i}`}
                  renderToggle={() =>
                    <AccordionHeader index={`available_${_i}`} value={_cm}/>
                  }
                  renderContent={() =>
                    <AccordionContent index={`available_${_i}`} value={_cm}/>
                  }
                />
              )
          })
          : (
            <div className='text-center'>
              <br/>
              <img src={Voucher} alt="" />
              <br/>
              <br/>
              <p style={{
                fontWeight: 400,
                fontSize: 16,
                color: '#CDD9D9'
              }}>{t('Stamp NoStampCard')}</p>
              <br/>
            </div>
          )
        }
        </>
      )
    }else if(activeTab === 1){
      return (
        <>
          {
            stampCollected.length > 0? 
              stampCollected.map((_cm, _i) => {
                  return (
                    <AccordionItem
                      onClick={() => activeStampsTracking(_i)}
                      key={_i}
                      eventKey={`live${_i}`}
                      renderToggle={() =>
                        <AccordionHeader index={`live${_i}`} value={_cm} live={true}/>
                      }
                      renderContent={() =>
                        <AccordionContent index={`live${_i}`} value={_cm} live={true}/>
                      }
                    />
                  )
              })
            : (
              <div className='text-center'>
                <br/>
                <img src={Voucher} alt="" />
                <br/>
                <br/>
                <p style={{
                  fontWeight: 500,
                  fontSize: 20,
                  color: '#CDD9D9',
                }}>{t('Stamp ActiveMessage1')}</p>
                <p style={{
                  fontWeight: 400,
                  fontSize: 14,
                  // color: '#CDD9D9'
                }}>
                  <Trans i18nKey='Stamp ActiveMessage2' />
                </p>
                <br/>
              </div>
            )
          }
        </>
      )
    }else{
      return (
        <>
          {
            unavailableStamps.length > 0?
            unavailableStamps.map((_cm, _i) => {
                return (
                  <AccordionItem
                    onClick={() => unavailableStampsTracking(_i)}
                    key={_i}
                    eventKey={`unavailable_${_i}`}
                    renderToggle={() =>
                      <AccordionHeader index={`unavailable_${_i}`} value={_cm}/>
                    }
                    renderContent={() =>
                      <AccordionContent index={`unavailable_${_i}`} value={_cm}/>
                    }
                  />
                )
            })
            : (
              <div className='text-center'>
                <br/>
                <img src={Voucher} alt="" />
                <br/>
                <br/>
                <p style={{
                  fontWeight: 400,
                  fontSize: 16,
                  color: '#CDD9D9'
                }}>{t('Stamp NoStampCard')}</p>
                <br/>
              </div>
            )
          }
        </>
      )
    }
  }

  return (
    <StampContainer color1={color1} color2={color2}
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      <div className='stamp-container' id="stamp-container">
        <TopNav 
          name={t(`Home ${campaignDef.name}`)}
          back={back}
          merchant={props.merchant}
          userInfo={props.userInfo}
          signout={props.signout}
          toggleLoginModal={props.toggleLoginModal}
        />       

        <div className='tabs'>
          <div className={classNames('tab', {
            active: activeTab === 0
          })}
          onClick={() => fetchCampaign(0)}
          >{t('Stamp Available')}</div>
          <div className={classNames('tab', {
            active: activeTab === 1
          })}
          onClick={() => fetchCampaign(1)}>{t('Stamp Active')}</div>
          <div className={classNames('tab', {
            active: activeTab === 2
          })}
          onClick={() => fetchCampaign(2)}>{t('Stamp Unavailable')}</div>
        </div>

        <Accordion activeKey={activeKey}>
          <div className='section-accordion available'>
            <div className='campaigns'>
              
              <RenderCampaign activeTab={activeTab} />

            </div>
          </div>
        </Accordion>

      </div>

      <Modal
        title={false}
        visible={showAuthModal}
        onCancel={() => {
          setShowAuthModal(false)
          setPin('')
          if (timer?.current) {
            clearInterval(timer.current);
            timer.current = null
          }
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='auth-modal'>
          <span className='title1'>Authentication</span>
          <span className='title2'>Let merchant<br/>scan this QR code</span>
          <QRCode value={pin} size={200} />
          <span className='title3'>Or let merchant enter the PIN</span>
          <span className='title4'>PIN : <span className='title5'>{pin}</span></span>
          <span className='title6'>Time remaining</span>
          <span className='title7'>00 : {authTimer < 10 ? `0${authTimer}` : authTimer}</span>
        </div>
      </Modal>

      <Modal
        title={false}
        visible={showTransactionResultModal}
        onCancel={() => {
          setShowTransactionResultModal(false)
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <div className='auth-modal'>
          {/* <span className='title1'>Authentication</span> */}
          {
            transactionResult === 'success' ? <img src={AuthSuccess} alt="" /> : <img src={AuthFail} alt="" />
          }
          {/* <span className={transactionResult === 'success' ? 'title8 success' : 'title8 fail'}>{transactionResult === 'success' ? 'Transaction is' : 'Transaction is'}</span> */}
          <br/>
          <span className={transactionResult === 'success' ? 'title8 success' : 'title8 fail'}>{transactionResult === 'success' ? 'Successful' : 'Unsuccessful'}</span>
          <button onClick={() => setShowTransactionResultModal(false)} className={transactionResult === 'success' ? 'btn-success' : 'btn-danger'}>Done</button>
        </div>
      </Modal>

      <ModalConfirmation 
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              {
                confirmAction === 'reverse'?
                "Reverse Stamp Card":
                "Delete Stamp Card"
              }
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>
              {
                confirmAction === 'reverse'?
                "Procees to reverse this stamp card?":
                "Proceed to delete this stamp card?"
              }
            </span>
          </>
        }
      />
    </StampContainer>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Stamps);
