import { Accordion } from "react-bootstrap";
import { AccordionItemContainer } from "./style";

function AccordionItem({
  className = "",
  eventKey = "",
  renderToggle = () => {},
  renderContent = () => {},
}) {
  return (
    <AccordionItemContainer className={className}>
      <Accordion.Toggle
        variant="link"
        as={"div"}
        className="custom-accordion-toggler"
        eventKey={eventKey}
      >
        {renderToggle()}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        {renderContent()}
      </Accordion.Collapse>
    </AccordionItemContainer>
  );
}

export default AccordionItem;
