import React from 'react';
import { RewardContainer } from "./style";
import { connect } from 'react-redux'
import { Modal } from "antd";
import {
  FaTimesCircle
} from "react-icons/fa";
import { RewardFail } from "assets/images"

const FailModal = props => {

  return (
    <Modal
      title={false}
      visible={props.showModal}
      onCancel={() => {
        props.toggleModal()
      }}
      centered
      closable={true}
      footer={false}
      // maskClosable={false}
      // getContainer={() => document.getElementById("reward-container")}
      width='90%'
      className='login-antd-modal'
      closeIcon={<FaTimesCircle className='close-icon'/>}
    >
      <RewardContainer>
        <div className='reward-container'>
          <div className='fail'>
            <img src={RewardFail} alt="" />
            {/* <FaTimesCircle className='status-icon-fail'/> */}
            <h5>
              {props.message}
            </h5>
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {props.footer}
            {
              (props.urlNavigate) && (
                <button type='button'>
                    <a href={props.urlNavigate}>Go Now!</a>
                </button>
              )
            }
          </div>
        </div>
      </RewardContainer>
    </Modal>
  );
}

const mapStateToProps = state => ({
  auth: state.role.auth,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(FailModal);
