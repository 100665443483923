import React from 'react';
import parse from 'html-react-parser';
import { RewardContainer } from "./rewardStyle";
import { connect } from 'react-redux'
import { Modal } from "antd";
// import { isEmpty } from 'lodash';
import {
  FaTimesCircle
} from "react-icons/fa";

import moment from 'moment';

const Reward = props => {

  const handleApplicable = () => {
    if(props.applicableTo === 'new'){
      props.acceptReward()
    }else{
      props.acceptRewardMember()
    }
  }

  return (
    <Modal
      title={false}
      visible={props.showModal}
      onCancel={() => {
        props.toggleRewardModal()
      }}
      centered
      closable={true}
      footer={false}
      maskClosable={false}
      // getContainer={() => document.getElementById("reward-container")}
      width='90%'
      className='login-antd-modal rewardz-modal'
      closeIcon={<FaTimesCircle className='close-icon'/>}
    >
      <RewardContainer>
        <div className='reward-container'>
     
              <>
              <div className='top'>
                <h3 className='w-100'>Get MoreREWARDZ</h3>
              </div>
              <div className='wrapepr'>
                <div className='header'>
                  <div className='w-100'>
                    {/* <h5>Your friend, {" "}
                      {!isEmpty(userShared)?
                        userShared.lastName || userShared.firstName?
                        userShared.lastName + " " + userShared.firstName
                        :
                        userShared.email.split("@")[0]
                      :""}, 
                      wants to share this voucher with you</h5> */}
                      <h3>Welcome to <span className='text-capitalize'>{props.merchant.business_name}</span></h3>
                  </div>
                  <div className='w-100 text-center'>
                    <span>Redeem the following gift:</span>
                  </div>
                </div>
                <div className='voucher'>
                  <span>{props.data.new_signup_title}</span>
                </div>
                <div className='tos'>
                  <span className='title'>Terms & Conditions</span>
                  <div className='detail'>
                    <ul style={{marginBottom: 0}}>
                      <li>
                        Campaign Period {props.data.period_start?moment(props.data.period_start).format('DD/MM/YYYY'):''} - {props.data.period_end?moment(props.data.period_end).format('DD/MM/YYYY'):''}
                      </li>
                      <li>
                        This card is valid for {props.data.new_signup_validity} {props.data.new_signup_validity_unit?props.data.new_signup_validity_unit.slice(0, -1): ''}(s) upon first collect
                      </li>

                      {/* <li>
                        This link is valid until {moment(props.data.expiry_validity_date).format('DD/MM/YYYY - HH:mm')}
                      </li>
                      <li>
                        The validity of gift redemption is {props.data.new_signup_validity} {props.data.new_signup_validity_unit?props.data.new_signup_validity_unit.slice(0, -1): ''}(s)
                      </li>
                      <li>
                        Valid for {props.applicableTo === 'new'? "new customer" : "exsiting customer"}
                      </li> */}
                    </ul>
                    {
                      props.data.tos1 &&
                      parse(props.data.tos1)
                    }
                  </div>
                </div>
                <div className='accept'>
                  <button type="button" onClick={() => handleApplicable()}>REDEEM NOW</button>
                </div>
              </div>
              </>
        </div>
      </RewardContainer>
    </Modal>
  );
}

const mapStateToProps = state => ({
  auth: state.role.auth,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Reward);
