const subscription = {
  "copper": {
    "stamp": 1,
    "package": 1,
    "referral": 1,
    "voucher": 1,
    "points": false,
    "wallet": false,
    "interaction": 1000,
    "customers": 200,
  },
  "bronze": {
    "stamp": 3,
    "package": 3,
    "referral": 3,
    "voucher": 3,
    "points": true,
    "wallet": true,
    "interaction": 10000,
    "customers": 1000,
  },
  "silver": {
    "stamp": 10,
    "package": 10,
    "referral": 10,
    "voucher": 10,
    "points": true,
    "wallet": true,
    "interaction": 50000,
    "customers": 5000,
  },
  "gold": {
    "stamp": 20,
    "package": 20,
    "referral": 20,
    "voucher": 20,
    "points": true,
    "wallet": true,
    "interaction": 250000,
    "customers": 25000,
  },
}

const env = {
  subscription: subscription,
  superAdminGA: process.env.REACT_APP_ADMIN_GA_TRACKING_ID, 
} 
  
export default env;