import React, { useEffect, useState, useContext } from 'react';
import { NotificationContainer } from "./style";
import { DataContext } from "contexts/DataContextContainer";
import moment from 'moment'
import { useHistory } from "react-router-dom";
import TopNav from "components/TopNav/index"

import client from "feathers.js"
import { Row, Col } from 'react-bootstrap';

import 'services/i18'
import { useTranslation as translation } from 'react-i18next';

const Notifications = props => {
  var color1 = "#3B7779"
  var color2 = "#FFB84F"

  const {t} = translation()

  const history = useHistory();

  const { setLoading, showToast } = useContext(DataContext)
  const [ done, setDone ] = useState(false)
  const [ skip, setSkip ] = useState(0)
  const [ data, setData ] = useState([])

  useEffect(() => {
    fetchNotifications(0)
    /* eslint-disable-next-line */
  }, [])

  const fetchNotifications = (innerSkip) => {
    setLoading(true)

    var skipped = skip+innerSkip
    client.authenticate()
    .then(() => {
      return client.service('notifications').find({
        query: {
          userId: props.userInfo._id,
          $skip: skipped,
          $sort: {
            createdAt: -1
          },
          $limit: 15
        }
      })
    })
    .then((res) => {
      setSkip(skipped)
      setData(data.concat(res.data))
      setLoading(false)
      if(res.data.length === 0){
        setDone(true)
      }
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){  
        props.toggleLoginModal()
      }else{
        showToast('error', err.message)
      }
      setLoading(false)
    })
  }

  const back = () => {
    history.goBack()
  }

  return (
    <NotificationContainer color1={color1} color2={color2}
    onWheel={ event => {
      if (event.nativeEvent.wheelDelta > 0) {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('down')
          document.getElementById('tab-wrap').classList.add('up')
        }
      } else {
        if(document.getElementById('tab-wrap')){
          document.getElementById('tab-wrap').classList.remove('up')
          document.getElementById('tab-wrap').classList.add('down')
        }
      }
    }}
    >
      <div className='notification-container' id="notification-container">
        <TopNav 
          name={t('Notifications Title')}
          back={back}
          merchant={props.merchant}
          userInfo={props.userInfo}
          signout={props.signout}
          toggleLoginModal={props.toggleLoginModal}
        />
       <div className='notification-page-body'>
         {
          data.length > 0?
          data.map((x, i) => {
            return (
              <Row key={i} className="align-items-center mb-2 card-wrapper">
                <Col xs="10" sm="10">
                  <div className='desc-wrap'>
                    <div className='date'>
                      {moment(x.createdAt).format('DD MMM YYYY')}
                    </div>
                    <div className='desc'>{x.description}</div>
                  </div>
                </Col>
                <Col xs="2" sm="2">
                  {/* <div className='uncheck'></div> */}
                </Col>
              </Row>
            )
          }): <p style={{textAlign: 'center', color: '#BDBDBD'}}>{t('Notifications NoNotificationsYet')}</p>
        }
        {
          !done && data.length >= 15? (
            <button 
              className='load-more'
              onClick={() => fetchNotifications(15)}
            >Load more</button>
          ): null
        }
       </div>
      </div>
    </NotificationContainer>
  );
}

export default Notifications;
