import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { LoadingViewContainer } from "./style";
import { colors } from "style";

export default function LoadingView({ isLoading, size = 50, color = colors.blueDark }) {
  return (
    isLoading ?
      <LoadingViewContainer>
        <ClipLoader color={color} loading={isLoading} size={size} className='loader'/>
      </LoadingViewContainer>
    :
      <></>
  );
}
