import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justfy-content: center;
  align-items: center;
  input {
    height: 50px;
    background: #FFFFFF;
    box-shadow: 0px 9px 27px rgba(225, 225, 225, 0.35);
    border-radius: 7px;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  .header {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #263238;
    margin-bottom: 12px;
  }
  .body {
    text-align: center;
    font-size: 14px;
    color: #575757;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
    .btnEmpty {
      // height: 40px;
      border-radius: 20px;
    }
    .btnConfirm {
      background: #3B7779;
      box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
      border-radius: 7px;
      font-weight: 700;
      font-size: 15px;
      color: #FFFFFF;
    }
  }
`;
