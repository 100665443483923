import { colors } from "style";
import styled from "styled-components";
import { MerchantFooter } from "assets/images"

export const TabBarContainer = styled.footer`
  position: fixed;
  bottom: 0;
  overflow: hidden;
  padding-top: 10px;
  max-width: 390px;
  // .up {
  //   transform: translateY(0);
  // }
  // .down {
  //   transform: translateY(100%);
  // }
  .tab-wrap {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 15px rgba(50,50,93,.1), 0px 0px 15px rgba(0,0,0,.07)!important;
    color: ${colors.white} !important;
    // padding: 12px;
    max-width: 600px;
    margin: 0 auto;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    background: white;
    left: 0;
    right: 0;
    transition: .5s;

    .tab-item {
      color: #B3B9C7;
      width: 19%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;
      border-radius: 8px;
      padding: 4px;
      span:nth-child(1) {
        font-size: 20px;
      }
      span:nth-child(2) {
        font-size: 10px;
        text-transform: uppercase;
      }
      &.active {
        // background: ${colors.yellow};
        color: ${props => props.color1};
      }
    }
  }
  @media (min-width: 768px) {
    position: absolute;
  }
`;

export const FooterLogoContainer = styled.div`
  position: relative;
  // background-image: url(${MerchantFooter});
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: ${props => props.color1};
  text-align: right;
  padding: 0px 10px;
`;
