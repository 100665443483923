export const fonts = {
  main: 'Lato-Regular',
}

export const colors = {
  white: '#fff',
  black: '#000',
  blueDark: '#000082',
  orange: '#ff6700',
  grey: '#979797',
  darkGrey: '#878787',
  bgGrey: '#c4c4c4',
  lightGrey: '#f5f5f6',
  background1: '#f0f2f5',
  background: '#e5e5e5',
  textGrey: '#b4b2b7',
  red: '#db5655',
  purple: '#3b3054',
  yellow: '#efc879',
  lightYellow: '#fbd669',
  grayLight: '#666666',
  green: '#04D300',
  greenDark: '#0B4141',
  greenDarkest: '#5F6368',
  dark8: 'rgba(0, 0, 0, 0.8)',
  dark7: 'rgba(0, 0, 0, 0.7)',
  // white: '#f6f6f6',
  blue: '#94d2f1'
}

export const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  xl: 1200,
  xxl: 1430,
}
